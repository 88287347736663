import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { queryClient } from "../../App";
import { ApiService } from "../../services";
import { makeKeyFactory } from "./makeKeyFactory";

const { get, post, patch, deleteCall } = new ApiService();

export type CreatePurchaseReportRequestBody = {
  purchaseDate: Date;
  reportNumber: number;
  sellerId: number;
  buyerId: number;
  purchaseTypeId: number;
  comments: string | null;
  purchaseReportStatusId: number;
  purchaseReportItems: {
    price: number;
    weight: number;
    pieces: number | null;
    value: number;
    speciesId: number;
    updatedBy: number;
    conditionCode: string | null;
  }[];
  purchaseReportMethods: {
    methodId: number;
    updatedBy: number;
  }[];
  purchaseReportFishers: {
    reportingEntityId: number;
    updatedBy: number;
  }[];
  purchaseReportAreas: {
    areaId: number;
    updatedBy: number;
  }[];
  fisherCount: number | null;
  motorizedVessel: boolean | null;
  hoursFished: number | null;
  gearsCount: number | null;
};

type CreatePurchaseReportProps = {
  body: CreatePurchaseReportRequestBody;
  update?: boolean;
};

const PURCHASE_REPORTS_ENDPOINT = "purchase-reports";

export const purchaseReportKeys = makeKeyFactory("purchaseReports");

export const getPurchaseReport = async (id: number) => {
  try {
    const data = await get(PURCHASE_REPORTS_ENDPOINT, { id });
    // If a purchase report is returned, show warning
    if (data && data.length > 0) {
      if (window.location.pathname.includes("purchase-reports/new")) {
        toast(`A purchase report with number ${id} already exists.`, {
          position: "top-right",
          type: "warning",
          theme: "colored"
        });
      }
    }
  } catch (err) {
    return err;
  }
};

export const getPurchaseReportsInRange = async (start: number, end: number) => {
  try {
    const data = await get(PURCHASE_REPORTS_ENDPOINT, { start, end });
    return data;
  } catch (err) {
    return err;
  }
};

export const searchPurchaseReport = async (
  query: string | Date,
  type: string[],
  offset?: number,
  limit?: number
) => {
  try {
    const data = await get(PURCHASE_REPORTS_ENDPOINT, {
      query,
      type,
      offset,
      limit
    });
    return data;
    // If a purchase report is returned, show warning
  } catch (err) {
    return err;
  }
};

export const usePurchaseReportsQuery = () => {
  const reports = useQuery(
    purchaseReportKeys.list("all"),
    async () => await get(PURCHASE_REPORTS_ENDPOINT),
    {
      staleTime: 1000 * 6 * 5,
      keepPreviousData: true,
      onSuccess: data => {
        return data;
      },
      onError: error => {
        toast("Error Fetching Purchase Reports", {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return reports;
};

export const useCreatePurchaseReport = (action: "Updated" | "Created") => {
  const newPurchaseReport = useMutation(
    async ({ body }: CreatePurchaseReportProps) =>
      (await action) === "Updated"
        ? patch(PURCHASE_REPORTS_ENDPOINT, body)
        : post(PURCHASE_REPORTS_ENDPOINT, body),
    {
      onSuccess: async () => {
        toast(`Purchase Report ${action}.`, {
          position: "top-right",
          type: "success",
          theme: "colored"
        });
        return queryClient.invalidateQueries(purchaseReportKeys.lists());
      },
      onError: error => {
        toast("Error Creating Purchase Report", {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return newPurchaseReport;
};

export const useDeletePurchaseReport = (id?: number) => {
  const deletedPurchaseReport = useMutation(
    async () => deleteCall(PURCHASE_REPORTS_ENDPOINT, { id }),
    {
      onSuccess: async () => {
        toast(`Purchase Report ${id} deleted.`, {
          position: "top-right",
          type: "success",
          theme: "colored"
        });
        return queryClient.invalidateQueries(purchaseReportKeys.lists());
      },
      onError: error => {
        toast(`Error Deleting Purchase Report ${id}`, {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return deletedPurchaseReport;
};
