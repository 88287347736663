import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SelectSearchOptions } from "../../components";
import {
  LogItFormRenderer,
  LogItFormRendererInputProps,
  PurchaseReportBookType
} from "../../components/Forms/LogItFormRenderer";
import { ReportingEntity } from "../../models";
import {
  getPurchaseReportBook,
  useCreatePurchaseReportBook
} from "../../pages/queries";
import { getAccountId } from "../../services/ApiService";
import { PAGE_TYPES } from "../../utils";

type InputOptions = {
  prbsOptions: SelectSearchOptions[];
  reportingEntityOptions: ReportingEntity[];
};
type PurchaseReportBookInputProps = {
  numberPerBook?: string;
  startReportNumber?: string;
  endReportNumber?: string;
  bookCount?: string;
};

const TARGET_REGION = process.env.REACT_APP_TARGET_REGION;
let DEFAULT_ENTITY_FISHER_TYPE: number;
let DEFAULT_NUMBER_PER_BOOK: number;
let singleFormConfig: LogItFormRendererInputProps[] = [];
let multipleFormConfig: LogItFormRendererInputProps[] = [];
import(`../../config/${TARGET_REGION}/purchaseReportBookFormConfig`).then(
  module => {
    singleFormConfig = module.singlePurchaseReportBookFormConfig;
  }
);
import(`../../config/${TARGET_REGION}/purchaseReportBookFormConfig`).then(
  module => {
    multipleFormConfig = module.multiplePurchaseReportBookFormConfig;
  }
);

import(`../../config/${TARGET_REGION}/defaults`).then(module => {
  DEFAULT_NUMBER_PER_BOOK = module.DEFAULT_NUMBER_PER_BOOK;
  DEFAULT_ENTITY_FISHER_TYPE = module.DEFAULT_ENTITY_FISHER_TYPE;
});

function NewPurchaseReportBook({
  prbsOptions,
  reportingEntityOptions
}: InputOptions) {
  const createPurchaseReportBook = useCreatePurchaseReportBook("Created");
  const [validInputs, setValidInputs] = useState({
    startReportNumber: true
  });

  const makeFormConfig = useCallback(
    (config: any) => {
      const handleInvalidStartNumber = async (
        e: React.FocusEvent<HTMLInputElement>
      ) => {
        const { name, value } = e.target;
        setValidInputs({
          ...validInputs,
          [name]: (Number(value) - 1) % DEFAULT_NUMBER_PER_BOOK === 0
        });
        value && (await getPurchaseReportBook(+e.target.value, true));
      };
      return config.map((input: any) => {
        // This is needed to clear any previous values
        delete input.defaultValue;
        if (input.name === "numberPerBook") {
          input.readonly = false;
          input.defaultValue = input.options?.find((option: { id: number }) => {
            return option.id === DEFAULT_NUMBER_PER_BOOK;
          });
        }
        if (input.name === "buyer") {
          input.options = reportingEntityOptions
            ?.filter(entity => entity.typeId !== DEFAULT_ENTITY_FISHER_TYPE)
            .map(entity => ({
              value: String(entity.id),
              label: entity.name,
              badgeTypeId: entity.type?.id,
              badgeTypeLabel: entity.type?.name
            }));
        }
        if (input.name === "bookStatus") {
          input.options = prbsOptions;
        }
        if (input.name === "startReportNumber") {
          input.handleOnBlur = handleInvalidStartNumber;
          input.readonly = false;
          input.isvalidinput = validInputs.startReportNumber;
        }
        if (input.name === "issueDate") {
          input.max = new Date().toISOString().split("T")[0];
        }
        return input;
      });
    },
    [reportingEntityOptions, prbsOptions, validInputs]
  );
  const [formData, setFormData] = useState<LogItFormRendererInputProps[]>(
    makeFormConfig(singleFormConfig)
  );

  const [inputs, setInputs] = useState<PurchaseReportBookInputProps>({
    numberPerBook: String(DEFAULT_NUMBER_PER_BOOK)
  });

  const [bookFormType, setBookFormType] = useState<PurchaseReportBookType>(
    PurchaseReportBookType.SINGLE
  );
  useEffect(() => {
    setFormData(
      bookFormType === PurchaseReportBookType.SINGLE
        ? makeFormConfig(singleFormConfig)
        : makeFormConfig(multipleFormConfig)
    );
  }, [bookFormType, makeFormConfig]);

  const navigate = useNavigate();

  const handleSetEntityType = (type: any) => {
    setBookFormType(type);
  };

  const handleCancel = () => {
    navigate("/purchase-report-books");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const accountId = getAccountId();
    const formData = new FormData(event.currentTarget);

    const { ...fieldValues } = Object.fromEntries(formData.entries());
    let dataArr: any[] = [];
    let numberPerBook = Number(fieldValues.numberPerBook);
    let startReportNumber = Number(fieldValues.startReportNumber);
    for (
      let i = startReportNumber;
      i <=
      numberPerBook * Number(fieldValues.bookCount || 1) +
        startReportNumber -
        1;
      i += numberPerBook
    ) {
      let startReportNumber = i;
      let result = {
        startReportNumber: startReportNumber,
        endReportNumber: startReportNumber + numberPerBook - 1,
        purchaseReportsPerBook: Number(fieldValues.numberPerBook),
        buyerId:
          bookFormType === PurchaseReportBookType.SINGLE
            ? Number(fieldValues.buyer)
            : null,
        purchaseReportBookStatusId: Number(fieldValues.bookStatus),
        issuedDate:
          bookFormType === PurchaseReportBookType.SINGLE
            ? new Date(fieldValues.issueDate as string)
            : null,
        comments:
          bookFormType === PurchaseReportBookType.SINGLE
            ? fieldValues.comments
            : null,
        updatedBy: accountId,
        createdBy: accountId
      };
      dataArr.push(result);
    }
    let body = { data: dataArr };

    createPurchaseReportBook.mutate(
      { body },
      {
        onSuccess: () => {
          navigate("/purchase-report-books");
        },
        onError: error => {}
      }
    );
  };

  const onChangeForField = (name: string, value: string) => {
    let numberPerBookVal =
      name === "numberPerBook" ? value : inputs["numberPerBook"];
    let startReportNumberVal =
      name === "startReportNumber" ? value : inputs["startReportNumber"];
    let endReportVal =
      name === "endReportNumber" ? value : inputs["endReportNumber"];
    let bookCountVal = name === "bookCount" ? value : inputs["bookCount"];
    if (numberPerBookVal && startReportNumberVal) {
      endReportVal = String(
        Number(numberPerBookVal) * Number(bookCountVal || 1) +
          Number(startReportNumberVal) -
          1
      );
    }
    setInputs({
      numberPerBook: numberPerBookVal,
      startReportNumber: startReportNumberVal,
      endReportNumber: endReportVal,
      bookCount: bookCountVal
    });
  };

  return (
    <LogItFormRenderer
      config={formData}
      pageType={PAGE_TYPES.NEW_PURCHASE_REPORT_BOOK}
      setBookFormType={handleSetEntityType}
      bookFormType={bookFormType}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      onChangeForField={onChangeForField}
      purchaseReportBookInputs={inputs}
    />
  );
}

export default NewPurchaseReportBook;
