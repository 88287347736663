/**
 * Creates a key factory used with React Query to organize query data for easier filtering. (https://react-query.tanstack.com/guides/query-keys)
 *
 * @param keyName
 * @returns ["purchaseReports", "list", {"filters":"species"}]
 */
export const makeKeyFactory = (keyName: string) => {
  const keyFactory = {
    all: [keyName] as const,
    lists: () => [...keyFactory.all, "list"] as const,
    list: (filters: string) => [...keyFactory.lists(), { filters }] as const,
    details: () => [...keyFactory.all, "detail"] as const,
    detail: (id: number) => [...keyFactory.details(), id] as const
  };
  return keyFactory;
};
