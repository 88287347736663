import * as Yup from "yup";

const loginIdValidationSchema = Yup.string()
  .required("Username is required") // Ensures the field is not empty
  .min(3, "Username must be at least 3 characters") // Minimum 3 characters
  .max(20, "Username must be less than 20 characters") // Maximum 20 characters
  .matches(
    /^[a-zA-Z0-9_-]+$/,
    "Username must contain only letters, numbers, underscores, and hyphens"
  ); // Validates allowed characters

export default loginIdValidationSchema;
