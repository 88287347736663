import { useEffect, useMemo, useRef, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import {
  LogItButtonRound,
  LogItPageHeader,
  LogItTable
} from "../../components";
import ReportingEntity from "../../models/ReportingEntity";
import { isEmptyObj } from "../../utils";
import {
  useReportingEntitiesQuery,
  useReportingEntityCategoriesQuery,
  useReportingEntityTypesQuery
} from "../queries";
import NewReportingEntity from "./NewReportingEntity";
import ReportingEntityDetails from "./ReportingEntityDetails";
import { reportingEntitiesTableConfig as tableConfig } from "./tableConfig";

type TableViewProps = {
  data: ReportingEntity[];
  setDetailData?: React.Dispatch<React.SetStateAction<ReportingEntity>>;
  searchBarRef?: any;
};

function ReportingEntities({
  reportingEntityData,
  setReportingEntityData
}: any) {
  const navigate = useNavigate();
  const params = useParams();
  const { data } = useReportingEntitiesQuery("all", {
    sort: "-updatedAt,name",
    include: "type,category,editor,creator,accountReportingEntities"
  });
  const [heading, setHeading] = useState("");
  const isBasePath =
    params["*"]?.length === 0 && isEmptyObj(reportingEntityData);

  const { data: catOptsData } = useReportingEntityCategoriesQuery({
    sort: "name"
  });
  const { data: typeOptsData } = useReportingEntityTypesQuery({
    sort: "id"
  });

  let searchBarRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    searchBarRef.current?.focus();
  }, [data, reportingEntityData, params]);

  const handleBackArrow = () => {
    // When browser popstate is called, if on detail page, navigate to reporting-entities
    if (!isEmptyObj(reportingEntityData)) {
      setReportingEntityData({});
      navigate("/reporting-entities");
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", handleBackArrow);
    return () => {
      window.removeEventListener("popstate", handleBackArrow);
    };
  });

  useEffect(() => {
    if (isBasePath) {
      setHeading("Reporters");
    } else if (params["*"] === "new") {
      setHeading("Add New Reporter");
    } else if (!isEmptyObj(reportingEntityData)) {
      setHeading("View/Edit Reporter");
    } else if (!isBasePath) {
      navigate("/purchase-reports");
    }
  }, [isBasePath, params, reportingEntityData, navigate]);

  const tableData = useMemo(() => {
    return data?.map((item: any) => {
      let category = null;
      let updatedAt = null;

      if (item.category && item.category.name) category = item.category.name;

      if (item.updatedAt) {
        updatedAt = `${new Date(
          item.updatedAt
        ).toLocaleDateString()}, ${new Date(
          item.updatedAt
        ).toLocaleTimeString()}`;
      }
      return new ReportingEntity({
        ...item,
        type: item.type.name,
        category,
        updatedAt
      });
    });
  }, [data]);

  const goToNewEntityForm = () => {
    navigate("new");
  };

  const renderButtons = () => {
    return (
      <LogItButtonRound
        label="Add New Reporter"
        onClick={goToNewEntityForm}
        buttonStyle="filled"
      />
    );
  };

  if (!data) {
    return null;
  }
  const renderTable = () => {
    return (
      <>
        <div className={!isEmptyObj(reportingEntityData) ? "d-none" : ""}>
          <TableView
            data={tableData}
            setDetailData={setReportingEntityData}
            searchBarRef={searchBarRef}
          />
        </div>
        {!isEmptyObj(reportingEntityData) ? (
          <ReportingEntityDetails
            reportingEntityData={reportingEntityData}
            categoriesOptions={catOptsData}
            entityTypesList={typeOptsData}
            setDetailData={setReportingEntityData}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const handleEscape = () => {
    if (params["*"] === "new") {
      navigate("/reporting-entities");
    }
    if (!isEmptyObj(reportingEntityData)) {
      setReportingEntityData({});
    }
  };

  return (
    <div
      onKeyDown={e => {
        if (e.key === "Escape") {
          handleEscape();
        }
      }}
    >
      <LogItPageHeader
        heading={heading}
        renderButtons={isBasePath ? renderButtons : null}
      />

      <Routes>
        <Route path="/" element={renderTable()} />
        <Route
          path="new"
          element={
            <NewReportingEntity
              categoriesOptions={catOptsData}
              entityTypesList={typeOptsData}
            />
          }
        />
      </Routes>
    </div>
  );
}

function TableView({ data, setDetailData, searchBarRef }: TableViewProps) {
  const tableColumns = useMemo(
    () => tableConfig.filter(({ display }) => display),
    []
  );
  return (
    <LogItTable
      columns={tableColumns}
      data={data}
      setDetailData={setDetailData}
      type="reportingEntity"
      ref={searchBarRef}
    />
  );
}

export default ReportingEntities;
