type LastUpdatedLabelProps = {
  account?: { name?: string };
  timeStamp?: Date | string | null;
  label: string;
  showAccount?: boolean;
  localOpts?: Object;
};

function LastUpdatedLabel({
  account,
  timeStamp: date,
  label,
  showAccount = true,
  localOpts = {}
}: LastUpdatedLabelProps) {
  return (
    <div className="text-end">
      <small>
        {label && <span className="fw-semibold">{label}</span>}{" "}
        {date && new Date(date).toLocaleString("en-US", localOpts)}{" "}
        {showAccount && account?.name && (
          <>
            by <span className="fst-italic">{account.name}</span>
          </>
        )}
      </small>
    </div>
  );
}

export default LastUpdatedLabel;
