import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import WelcomeLayout from "./components/Layout/WelcomeLayout";
import { Home } from "./pages";
import {
  Login,
  Message,
  RequestResetPassword,
  ResetPasswordForm
} from "./pages/LoginRegistration";
import RegisterAccount from "./pages/LoginRegistration/RegisterAccount";
import { AuthContext } from "./providers/Authentication";

export function AppRoutes() {
  return (
    <Routes>
      <Route element={<WelcomeLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<RegisterAccount />} />
        <Route
          path="/password/reset/request"
          element={<RequestResetPassword />}
        />
        <Route path="/password/reset" element={<ResetPasswordForm />} />
        <Route path="/message" element={<Message />} />
      </Route>

      <Route
        path="*"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if ((!auth || !auth.getToken()) && location.pathname !== "/login") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
