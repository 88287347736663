import { FormEventHandler, useContext, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LogItInput } from "../../components";
import { AuthContext } from "../../providers/Authentication";
import { usePermissions } from "../../providers/Permission";
import { AuthenticationService } from "../../services";

interface LoginFormElements extends HTMLFormElement {
  loginId: HTMLInputElement;
  password: HTMLInputElement;
}

interface APIErrorBody {
  errors: { detail: string; source?: { pointer: string } }[];
}

function isErrorBody(body: any): body is APIErrorBody {
  return body.hasOwnProperty("errors") && Array.isArray(body.errors);
}

type LoginState = {
  errors: {
    loginId?: { detail: string; source?: { pointer: string } };
    password?: { detail: string; source?: { pointer: string } };
    _: { detail: string }[];
  };
};

function Login() {
  const [errors, setErrors] = useState<LoginState["errors"]>({
    loginId: undefined,
    password: undefined,
    _: []
  });
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { message = "" } = location.state || {};

  const { fetchPolicies } = usePermissions();

  const onSubmit: FormEventHandler<LoginFormElements> = async event => {
    event.preventDefault();

    const Authentication = new AuthenticationService();
    const results = await Authentication.logIn(
      event.currentTarget.loginId.value,
      event.currentTarget.password.value
    );

    if (isErrorBody(results)) {
      const { errors } = results;
      setErrors({
        loginId: getFieldError(errors, "/loginId"),
        password: getFieldError(errors, "/password"),
        _: errors.filter(err => {
          return !err.source;
        })
      });
    } else {
      authContext.setToken(
        results.accessToken,
        results.refreshToken,
        results.accountId,
        results.name,
        results.status
      );

      // Get permissions for user
      await fetchPolicies();

      // Go to home page
      navigate("/purchase-reports", { replace: true });
    }
  };

  return (
    <>
      {message && message !== "" && (
        <Alert variant="success" className="mb-5">
          {message}
        </Alert>
      )}

      <h2>Login</h2>
      <p className="mb-4">
        Please login with your username and password to continue.
      </p>
      <form method="POST" onSubmit={onSubmit}>
        <LogItInput
          type="text"
          name="loginId"
          label="Username"
          error={errors.loginId}
        />
        <LogItInput
          type="password"
          name="password"
          label="Password"
          error={errors.password}
        />
        <div className="d-flex align-items-end">
          <Button variant="success" type="submit" className="me-4">
            Login
          </Button>
          <Link to="/password/reset/request">Forgot Password?</Link>
        </div>

        {errors._.length > 0 && (
          <div className="alert alert-danger mt-5" role="alert">
            <ul>
              {errors._.map((err, i) => (
                <li key={`error-${i}`}>{err.detail}</li>
              ))}
            </ul>
          </div>
        )}
      </form>
    </>
  );
}

export default Login;

function getFieldError(
  errors: { detail: string; source?: { pointer: string } }[],
  pointer: string
) {
  return errors.find(error => error?.source?.pointer === pointer);
}
