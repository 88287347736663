import DeleteCommentButton from "../Buttons/DeleteCommentButton";
import { LastUpdatedLabel } from "../Labels";

export type ReportingEntityCommentProps = {
  _uid?: string | number;
  id: number;
  reportingEntityId?: number;
  text: string;
  updatedAt?: string;
  updatedBy?: number;
  editor?: {
    name: string;
  };
};

type CommentItemProps = {
  comment: ReportingEntityCommentProps;
  deleteCommentHandler: (id: number) => void;
};

function CommentItem({ comment, deleteCommentHandler }: CommentItemProps) {
  return (
    <div className="position-relative" style={{ backgroundColor: "#e9ecef" }}>
      <div
        className="border rounded-1 d-flex flex-column mb-1"
        style={{
          borderColor: "#ced4da",
          borderRadius: "0.25rem",
          padding: "0.375rem 0.75rem"
        }}
      >
        <div>
          <div>{comment.text}</div>
        </div>
        <div className="fw-light text-end">
          <LastUpdatedLabel
            account={comment.editor}
            timeStamp={comment.updatedAt}
            label={""}
            localOpts={{ dateStyle: "medium" }}
          />
        </div>
      </div>

      <DeleteCommentButton
        handleOnClick={deleteCommentHandler}
        id={comment.id}
        style={{ right: "-24px", top: "15px" }}
      />
    </div>
  );
}

export default CommentItem;
