enum BoolString {
  TRUE = "true",
  FALSE = "false"
}

export const passStringValOrNull = (fieldValue: FormDataEntryValue) => {
  if (typeof fieldValue === "string") {
    if (fieldValue.trim() !== "") {
      return String(fieldValue);
    }
  }
  return null;
};

export const passNumberValOrNull = (fieldValue: FormDataEntryValue) => {
  if (typeof fieldValue === "string") {
    if (fieldValue.trim() !== "") {
      return Number(fieldValue);
    }
  }
  return null;
};

export const passBooleanValOrNull = (fieldValue: FormDataEntryValue) => {
  if (typeof fieldValue === "string") {
    const boolString = fieldValue.trim().toLowerCase();
    if (boolString === BoolString.TRUE) {
      return true;
    } else if (boolString === BoolString.FALSE) {
      return false;
    }
  }
  return null;
};

export const passComments = (
  fieldValue: FormDataEntryValue,
  userId: number | null
) => {
  if (typeof fieldValue === "string") {
    if (fieldValue.trim() !== "") {
      return [{ text: fieldValue, updatedBy: userId }];
    }
  }
  return [];
};

export const roundTwoPlaces = (num: number) => {
  return Math.round((Number(num) + Number.EPSILON) * 100) / 100;
};

export const getRowId = (rowName: string) => {
  const nameArr = rowName.split("_");
  const nameUid = nameArr.length === 3 ? `_${nameArr.pop()}` : "";
  return nameUid;
};
