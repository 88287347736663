import React, { forwardRef, useEffect, useState } from "react";
import { Lock } from "react-bootstrap-icons";
import DeleteItemButton from "../Buttons/DeleteItemButton";

type IType = {
  species_price?: string;
  species_weight?: string;
  species_value?: string;
  species_pieces?: string;
  numberPerBook?: string;
  startReportNumber?: string;
  endReportNumber?: string;
};

export type LogItInputProps = {
  name: string;
  defaultValue?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  type?: string;
  className?: string;
  placeholder?: string;
  purchaseReport?: boolean;
  error?: {
    source?: { pointer: string };
    detail: string;
  };
  values?: IType;
  min?: string;
  step?: string;
  renderDelete?: boolean;
  deleteSpeciesRow?: (uid: string) => void;
  handleOnChange?: (
    name: string,
    value: string,
    defaultValue: string,
    uid: string
  ) => void;
  handleOnBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  customClass?: string;
  ref?: any;
  forceRequired?: boolean;
  isvalidinput?: boolean;
  isvalidmessage?: string;
  totalFishers?: number;
  maxLimit?: number;
};

const LogItInput = forwardRef(
  (
    {
      name,
      label,
      defaultValue = "",
      required = false,
      type = "",
      className,
      error,
      values,
      purchaseReport,
      renderDelete,
      totalFishers,
      deleteSpeciesRow,
      handleOnChange,
      handleOnBlur,
      min,
      step,
      customClass,
      disabled,
      readonly,
      forceRequired,
      isvalidinput,
      isvalidmessage,
      maxLimit,
      ...props
    }: LogItInputProps,
    ref = null
  ) => {
    const htmlId = `${name}Input`;
    const [inputValue, setInputValue] = useState(
      defaultValue !== undefined ? defaultValue : ""
    );
    const [maxLimitError, setMaxLimitError] = useState("");
    const [calculatedValue, setCalculatedValue] = useState<string | undefined>(
      defaultValue !== undefined ? defaultValue : ""
    );
    useEffect(() => {
      handleOnChange && handleOnChange(name, inputValue, defaultValue, "");
      // eslint-disable-next-line
    }, [inputValue, name, defaultValue]);

    useEffect(() => {
      if (values) {
        setCalculatedValue(values[name as keyof IType]);
      }
    }, [values, name]);

    const defaultOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      if (
        ["gearsCount", "fisherCount", "hoursFished", "bookCount"].includes(
          name
        ) &&
        maxLimit &&
        Number(value) > maxLimit
      ) {
        setMaxLimitError(`Verify ${label} value`);
      } else {
        setMaxLimitError("");
      }
    };

    let showRequired =
      (name !== "issueDate" && required) ||
      (name === "issueDate" && forceRequired);

    const validationClass = error || maxLimitError ? "is-invalid" : "";
    const marginBottom = name.includes("species") ? "mb-2" : "mb-4";
    const value = values ? calculatedValue || inputValue : inputValue;
    const generateValidationError = () => {
      if (error) {
        return (
          <div
            id="validationServerUsernameFeedback"
            className="invalid-feedback p-0 mt-0 mb-3"
          >
            {error?.detail}
          </div>
        );
      }
      if (maxLimitError !== "") {
        return (
          <div
            id="validationServerUsernameFeedback"
            className="invalid-feedback p-0 mt-0 mb-1"
          >
            {maxLimitError}
          </div>
        );
      }
      return null;
    };

    return (
      <div
        className={`${className} ${
          customClass || ""
        } ${marginBottom} has-validation position-relative mb-4`}
      >
        {readonly && name !== "endReportNumber" && (
          <div
            className="position-absolute"
            style={{ right: 0, bottom: -11, zIndex: 1 }}
          >
            <div
              className="d-flex justify-content-center align-items-center text-white rounded-circle"
              style={{
                height: "25px",
                width: "25px",
                backgroundColor: "#ced4da"
              }}
            >
              <Lock />
            </div>
          </div>
        )}
        {label && (
          <label htmlFor={htmlId} className="form-label fw-bold">
            {label} {showRequired && <span>*</span>}
          </label>
        )}
        <input
          {...props}
          type={type}
          className={`form-control ${
            name === "blank" && "border-0 bg-transparent d-none"
          } ${validationClass} ${isvalidinput === false && " is-invalid"}`}
          aria-describedby="inputGroupPrepend3 validationServerUsernameFeedback"
          id={htmlId}
          min={min}
          step={step ? step : undefined}
          required={showRequired}
          disabled={disabled || name === "blank"}
          readOnly={readonly}
          name={name}
          autoComplete="off"
          value={name === "fisherCount" ? totalFishers : value}
          onChange={e => {
            if (name === "fisherCount") {
              handleOnChange &&
                handleOnChange(name, e.target.value, defaultValue, "");
            }
            setInputValue(e.target.value);
          }}
          onBlur={handleOnBlur || defaultOnBlur}
          //@ts-ignore
          ref={ref}
          style={readonly ? { backgroundColor: "#e9ecef" } : {}}
        />
        {isvalidinput === false && (
          <div className="invalid-feedback">
            {isvalidmessage ? isvalidmessage : "Warning: Invalid input value!"}
          </div>
        )}
        {renderDelete && deleteSpeciesRow && (
          <DeleteItemButton
            handleOnClick={deleteSpeciesRow}
            rowId={name}
            style={{
              top: 0,
              bottom: 6,
              right: -12,
              margin: "auto 0",
              height: "fit-content"
            }}
          />
        )}
        {generateValidationError()}
      </div>
    );
  }
);

export default LogItInput;
