import { useEffect, useState } from "react";
import { Building, Person } from "react-bootstrap-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import logo from "../../logo.svg";
import { getInitials } from "../../utils/getInitials";
import { logout } from "../../utils/logout";
import PermissionsGate from "../Utils/PermissionsGate";

const { REACT_APP_TARGET_REGION } = process.env;

export default function TopNavigation() {
  const [showTooltip, setShowTooltip] = useState(false);
  const [roles, setRoles] = useState<string[]>([]);
  const [name, setName] = useState<string>("");

  useEffect(() => {
    setName(window.localStorage.getItem("name") || "");
    const lsRoles = window.localStorage.getItem("roles");
    if (lsRoles) setRoles(JSON.parse(lsRoles));
  }, []);

  const formattedRoles = () => {
    if (roles.length === 1) {
      return roles[0];
    } else {
      return roles.join(", ");
    }
  };

  return (
    <>
      <nav
        id="top-navigation"
        className="d-flex justify-content-between align-items-center bg-primary px-4 py-2"
      >
        <div className="d-flex align-items-center">
          <img id="nav-logo" src={logo} alt="LogIt Logo" className="me-1" />
          <span className="text-light fs-5 fw-semibold">Logit</span>
          <span className="text-light fs-5 mx-3">|</span>
          <span className="text-light fs-5 text-uppercase">
            {REACT_APP_TARGET_REGION}
          </span>
        </div>
        <div
          className="d-inline"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <OverlayTrigger
            show={showTooltip}
            placement="bottom-end"
            overlay={
              <Popover id="nav-profile-popover">
                <Popover.Body>
                  <p>
                    <strong>{name}</strong>
                  </p>
                  <div className="nav-profile-list-item">
                    <Person />
                    <div>
                      <small>Role</small>
                      {formattedRoles()}
                    </div>
                  </div>
                  <div className="nav-profile-list-item">
                    <Building />
                    <div>
                      <small>Organization</small>
                      <span className="text-uppercase">
                        {REACT_APP_TARGET_REGION}
                      </span>
                    </div>
                  </div>
                  <hr />
                  <ul className="nav-profile-links">
                    <PermissionsGate resource="menu-profile">
                      <li>Account Settings</li>
                    </PermissionsGate>
                    <li onClick={logout}>Sign Out</li>
                  </ul>
                </Popover.Body>
              </Popover>
            }
          >
            <button
              id="nav-profile-circle"
              onFocus={() => setShowTooltip(true)}
            >
              {getInitials(name)}
            </button>
          </OverlayTrigger>
        </div>
      </nav>
    </>
  );
}
