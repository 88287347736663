import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Message() {
  const email = window.localStorage.getItem("emailsent");
  const requestState = window.localStorage.getItem("requestState");
  const getMessage = () => {
    const emailResetMessage = `Password reset request processed, please check your email
    ${email}. Click on the reset link in the email to change your
    password.`;
    const failureMessage =
      "Unable to process request, please verify the submitted information. Please contact support if the issue continues.";
    return requestState === "failed" ? failureMessage : emailResetMessage;
  };
  const header =
    requestState === "failed" ? "Password Reset Failed" : "Password Reset";

  useEffect(() => {
    window.localStorage.removeItem("emailsent");
    window.localStorage.removeItem("requestState");
  }, [email, requestState]);

  return (
    <div>
      <h2>{header}</h2>
      <p className="mb-4">{getMessage()}</p>
      <Link to="/login">
        <Button variant="primary">Continue</Button>
      </Link>
    </div>
  );
}

export default Message;
