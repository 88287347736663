import { useEffect, useState } from "react";
import { LastUpdatedLabel, SelectSearchOptions } from "../../components";
import { LogItButtonStyle } from "../../components/Buttons/LogItButtonRound";
import { ReportingEntityCommentProps } from "../../components/Comments";
import { LogItFormRenderer } from "../../components/Forms/LogItFormRenderer";
import {
  Account,
  ReportingEntity,
  ReportingEntityTypeEnum
} from "../../models";
import { getAccountId } from "../../services/ApiService";
import { ReportingEntityFormConfig } from "../../types/formTypes";
import { PAGE_TYPES } from "../../utils";
import {
  CreateReportingEntityRequestBody,
  accountSearch,
  getReportingEntity,
  linkReportingAccount,
  unlinkReportingAccount,
  useCreateReportingEntity,
  useDeleteReportingEntity,
  useReportingEntityCommentsQuery
} from "../queries";

// Dynamically load form config for TARGET_REGION
const TARGET_REGION = process.env.REACT_APP_TARGET_REGION;
let reportingEntityFormConfig: ReportingEntityFormConfig = {
  VENDOR: [],
  FISHER: [],
  UNKNOWN: []
};
import(`../../config/${TARGET_REGION}/reportingEntityFormConfig`).then(
  module => {
    reportingEntityFormConfig = module.reportingEntityFormConfig;
  }
);

type ReportingEntityDetailsProps = {
  categoriesOptions: SelectSearchOptions[];
  entityTypesList: { id: number; name: string }[];
  setDetailData?: any;
  reportingEntityData?: any;
};

function ReportingEntityDetails({
  categoriesOptions,
  entityTypesList,
  setDetailData,
  reportingEntityData
}: ReportingEntityDetailsProps) {
  const id = Number(reportingEntityData.id);
  const entity = reportingEntityData;
  const createReportingEntity = useCreateReportingEntity("Updated");
  const deleteReportingEntity = useDeleteReportingEntity(id);
  const { data: commentsData, status: commentsStatus } =
    useReportingEntityCommentsQuery(id);
  const [accountSearchResults, setAccountSearchResults] = useState<Account[]>(
    []
  );
  const [entityTypeId, setEntityTypeId] = useState<number>(entity.typeId);
  const [reportingEntityCommentsState, setReportingEntityCommentsState] =
    useState<ReportingEntityCommentProps[]>([]);
  useEffect(() => {
    if (commentsStatus === "success") {
      setReportingEntityCommentsState(commentsData.comments);
    }
  }, [commentsStatus, commentsData]);

  const fetchedArchivedState = entity?.archivedAt;
  const [archivedAtSave, setArchivedAtSave] = useState(fetchedArchivedState);
  const [futureArchivedChecked, setFutureArchivedChecked] = useState(
    !!fetchedArchivedState
  );
  const [archivedButtonLabel, setArchivedButtonLabel] = useState(
    futureArchivedChecked ? "Archived" : "Archive"
  );
  const [archivedButtonClasses, setArchivedButtonClasses] =
    useState<LogItButtonStyle>(futureArchivedChecked ? "warning" : "filled");
  const [isLocked, setIsLocked] = useState(true);
  useEffect(() => {
    if (!fetchedArchivedState && !futureArchivedChecked) {
      setArchivedButtonLabel("Archive Reporter");
      setArchivedButtonClasses("outline");
    }
    if (!fetchedArchivedState && futureArchivedChecked) {
      setArchivedButtonLabel("Archive On Save");
      setArchivedButtonClasses("filledcancel");
      setArchivedAtSave(new Date());
    }
    if (fetchedArchivedState && !futureArchivedChecked) {
      setArchivedButtonLabel("Re-activate On Save");
      setArchivedButtonClasses("filledcancel");
      setArchivedAtSave(null);
    }
    if (fetchedArchivedState && futureArchivedChecked) {
      setArchivedButtonLabel("Re-activate Reporter");
      setArchivedButtonClasses("warning");
    }
  }, [
    fetchedArchivedState,
    futureArchivedChecked,
    entityTypeId,
    entityTypesList
  ]);

  if (!entity || !entityTypeId) {
    return <div>Unable to locate record.</div>;
  }

  //@ts-ignore
  const config = reportingEntityFormConfig[
    ReportingEntityTypeEnum[entityTypeId]
    //@ts-ignore
  ].map(input => {
    const name = input.name as keyof ReportingEntity;
    const value = entity[name];
    switch (input.name) {
      case "businessName":
        if (entityTypeId === ReportingEntityTypeEnum.VENDOR)
          input.readonly = isLocked;
        input.defaultValue = value || "";
        break;
      case "firstName":
        if (entityTypeId === ReportingEntityTypeEnum.FISHER)
          input.readonly = isLocked;
        input.defaultValue = value || "";
        break;
      case "lastName":
        if (entityTypeId === ReportingEntityTypeEnum.FISHER)
          input.readonly = isLocked;
        input.defaultValue = value || "";
        break;
      case "categoryId":
        input.readonly = isLocked;
        input.options = categoriesOptions;
        input.defaultValue = [
          {
            value: !isNaN(entity?.categoryId) ? String(entity?.categoryId) : "",
            label: entity?.category
          }
        ];
        break;
      default:
        input.defaultValue = value || "";
    }
    return input;
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const { ...fieldValues } = Object.fromEntries(formData.entries());

    let newComment;
    if (
      typeof fieldValues.comments === "string" &&
      fieldValues.comments.trim() !== ""
    ) {
      newComment = {
        text: fieldValues.comments.trim(),
        updatedBy: getAccountId()
      };
    }

    const processedCommentsState = reportingEntityCommentsState.map(comment => {
      return {
        id: comment.id,
        text: comment.text,
        updatedBy: comment.updatedBy,
        updatedAt: comment.updatedAt
      };
    });

    const legacyFisherCode =
      typeof fieldValues.legacyFisherCode === "string" &&
      fieldValues.legacyFisherCode.trim() !== ""
        ? parseInt(fieldValues.legacyFisherCode)
        : null;
    const legacyBuyerCode =
      typeof fieldValues.legacyBuyerCode === "string" &&
      fieldValues.legacyBuyerCode.trim() !== ""
        ? parseInt(fieldValues.legacyBuyerCode)
        : null;

    const updatedComments = newComment
      ? [...processedCommentsState, newComment]
      : processedCommentsState;
    const update = true;
    let displayName = "";

    if (entityTypeId === ReportingEntityTypeEnum.FISHER) {
      displayName = fieldValues.businessName
        ? `${fieldValues.firstName} ${fieldValues.lastName} | ${fieldValues.businessName}`
        : `${fieldValues.firstName} ${fieldValues.lastName}`;
      const body = {
        ...fieldValues,
        legacyFisherCode: legacyFisherCode,
        name: displayName,
        typeId: entityTypeId,
        id,
        archivedAt: archivedAtSave,
        comments: updatedComments,
        categoryId: null
      } as unknown as CreateReportingEntityRequestBody;

      return createReportingEntity.mutate(
        { body, update },
        {
          onSuccess: () => {
            setDetailData && setDetailData({});
          }
        }
      );
    }

    if (entityTypeId === ReportingEntityTypeEnum.VENDOR) {
      if (fieldValues.firstName && fieldValues.lastName) {
        displayName = `${fieldValues.businessName} | ${fieldValues.lastName}, ${fieldValues.firstName}`;
      } else if (fieldValues.firstName || fieldValues.lastName) {
        displayName = `${fieldValues.businessName} | ${fieldValues.firstName}${fieldValues.lastName}`;
      } else {
        displayName = `${fieldValues.businessName}`;
      }
      const body = {
        ...fieldValues,
        legacyFisherCode: legacyFisherCode,
        legacyBuyerCode: legacyBuyerCode,
        name: displayName,
        typeId: entityTypeId,
        categoryId: Number(fieldValues.categoryId),
        id,
        archivedAt: archivedAtSave,
        comments: updatedComments,
        vesselName: null
      } as unknown as CreateReportingEntityRequestBody;

      return createReportingEntity.mutate(
        { body, update },
        {
          onSuccess: () => {
            setDetailData && setDetailData({});
          }
        }
      );
    }
  };
  const handleDeleteReportingEntity = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteReportingEntity.mutate(
        //@ts-ignore
        {},
        {
          onSuccess: () => {
            setDetailData && setDetailData({});
          }
        }
      );
    }
  };
  const handleArchiveButtonClick = () => {
    setFutureArchivedChecked(!futureArchivedChecked);
  };
  const handleCancel = () => {
    setDetailData && setDetailData({});
  };

  const deleteComment = (id: number) => {
    const newCommentsState = reportingEntityCommentsState.filter(
      comment => Number(id) !== comment.id
    );
    setReportingEntityCommentsState(newCommentsState);
  };

  const handleSetEntityTypeId = (typeId: number) => {
    setEntityTypeId(typeId);
  };

  const handleAccountSearch = async (searchTerm: string) => {
    if (searchTerm === "") return; //Stop if there is no search term
    const data = await accountSearch(searchTerm, "Reporter");
    setAccountSearchResults(data);
  };

  const handleLinkAccount = async (accountId: number) => {
    const reportingEntityId = entity.id;

    if (!accountId || !reportingEntityId) return;
    await linkReportingAccount(accountId, reportingEntityId);
    const reportingEntity = await getReportingEntity(reportingEntityId);
    setDetailData(reportingEntity);
  };

  const handleUnlinkAccount = async (accountId: number) => {
    const reportingEntityId = entity.id;

    if (!accountId || !reportingEntityId) return;
    await unlinkReportingAccount(accountId, reportingEntityId);
    const reportingEntity = await getReportingEntity(reportingEntityId);
    setDetailData(reportingEntity);
  };

  const handleClearAccountSearchResults = () => setAccountSearchResults([]);

  return (
    <div>
      <LogItFormRenderer
        config={config}
        pageType={PAGE_TYPES.REPORTING_ENTITY_DETAILS}
        setEntityTypeId={handleSetEntityTypeId}
        entityTypeId={entityTypeId}
        entityTypesList={entityTypesList}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        archivedButtonLabel={archivedButtonLabel}
        handleArchiveButtonClick={handleArchiveButtonClick}
        handleDelete={handleDeleteReportingEntity}
        archivedButtonClasses={archivedButtonClasses}
        reportingEntityComments={reportingEntityCommentsState}
        deleteComment={deleteComment}
        isLocked={isLocked}
        handleIsLockedClick={setIsLocked}
        accountSearchResults={accountSearchResults}
        handleAccountSearch={handleAccountSearch}
        linkedAccounts={entity.accountReportingEntities}
        handleClearAccountSearchResults={handleClearAccountSearchResults}
        handleLinkAccount={handleLinkAccount}
        handleUnlinkAccount={handleUnlinkAccount}
      />

      {entity && (
        <>
          <LastUpdatedLabel
            account={entity.editor}
            timeStamp={entity.updatedAt}
            label="Updated:"
          />
          <LastUpdatedLabel
            account={entity.creator}
            timeStamp={entity.createdAt}
            label="Created:"
          />
        </>
      )}
    </div>
  );
}

export default ReportingEntityDetails;
