import { Formik } from "formik";
import { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Form,
  Row,
  Table
} from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { LastUpdatedLabel } from "../../components";
import ReportingEntityDetailsPopover from "../../components/Overlays/ReportingEntityDetailsPopover";
import { Account, ReportingEntity } from "../../models";
import { AccountReportingEntities } from "../../models/AccountReportingEntities";
import { generatePassword } from "../../utils";
import passwordValidationSchema from "../../utils/validation/passwordValidationSchema";
import {
  UpdateAccountRequestBody,
  linkReportingEntity,
  reportingEntitySearch,
  setAccountPassword,
  unlinkReportingEntity,
  updateAccount,
  updateAccountStatus,
  useDeleteReporterAccount,
  useReporterAccountQuery
} from "../queries";
import { resetPasswordByEmail } from "../queries/resetPasswordQueries";

function ReporterAccountDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { account, newAccount } = location.state as {
    account: Account;
    newAccount?: boolean;
  };

  const { data: reporterAccountData } = useReporterAccountQuery(account.id);
  const deleteReporterAccount = useDeleteReporterAccount(account.id);
  const [selectedAccountStatus, setSelectedAccountStatus] = useState(-1);
  const [requirePasswordReset, setRequirePasswordReset] = useState(true);
  const [reporterAccount, setReporterAccount] = useState<Account>();
  const [accountReportingEntities, setAccountReportingEntities] = useState<
    AccountReportingEntities[]
  >([]);
  const [reportingEntitySearchResults, setReportingEntitySearchResults] =
    useState<ReportingEntity[]>([]);

  const userInformationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email Address is required")
  });

  const passwordSchema = yup.object().shape({
    password: passwordValidationSchema
  });

  useEffect(() => {
    if (!reporterAccountData) return;
    setReporterAccount(reporterAccountData);
    setAccountReportingEntities(reporterAccountData.accountReportingEntities);
  }, [reporterAccountData]);

  const handleSearch = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.currentTarget;
    const searchTerm = target.searchTerm.value;

    if (searchTerm === "") return; //Stop if there is no search term
    const data = await reportingEntitySearch(searchTerm);
    setReportingEntitySearchResults(data);
  };

  const handleLinkReporter = async (reportingEntityId: number) => {
    const accountId = reporterAccount?.id;

    if (!reportingEntityId || !accountId) return; //Stop if parameters are not defined
    const linkedReportingEntitiesData = await linkReportingEntity(
      accountId,
      reportingEntityId
    );

    setAccountReportingEntities(linkedReportingEntitiesData);
  };

  const handleUnlinkReporter = async (reportingEntityId: number) => {
    const accountId = reporterAccount?.id;

    if (!reportingEntityId || !accountId) return; //Stop if parameters are not defined
    const linkedReportingEntitiesData = await unlinkReportingEntity(
      accountId,
      reportingEntityId
    );

    setAccountReportingEntities(linkedReportingEntitiesData);
  };

  const handleSaveAccount = async (values: { name: string; email: string }) => {
    // If account is undefined, do nothing
    if (!reporterAccount) return;

    const updateAcct: UpdateAccountRequestBody = {
      id: reporterAccount.id,
      loginId: reporterAccount.loginId
    };

    if (values.name) updateAcct.name = values.name;
    if (values.email) updateAcct.email = values.email;

    await updateAccount(updateAcct);
  };

  const handleCancel = () => {
    if (newAccount) {
      navigate("/reporter-accounts");
    } else {
      navigate(-1);
    }
  };

  const handlePasswordChange = async (values: { password: string }) => {
    if (!reporterAccount?.id) return;
    const response = await setAccountPassword(
      reporterAccount.id,
      values.password,
      requirePasswordReset
    );

    setReporterAccount(response);
  };

  const handleEmailPasswordResetLink = async () => {
    if (!reporterAccount?.loginId) return;
    await resetPasswordByEmail(reporterAccount?.loginId);
  };

  const handleUpdateStatus = async () => {
    if (!reporterAccount?.id) return;
    const response = await updateAccountStatus(
      reporterAccount.id,
      selectedAccountStatus
    );
    if (response) {
      setReporterAccount(response);
      setSelectedAccountStatus(-1);
    }
  };

  const handleDeleteAccount = () => {
    if (!reporterAccount?.id) return;
    if (
      window.confirm(
        "Are you sure you want to delete this account? This cannot be undone."
      )
    ) {
      deleteReporterAccount.mutate(
        //@ts-ignore
        {},
        {
          onSuccess: () => {
            setTimeout(() => {
              navigate("/reporter-accounts");
            }, 1500);
          }
        }
      );
    }
  };

  const hasLinkedReportingEntity = (reportingEntity: ReportingEntity) => {
    if (
      accountReportingEntities.some(
        (x: any) => x.reportingEntityId === reportingEntity.id
      )
    ) {
      return true;
    }
    return false;
  };

  const getVariantColor = () => {
    const status = reporterAccount?.status.name;
    if (!status) return;

    switch (status) {
      case "Active":
        return "success";
      case "Archived":
        return "danger";
      default:
        return "warning"; //could not find status value
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>User Information</Card.Title>

          <Formik
            validationSchema={userInformationSchema}
            onSubmit={values => handleSaveAccount(values)}
            enableReinitialize
            initialValues={{
              name: reporterAccount?.name ?? "",
              email: reporterAccount?.email ?? ""
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors
              // setFieldValue,
              // setFieldTouched
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      value={reporterAccount?.loginId ?? ""}
                      disabled
                      readOnly
                    ></Form.Control>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name && touched.name}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email && touched.email}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Button
                  variant="outline-success"
                  className="me-3"
                  type="submit"
                >
                  Save User Information
                </Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Linked Reporters</Card.Title>
          <Card.Subtitle className="text-body-tertiary mb-3">
            Reporting entities linked to this account. You can view or unlink
            the reporting entities from this account.
          </Card.Subtitle>
          {reporterAccount && accountReportingEntities?.length > 0 ? (
            <Table responsive className="border">
              <thead>
                <tr>
                  <th className="bg-body-secondary">Name</th>
                  <th className="bg-body-secondary">Type</th>
                  <th className="bg-body-secondary">Category</th>
                  <th className="bg-body-secondary">License</th>
                  <th className="bg-body-secondary">Phone</th>
                  <th className="bg-body-secondary">Status</th>
                  <th className="bg-body-secondary">Action</th>
                </tr>
              </thead>
              <tbody>
                {accountReportingEntities.map(linkedReporter => {
                  if (!linkedReporter.reportingEntity) return <></>;
                  return (
                    <tr key={linkedReporter.reportingEntityId}>
                      <td>
                        <ReportingEntityDetailsPopover
                          reportingEntity={linkedReporter.reportingEntity}
                          value={linkedReporter.reportingEntity.name}
                        />
                      </td>
                      <td>
                        <Badge
                          pill
                          bg={`${
                            linkedReporter.reportingEntity.type?.id === 10
                              ? "yellow"
                              : "red"
                          }`}
                        >
                          {linkedReporter.reportingEntity.type?.name}
                        </Badge>
                      </td>
                      <td>{linkedReporter.reportingEntity?.category?.name}</td>
                      <td>{linkedReporter.reportingEntity.license}</td>
                      <td>{linkedReporter.reportingEntity.phoneNumber}</td>
                      <td>
                        <span
                          className={`status-dot status-dot-${
                            linkedReporter.reportingEntity.archivedAt
                              ? "archived"
                              : "active"
                          }`}
                        >
                          {linkedReporter.reportingEntity.archivedAt
                            ? "Archived"
                            : "Active"}
                        </span>
                      </td>
                      <td>
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() =>
                            handleUnlinkReporter(
                              linkedReporter.reportingEntityId
                            )
                          }
                        >
                          Unlink Reporter
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <p>
              <InfoCircle className="text-primary me-2" />
              There are no linked reporters. Link reporters from the search
              below.
            </p>
          )}

          <Form onSubmit={handleSearch} className="mt-5">
            <Card.Title>Link Existing Reporter</Card.Title>
            <Card.Subtitle className="text-body-tertiary mb-3">
              You can search for a Reporter by name, phone, or license number.
              You link MANY reporters to this Reporter Account.
            </Card.Subtitle>
            <Row className="align-items-end mb-4">
              <Form.Group as={Col} sm={6} className="mb-3">
                <Form.Label>Search by name, phone, or license</Form.Label>
                <Form.Control type="text" name="searchTerm"></Form.Control>
              </Form.Group>
              <Col sm={6}>
                <Button
                  variant="outline-primary"
                  className="mb-3 me-2"
                  type="submit"
                >
                  Search for Reporter
                </Button>
                <Button
                  variant="outline-dark"
                  className="mb-3"
                  type="button"
                  onClick={() => setReportingEntitySearchResults([])}
                >
                  Clear Results
                </Button>
              </Col>
            </Row>
            {reportingEntitySearchResults.length > 0 && (
              <>
                <div className="d-flex align-items-end mb-3">
                  <span className="lead me-3">
                    <strong>Search Results</strong>
                  </span>
                </div>
                <Table responsive className="border">
                  <thead>
                    <tr>
                      <th className="bg-body-secondary">Name</th>
                      <th className="bg-body-secondary">Type</th>
                      <th className="bg-body-secondary">Category</th>
                      <th className="bg-body-secondary">License</th>
                      <th className="bg-body-secondary">Phone</th>
                      <th className="bg-body-secondary">Status</th>
                      <th className="bg-body-secondary">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportingEntitySearchResults.map(reportingEntity => (
                      <tr key={reportingEntity.id}>
                        <td>
                          <ReportingEntityDetailsPopover
                            reportingEntity={reportingEntity}
                            value={reportingEntity.name}
                          />
                        </td>
                        <td>
                          <Badge
                            pill
                            bg={`${
                              reportingEntity.type?.id === 10 ? "yellow" : "red"
                            }`}
                          >
                            {reportingEntity.type?.name}
                          </Badge>
                        </td>
                        <td>{reportingEntity?.category?.name}</td>
                        <td>{reportingEntity.license}</td>
                        <td>{reportingEntity.phoneNumber}</td>
                        <td>
                          <span
                            className={`status-dot status-dot-${
                              reportingEntity.archivedAt ? "archived" : "active"
                            }`}
                          >
                            {reportingEntity.archivedAt ? "Archived" : "Active"}
                          </span>
                        </td>
                        <td>
                          {hasLinkedReportingEntity(reportingEntity) ? (
                            <Button
                              variant="outline-danger"
                              size="sm"
                              onClick={() =>
                                handleUnlinkReporter(reportingEntity.id)
                              }
                            >
                              Unlink Reporter
                            </Button>
                          ) : (
                            <Button
                              variant="outline-success"
                              size="sm"
                              onClick={() =>
                                handleLinkReporter(reportingEntity.id)
                              }
                            >
                              Link Reporter
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                    <tr className="border-top"></tr>
                  </tbody>
                </Table>
              </>
            )}
          </Form>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title className="mb-3">Send Password Reset Link</Card.Title>
          <div className="mb-3">
            <Button
              variant="outline-success"
              onClick={handleEmailPasswordResetLink}
            >
              Email Password Reset Link
            </Button>
          </div>
          <Formik
            validationSchema={passwordSchema}
            onSubmit={values => handlePasswordChange(values)}
            enableReinitialize
            initialValues={{
              password: ""
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setFieldValue
            }) => (
              <Form onSubmit={handleSubmit}>
                <Card.Title className="mb-3 mt-5">Change Password</Card.Title>
                <Row className="align-items-end">
                  <Form.Group as={Col} xs={6} className="mb-3">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="text"
                      name="password"
                      onChange={handleChange}
                      isInvalid={!!errors.password && touched.password}
                      value={values.password}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} xs={6} className="mb-3">
                    <Button
                      variant="outline-dark"
                      onClick={() =>
                        setFieldValue("password", generatePassword())
                      }
                    >
                      Generate password
                    </Button>
                  </Form.Group>
                </Row>
                {!!errors.password && touched.password && (
                  <div className="invalid-feedback d-block">
                    {errors.password}
                  </div>
                )}
                <Form.Check
                  className="mb-3"
                  inline
                  label="Require password change on next login"
                  name="require-password-change"
                  type="checkbox"
                  checked={requirePasswordReset}
                  onChange={() =>
                    setRequirePasswordReset(!requirePasswordReset)
                  }
                />
                <Button variant="outline-success d-block mb-3" type="submit">
                  Change Password
                </Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title className="mb-3">Account Settings</Card.Title>
          <Row className="mb-3">
            <Col xs={6}>
              <Alert variant={getVariantColor()}>
                Account status:{" "}
                <strong>{reporterAccount?.status.name || "Undefined"}</strong>
              </Alert>
            </Col>
          </Row>
          <Row className="align-items-end">
            <Form.Group as={Col} xs={6} className="mb-3">
              <Form.Label className="me-3">Change Account Status</Form.Label>
              <Form.Select
                onChange={e =>
                  setSelectedAccountStatus(parseInt(e.currentTarget.value))
                }
              >
                <option value={-1}>
                  {reporterAccount?.status.name || "Undefined"} (Current)
                </option>
                {reporterAccount?.status.SourceAccountStatusTransition?.map(
                  status => (
                    <option
                      value={status.destAccountStatus.id}
                      key={status.destAccountStatus.id}
                    >
                      {status.transitionVerb}
                    </option>
                  )
                )}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} xs={6} className="mb-3">
              {selectedAccountStatus !== -1 && (
                <Button onClick={handleUpdateStatus} variant="outline-success">
                  Update Status
                </Button>
              )}
            </Form.Group>
          </Row>
          <div className="d-flex justify-content-end">
            <Button variant="outline-danger" onClick={handleDeleteAccount}>
              Delete Account
            </Button>
          </div>
        </Card.Body>
      </Card>

      <Row className="mb-5">
        <Col xs={6}>
          <Button variant="outline-primary" onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
        <Col xs={6}>
          <LastUpdatedLabel
            account={account.editor}
            timeStamp={account.updatedAt}
            label="Updated:"
          />
          <LastUpdatedLabel
            account={account.creator}
            timeStamp={account.createdAt}
            label="Created:"
          />
        </Col>
      </Row>
    </>
  );
}

export default ReporterAccountDetails;
