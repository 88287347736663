import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { ApiService } from "../../services";
import { QueryParamOptions } from "../../services/ApiService";
import { makeKeyFactory } from "./makeKeyFactory";

const { get } = new ApiService();
const ONE_HOUR = 60 * 60 * 1000;
const PICKLIST_QUERY_FILTER = "picklist";

const FISHING_AREAS_ENDPOINT = "fishing-areas";
const FISHING_METHODS_ENDPOINT = "fishing-methods";
const SPECIES_ENDPOINT = "species";
const SPECIES_CONDITIONS_ENDPOINT = "species-conditions";
const PURCHASE_TYPES_ENDPOINT = "purchase-types";
const PURCHASE_REPORT_STATUSES_ENDPOINT = "purchase-report-statuses";
const REPORTING_ENTITY_CATEGORIES_ENDPOINT = "reporting-entity-categories";
const REPORTING_ENTITY_TYPES_ENDPOINT = "reporting-entity-types";
const PURCHASE_REPORT_BOOK_STATUS_ENDPOINT = "purchase-report-book-status";

export const fishingAreaKeys = makeKeyFactory("FishingAreas");
export const fishingMethodKeys = makeKeyFactory("FishingMethods");
export const speciesKeys = makeKeyFactory("Species");
export const speciesConditionKeys = makeKeyFactory("SpeciesConditions");
export const purchaseTypeKeys = makeKeyFactory("PurchaseTypes");
export const purchaseReportStatusKeys = makeKeyFactory(
  "PurchaseReportStatuses"
);
export const reportingEntityCategoryKeys = makeKeyFactory(
  "ReportingEntityCategories"
);
export const reportingEntityTypeKeys = makeKeyFactory("ReportingEntityTypes");
export const purchaseReportBookStatusKeys = makeKeyFactory(
  "PurchaseReportBookStatus"
);
export const useFishingAreasQuery = (queryOptions?: QueryParamOptions) => {
  const areas = useQuery(
    fishingAreaKeys.list(PICKLIST_QUERY_FILTER),
    async () => await get(FISHING_AREAS_ENDPOINT, queryOptions),
    {
      staleTime: ONE_HOUR,
      select: data => {
        return data.map(
          (area: { id: number; name: string; archivedAt: string }) => {
            return {
              id: area.id,
              value: String(area.id),
              label: area.name,
              archivedAt: area.archivedAt
            };
          }
        );
      },
      onSuccess: data => {
        return data;
      },
      onError: error => {
        toast("Error Fetching Fishing Areas", {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return areas;
};

export const useFishingMethodsQuery = (queryOptions?: QueryParamOptions) => {
  const methods = useQuery(
    fishingMethodKeys.list(PICKLIST_QUERY_FILTER),
    async () => await get(FISHING_METHODS_ENDPOINT, queryOptions),
    {
      staleTime: ONE_HOUR,
      select: data => {
        return data?.map(
          (method: { methodId: number; name: string; archivedAt: string }) => {
            return {
              id: method.methodId,
              value: String(method.methodId),
              label: method.name,
              archivedAt: method.archivedAt
            };
          }
        );
      },
      onSuccess: data => {
        return data;
      },
      onError: error => {
        toast("Error Fetching Methods", {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return methods;
};

export const useSpeciesQuery = (queryOptions?: QueryParamOptions) => {
  const species = useQuery(
    speciesKeys.list(PICKLIST_QUERY_FILTER),
    async () => await get(SPECIES_ENDPOINT, queryOptions),
    {
      staleTime: ONE_HOUR,
      select: data => {
        return data?.map(
          (species: {
            speciesId: number;
            name: string;
            code: number;
            species: { name: string };
            archivedAt: string;
            minPrice: number;
            maxPrice: number;
            minWeight: number;
            maxWeight: number;
            piecesRequired: number;
          }) => {
            return {
              id: species.speciesId,
              value: String(species.speciesId),
              label: `${species.code} | ${species.name} | ${species.species.name}`,
              archivedAt: species.archivedAt,
              minPrice: species.minPrice,
              maxPrice: species.maxPrice,
              minWeight: species.minWeight,
              maxWeight: species.maxWeight,
              piecesRequired: species.piecesRequired
            };
          }
        );
      },
      onSuccess: data => {
        return data;
      },
      onError: error => {
        toast("Error Fetching Species", {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return species;
};

export const useSpeciesConditionsQuery = (queryOptions?: QueryParamOptions) => {
  const conditions = useQuery(
    speciesConditionKeys.list(PICKLIST_QUERY_FILTER),
    async () => await get(SPECIES_CONDITIONS_ENDPOINT, queryOptions),
    {
      staleTime: ONE_HOUR,
      select: data => {
        return data?.map(
          (condition: { code: string; name: string; archivedAt: string }) => {
            return {
              id: condition.code,
              value: condition.code,
              label: condition.name,
              archivedAt: condition.archivedAt
            };
          }
        );
      },
      onSuccess: data => {
        return data;
      }
    }
  );
  return conditions;
};

export const usePurchaseTypesQuery = (queryOptions?: QueryParamOptions) => {
  const purchaseTypes = useQuery(
    purchaseTypeKeys.list(PICKLIST_QUERY_FILTER),
    async () => await get(PURCHASE_TYPES_ENDPOINT, queryOptions),
    {
      staleTime: ONE_HOUR,
      select: data => {
        return data.map(
          (type: { id: number; name: string; archivedAt: string }) => {
            return {
              id: type.id,
              value: String(type.id),
              label: type.name,
              archivedAt: type.archivedAt
            };
          }
        );
      },
      onSuccess: data => {
        return data;
      },
      onError: error => {
        toast("Error Fetching Purchase Types", {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return purchaseTypes;
};

export const usePurchaseReportStatusesQuery = (
  queryOptions?: QueryParamOptions
) => {
  const purchaseReportStatuses = useQuery(
    purchaseReportStatusKeys.list(PICKLIST_QUERY_FILTER),
    async () => await get(PURCHASE_REPORT_STATUSES_ENDPOINT, queryOptions),
    {
      staleTime: ONE_HOUR,
      select: data => {
        return data.map(
          (status: { id: number; name: string; archivedAt: string }) => {
            return {
              id: status.id,
              value: String(status.id),
              label: status.name,
              archivedAt: status.archivedAt
            };
          }
        );
      },
      onSuccess: data => {
        return data;
      },
      onError: error => {
        toast("Error Fetching Purchase Report Statuses", {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return purchaseReportStatuses;
};

export const useReportingEntityCategoriesQuery = (
  queryOptions?: QueryParamOptions
) => {
  const reports = useQuery(
    reportingEntityCategoryKeys.list(PICKLIST_QUERY_FILTER),
    async () => await get(REPORTING_ENTITY_CATEGORIES_ENDPOINT, queryOptions),
    {
      staleTime: ONE_HOUR,
      select: data => {
        return data.map(
          (category: { id: number; name: string; archivedAt: string }) => {
            return {
              id: category.id,
              value: String(category.id),
              label: category.name,
              archivedAt: category.archivedAt
            };
          }
        );
      },
      onSuccess: data => {
        return data;
      },
      onError: async () => {
        toast("Error Fetching Reporting Entity Categories", {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return reports;
};

export const useReportingEntityTypesQuery = (
  queryOptions?: QueryParamOptions
) => {
  const types = useQuery(
    reportingEntityTypeKeys.list(PICKLIST_QUERY_FILTER),
    async () => await get(REPORTING_ENTITY_TYPES_ENDPOINT, queryOptions),
    {
      staleTime: ONE_HOUR,
      select: data => {
        return data.map((type: { id: number; name: string }) => {
          return {
            id: type.id,
            name: type.name
          };
        });
      },
      onSuccess: data => {
        return data;
      },
      onError: async () => {
        toast("Error Fetching Reporting Entity Types", {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return types;
};

export const usePurchaseReportBookStatusQuery = () => {
  const purchaseReportBookStatus = useQuery(
    purchaseReportBookStatusKeys.list(PICKLIST_QUERY_FILTER),
    async () => await get(PURCHASE_REPORT_BOOK_STATUS_ENDPOINT),
    {
      staleTime: ONE_HOUR,
      select: data => {
        return data.map(
          (prbs: { id: number; name: string; archivedAt: string }) => {
            return {
              id: prbs.id,
              value: String(prbs.id),
              label: prbs.name,
              archivedAt: prbs.archivedAt
            };
          }
        );
      },
      onSuccess: data => {
        return data;
      },
      onError: error => {
        toast("Error Fetching Purchase Report Book Status", {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return purchaseReportBookStatus;
};
