import { useEffect, useMemo, useRef, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { LogItButtonRound, LogItPageHeader } from "../../components";
import LogItTable from "../../components/Tables/LogItTable";
import { isEmptyObj } from "../../utils";
import {
  usePurchaseReportBookStatusQuery,
  usePurchaseReportBooksQuery,
  useReportingEntitiesQuery
} from "../queries";
import NewPurchaseReportBook from "./NewPurchaseReportBook";
import PurchaseReportBookDetails from "./PurchaseReportBookDetails";
import { purchaseReportBooksTableConfig as tableConfig } from "./tableConfig";

type TableViewProps = {
  purchaseReportBooks: { [key: string]: any }[];
  setSearchResults: (data: []) => void;
  setDetailData?: React.Dispatch<React.SetStateAction<object>>;
  searchBarRef?: any;
};

function formatDateString(sourceDate: string) {
  //Purchase Date is stored in DB as Date datatype.
  //Is is returned by API as YYYY-MM-DDT00:00:00.000Z
  //Only the date component is needed and should not have local offset applied.
  const re = /[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}T/;
  if (re.test(sourceDate)) {
    const [yearStr, monthStr, dayStr] = sourceDate.split("T")[0].split("-");
    return `${monthStr}/${dayStr}/${yearStr}`;
  } else {
    return sourceDate;
  }
}

function PurchaseReportBooks({
  purchaseReportBook,
  setPurchaseReportBook
}: any) {
  const navigate = useNavigate();
  const params = useParams();
  const [heading, setHeading] = useState("");
  const { data } = usePurchaseReportBooksQuery("all", {
    include: "reportCount"
  });
  const { data: purchaseReportBookStatusOptsData } =
    usePurchaseReportBookStatusQuery();
  const { data: rEntOptsData } = useReportingEntitiesQuery("picklist", {
    sort: "name",
    fields: "id,typeId,legacyFisherCode,legacyBuyerCode,name,type,archivedAt"
  });
  let searchBarRef = useRef<HTMLInputElement>(null);

  const [purchaseReportBooks, setPurchaseReportBooks] = useState([]);
  const isBasePath =
    params["*"]?.length === 0 && isEmptyObj(purchaseReportBook);
  useEffect(() => {
    searchBarRef.current?.focus();
  }, [purchaseReportBooks, purchaseReportBook, params]);
  const handleBackArrow = () => {
    // When browser popstate is called, if on detail page, navigate to purchase-report-books
    if (!isEmptyObj(purchaseReportBook)) {
      setPurchaseReportBook({});
      navigate("/purchase-report-books");
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", handleBackArrow);
    return () => {
      window.removeEventListener("popstate", handleBackArrow);
    };
  });
  useEffect(() => {
    if (isBasePath) {
      setHeading("Purchase Report Books");
    } else if (params["*"] === "new") {
      setHeading("Add New Purchase Report Book");
    } else if (!isEmptyObj(purchaseReportBook)) {
      setHeading("View/Edit Purchase Report Book");
    } else if (!isBasePath) {
      navigate("/purchase-reports");
    }
    setPurchaseReportBooks(data);
  }, [isBasePath, params, data, purchaseReportBook, navigate]);

  const tableData = useMemo(() => {
    return purchaseReportBooks?.map((item: Record<string, any>) => {
      if (item.issuedDate) {
        item.issuedDate = formatDateString(item.issuedDate);
      }
      if (item.updatedAt) {
        item.updatedAt = new Date(item.updatedAt).toLocaleString();
      }
      if (item.createdAt) {
        item.createdAt = new Date(item.createdAt).toLocaleString();
      }
      return item;
    });
  }, [purchaseReportBooks]);

  const goToNewPurchaseReportBookForm = () => {
    navigate("new");
  };

  const renderButtons = () => {
    return (
      <LogItButtonRound
        label="Add New Purchase Report Book"
        onClick={goToNewPurchaseReportBookForm}
        buttonStyle="filled"
      />
    );
  };

  const setSearchResults = (data: []) => {
    setPurchaseReportBooks(data);
  };

  if (!purchaseReportBooks) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Fetching books...</span>
        </div>
      </div>
    );
  }
  const renderTable = () => {
    return (
      <>
        <div className={!isEmptyObj(purchaseReportBook) ? "d-none" : ""}>
          <TableView
            purchaseReportBooks={tableData}
            setDetailData={setPurchaseReportBook}
            setSearchResults={setSearchResults}
            searchBarRef={searchBarRef}
          />
        </div>
        {!isEmptyObj(purchaseReportBook) ? (
          <PurchaseReportBookDetails
            prbsOptions={purchaseReportBookStatusOptsData}
            reportingEntityOptions={rEntOptsData}
            purchaseReportBook={purchaseReportBook}
            setDetailData={setPurchaseReportBook}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const handleEscape = () => {
    if (params["*"] === "new") {
      navigate("/purchase-report-books");
    }
    if (!isEmptyObj(purchaseReportBook)) {
      setPurchaseReportBook({});
    }
  };

  return (
    <div
      onKeyDown={e => {
        if (e.key === "Escape") {
          handleEscape();
        }
      }}
    >
      <LogItPageHeader
        heading={heading}
        renderButtons={isBasePath ? renderButtons : null}
      />

      <Routes>
        <Route path="/" element={renderTable()} />
        <Route
          path="new"
          element={
            <NewPurchaseReportBook
              prbsOptions={purchaseReportBookStatusOptsData}
              reportingEntityOptions={rEntOptsData}
            />
          }
        />
      </Routes>
    </div>
  );
}

function TableView({
  purchaseReportBooks,
  setSearchResults,
  setDetailData,
  searchBarRef
}: TableViewProps) {
  const tableColumns = useMemo(
    () => tableConfig.filter(({ display }) => display),
    []
  );
  return (
    <LogItTable
      columns={tableColumns}
      setSearchResults={setSearchResults}
      data={purchaseReportBooks}
      type="purchaseReportBooks"
      setDetailData={setDetailData}
      ref={searchBarRef}
    />
  );
}

export default PurchaseReportBooks;
