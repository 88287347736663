import {
  NumberDictionary,
  adjectives,
  animals,
  uniqueNamesGenerator
} from "unique-names-generator";

function generateSpecialCharArray() {
  const specialChars = "@$!%*?&";
  return specialChars.split("");
}

export const generatePassword = () => {
  const numberDictionary = NumberDictionary.generate({ min: 100, max: 999 });
  const specialCharacters = generateSpecialCharArray();
  const randomPass = uniqueNamesGenerator({
    dictionaries: [adjectives, animals, numberDictionary, specialCharacters],
    separator: "",
    style: "capital"
  });

  return randomPass;
};
