import { customAlphabet } from "nanoid";
import { useEffect, useState } from "react";
import { LastUpdatedLabel, SelectSearchOptions } from "../../components";
import {
  LogItFormRenderer,
  LogItFormRendererInputProps
} from "../../components/Forms/LogItFormRenderer";
import { ReportingEntity, ReportingEntityTypeEnum } from "../../models";
import { getAccountId } from "../../services/ApiService";
import {
  PAGE_TYPES,
  getRowId,
  passBooleanValOrNull,
  passNumberValOrNull,
  passStringValOrNull,
  roundTwoPlaces
} from "../../utils";
import {
  useCreatePurchaseReport,
  useDeletePurchaseReport
} from "../queries/purchaseReportQueries";
// Dynamically load form config for TARGET_REGION
const TARGET_REGION = process.env.REACT_APP_TARGET_REGION;
let purchaseReportDetailFormConfig: LogItFormRendererInputProps[] = [];
import(`../../config/${TARGET_REGION}/purchaseReportFormConfig`).then(
  module => {
    purchaseReportDetailFormConfig = module.purchaseReportDetailFormConfig;
  }
);

type PurchaseReportDetailsProps = {
  reportingEntityOptions: ReportingEntity[];
  areasOptions: SelectSearchOptions[];
  methodsOptions: SelectSearchOptions[];
  speciesOptions: SelectSearchOptions[];
  purchaseTypesOptions: SelectSearchOptions[];
  reportStatusOptions: SelectSearchOptions[];
  speciesConditionOptions: SelectSearchOptions[];
  purchaseReport: any;
  setDetailData: any;
};

type PurchaseReportItemProps = {
  _uid?: string | number;
  id?: number;
  conditionCode: string | null;
  createdAt?: string;
  species_pieces: number | string;
  species_price: string;
  species?: {
    id?: number;
    name: string;
  };
  species_value: string;
  species_weight: string;
  defaultValue?: any;
};
type IType = {
  species_price?: string;
  species_weight?: string;
  species_value?: string;
  species_pieces?: string;
  species_constraints?: any;
};

const formatDate = (date: Date) => {
  const newDate = new Date(date);
  const year = newDate.getFullYear();

  let month = (1 + newDate.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  let day = newDate.getDate().toLocaleString();
  day = day.length > 1 ? day : "0" + day;

  return `${year}-${month}-${day}`;
};

function PurchaseReportDetails({
  reportingEntityOptions,
  areasOptions,
  methodsOptions,
  speciesOptions,
  purchaseTypesOptions,
  reportStatusOptions,
  speciesConditionOptions,
  purchaseReport,
  setDetailData
}: PurchaseReportDetailsProps) {
  const [purchaseReportItemsState, setPurchaseReportItemsState] = useState<
    PurchaseReportItemProps[]
  >([]);

  const id = Number(purchaseReport.id);
  const deletePurchaseReport = useDeletePurchaseReport(id);

  if (!purchaseReport) {
    window.location.replace(`${window.location.origin}/purchase-reports`);
  }

  const createPurchaseReport = useCreatePurchaseReport("Updated");

  const [inputs, setInputs] = useState<IType>({
    species_price: "",
    species_weight: "",
    species_value: "",
    species_pieces: "",
    species_constraints: null
  });

  const [fisherInputs, setFisherInputs] = useState<number>(0);
  const [totalPieces, setTotalPieces] = useState("");
  const [totalFishers, setTotalFishers] = useState("");
  const [totalWeight, setTotalWeight] = useState("0");
  const [validFisher, setValidFisher] = useState<boolean>(true);
  const [totalValue, setTotalValue] = useState("0");
  const [totalAdjustment, setTotalAdjustment] = useState(
    purchaseReport?.valueAdjustment || "0"
  );
  const [adjustedTotalValue, setAdjustedTotalValue] = useState("0");
  const [isLocked, setIsLocked] = useState<boolean>(true);

  useEffect(() => {
    setPurchaseReportItemsState(purchaseReport.purchaseReportItems);
  }, [purchaseReport.purchaseReportItems]);

  useEffect(() => {
    purchaseReport.purchaseReportItems.map((item: any) => {
      const priceKey = `species_price_${item.id}`;
      const weightKey = `species_weight_${item.id}`;
      const valueKey = `species_value_${item.id}`;
      const piecesKey = `species_pieces_${item.id}`;
      const constraintsKey = `species_constraints_${item.id}`;
      return setInputs(_state => {
        return {
          ..._state,
          [priceKey]: item.price,
          [weightKey]: item.weight,
          [valueKey]: item.value,
          [piecesKey]: item.pieces,
          [constraintsKey]: speciesOptions.find(
            species => species.id === item.speciesId
          )
        };
      });
    });
  }, [purchaseReport.purchaseReportItems, speciesOptions]);

  useEffect(() => {
    let weightTotal = 0;
    let valueTotal = 0;
    let piecesTotal = 0;
    for (const key in inputs) {
      if (key.includes("species_weight")) {
        weightTotal += Number(inputs[key as keyof IType]) || 0;
        setTotalWeight(String(roundTwoPlaces(weightTotal)));
      }
      if (key.includes("species_value")) {
        valueTotal += Number(inputs[key as keyof IType]) || 0;
        setTotalValue(String(roundTwoPlaces(valueTotal)));
      }
      if (key.includes("species_pieces")) {
        let piecesTotalDisplay = "";
        piecesTotal += Number(inputs[key as keyof IType]) || 0;
        if (piecesTotal) {
          piecesTotalDisplay = String(Number(piecesTotal).toFixed());
        }
        setTotalPieces(piecesTotalDisplay);
      }
    }
  }, [inputs, purchaseReportItemsState]);

  useEffect(() => {
    setAdjustedTotalValue(
      String(roundTwoPlaces(Number(totalValue) + Number(totalAdjustment)))
    );
  }, [totalValue, totalAdjustment]);

  const config = purchaseReportDetailFormConfig
    .filter(input => !input.name.includes("species"))
    .map(input => {
      delete input.defaultValue;
      switch (input.name) {
        case "reportNumber": {
          input.readonly = isLocked;
          input.defaultValue = purchaseReport.reportNumber;
          break;
        }
        case "fisherCount":
          const handleValidFisherCount = (
            e: React.FocusEvent<HTMLInputElement>
          ) => {
            const { value } = e.target;
            setValidFisher(fisherInputs === Number(value));
          };
          input.handleOnBlur = handleValidFisherCount;
          input.isvalidinput = validFisher;
          input.defaultValue = purchaseReport.fisherCount;
          break;
        case "seller": {
          input.defaultValue = [
            {
              id: purchaseReport.seller.id,
              value: String(purchaseReport.seller.id),
              label: purchaseReport.seller.name,
              badgeTypeId: purchaseReport.seller.typeId,
              badgeTypeLabel: purchaseReport.seller.type?.name
            }
          ];
          input.options = reportingEntityOptions?.map(entity => ({
            value: String(entity.id),
            label: entity.name,
            badgeTypeId: entity.typeId,
            badgeTypeLabel: entity.type?.name,
            archivedAt: entity.archivedAt
          }));
          break;
        }
        case "buyer": {
          input.isReadOnly = isLocked;
          input.defaultValue = [
            {
              id: purchaseReport.buyer.id,
              value: String(purchaseReport.buyer.id),
              label: purchaseReport.buyer.name,
              badgeTypeId: purchaseReport.buyer.typeId,
              badgeTypeLabel: purchaseReport.buyer.type?.name
            }
          ];
          input.options = reportingEntityOptions
            ?.filter(entity => entity.typeId !== ReportingEntityTypeEnum.FISHER)
            .map(entity => ({
              value: String(entity.id),
              label: entity.name,
              badgeTypeId: purchaseReport.buyer.typeId,
              badgeTypeLabel: purchaseReport.buyer.type?.name,
              archivedAt: entity.archivedAt
            }));
          break;
        }
        case "purchaseDate": {
          input.defaultValue = formatDate(purchaseReport.purchaseDate);
          input.max = new Date().toISOString().split("T")[0];
          break;
        }
        case "motorizedVessel": {
          if (purchaseReport.motorizedVessel !== null) {
            input.defaultValue = [
              {
                value: String(purchaseReport.motorizedVessel),
                label: purchaseReport.motorizedVessel ? "Yes" : "No"
              }
            ];
          }
          break;
        }
        case "purchaseReportAreas": {
          input.defaultValue = purchaseReport.purchaseReportAreas.map(
            (item: any) => {
              return {
                id: String(item.areaId),
                value: String(item.areaId),
                label: item.area.name
              };
            }
          );
          input.options = areasOptions;
          break;
        }
        case "purchaseReportFishers": {
          input.defaultValue = purchaseReport?.purchaseReportFishers?.map(
            (item: any) => {
              return {
                id: String(item.fisher.id),
                value: String(item.fisher.id),
                label: item.fisher.name
              };
            }
          );
          input.options = reportingEntityOptions
            ?.filter(
              (entity: { typeId: number }) =>
                entity.typeId === ReportingEntityTypeEnum.FISHER
            )
            .map(
              (entity: {
                id: any;
                name: any;
                typeId: any;
                archivedAt: any;
              }) => ({
                value: String(entity.id),
                label: entity.name,
                archivedAt: entity.archivedAt
              })
            );
          break;
        }
        case "purchaseReportMethods": {
          input.defaultValue = purchaseReport.purchaseReportMethods.map(
            (item: any) => {
              return {
                id: String(item.methodId),
                value: String(item.methodId),
                label: item.method.name
              };
            }
          );
          input.options = methodsOptions;
          break;
        }
        case "purchaseType": {
          input.defaultValue = [
            {
              value: String(purchaseReport?.purchaseType.id),
              label: purchaseReport?.purchaseType.name
            }
          ];
          input.options = purchaseTypesOptions;
          break;
        }
        case "purchaseReportStatus": {
          input.defaultValue = [
            {
              value: String(purchaseReport?.purchaseReportStatus.id),
              label: purchaseReport?.purchaseReportStatus.name
            }
          ];
          input.options = reportStatusOptions;
          break;
        }
        case "comments": {
          input.defaultValue =
            purchaseReport[input.name] !== "undefined"
              ? purchaseReport[input.name]
              : "";
          break;
        }
        default:
          input.defaultValue = purchaseReport[input.name] || "";
      }
      return input;
    });

  if (!config) {
    return null;
  }

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    let multiValues = {
      purchaseReportMethods: [] as any[],
      purchaseReportAreas: [] as any[],
      purchaseReportFishers: [] as any[],
      purchaseReportItems: [] as PurchaseReportItemProps[]
    };

    const getFishers = e.currentTarget.querySelectorAll(
      "[name='purchaseReportFishers']"
    );
    const getMethods = e.currentTarget.querySelectorAll(
      "[name='purchaseReportMethods']"
    );
    const getAreas = e.currentTarget.querySelectorAll(
      "[name='purchaseReportAreas']"
    );

    const getId = (item: Element) => {
      return Number(item.getAttribute("value"));
    };

    getFishers.forEach(item => {
      const id = getId(item);
      if (id) {
        multiValues.purchaseReportFishers.push({
          reportingEntityId: +id,
          updatedBy: getAccountId()
        });
      }
    });
    getMethods.forEach(item => {
      const id = getId(item);
      if (id) {
        multiValues.purchaseReportMethods.push({
          methodId: +id,
          updatedBy: getAccountId()
        });
      }
    });

    getAreas.forEach(item => {
      const id = getId(item);
      if (id) {
        multiValues.purchaseReportAreas.push({
          areaId: +id,
          updatedBy: getAccountId()
        });
      }
    });

    formData.forEach((val, key) => {
      const firstSpeciesRow = "1";
      const speciesKey = key.split("_");
      const rowKey = Number(speciesKey[2]) || firstSpeciesRow;
      if (
        key.startsWith("species_speciesId") ||
        key.startsWith("species_weight") ||
        key.startsWith("species_price") ||
        key.startsWith("species_value")
      ) {
        multiValues.purchaseReportItems[rowKey] = {
          ...multiValues.purchaseReportItems[rowKey],
          [speciesKey[1]]: Number(val),
          id: speciesKey[2] ? Number(speciesKey[2]) : undefined
        };
      }
      if (key.startsWith("species_conditionCode_")) {
        multiValues.purchaseReportItems[rowKey] = {
          ...multiValues.purchaseReportItems[rowKey],
          [speciesKey[1]]: passStringValOrNull(val),
          id: speciesKey[2] ? Number(speciesKey[2]) : undefined
        };
      }
      if (key.startsWith("species_pieces_")) {
        multiValues.purchaseReportItems[rowKey] = {
          ...multiValues.purchaseReportItems[rowKey],
          [speciesKey[1]]: passNumberValOrNull(val),
          id: speciesKey[2] ? Number(speciesKey[2]) : undefined
        };
      }
    });

    const purchaseReportItems = Object.values(
      multiValues.purchaseReportItems as PurchaseReportItemProps[]
    ).map(item => {
      return {
        ...item,
        updatedBy: getAccountId(),
        id: item.id ? item.id : undefined
      };
    });

    multiValues.purchaseReportItems = purchaseReportItems;

    const { ...fieldValues } = Object.fromEntries(formData.entries());
    const body = {
      id: id,
      purchaseDate: new Date(fieldValues.purchaseDate as string),
      reportNumber: Number(fieldValues.reportNumber),
      purchaseTypeId: Number(fieldValues.purchaseType),
      sellerId: Number(fieldValues.seller),
      buyerId: Number(fieldValues.buyer),
      purchaseReportStatusId: Number(fieldValues.purchaseReportStatus),
      comments: passStringValOrNull(fieldValues.comments),
      fisherCount: passNumberValOrNull(fieldValues.fisherCount),
      motorizedVessel: passBooleanValOrNull(fieldValues.motorizedVessel),
      hoursFished: passNumberValOrNull(fieldValues.hoursFished),
      gearsCount: passNumberValOrNull(fieldValues.gearsCount),
      purchaseReportItems: multiValues.purchaseReportItems,
      purchaseReportMethods: multiValues.purchaseReportMethods,
      purchaseReportAreas: multiValues.purchaseReportAreas,
      purchaseReportFishers: multiValues.purchaseReportFishers,
      valueAdjustment: totalAdjustment
    };
    createPurchaseReport.mutate(
      //@ts-ignore
      { body },
      {
        onSuccess: () => {
          setDetailData({});
        }
      }
    );
  };

  const calcValue = (price: string, weight: string) =>
    price && weight
      ? String(roundTwoPlaces(Number(price) * Number(weight)))
      : "";
  const calcPrice = (value: string, weight: string) =>
    value && weight
      ? String(roundTwoPlaces(Number(value) / Number(weight)))
      : "";

  const onChangeForFisherInputs = (name: string, value: string) => {
    if (name === "fisherCount") {
      setTotalFishers(value || "");
      return;
    }
    if (name !== "purchaseReportFishers") return;
    let amountOfAdditional = value ? value.length : fisherInputs;
    setTotalFishers(String(amountOfAdditional + 1));
    setFisherInputs(amountOfAdditional + 1);
    setValidFisher(true);
  };

  const onChangeForField = (name: string, value: string, uid: string) => {
    const nameUid = getRowId(name);
    const base = "species_";
    const priceKey = `${base}price${nameUid}`;
    const weightKey = `${base}weight${nameUid}`;
    const valueKey = `${base}value${nameUid}`;
    const piecesKey = `${base}pieces${nameUid}`;
    let priceVal = name === priceKey ? value : inputs[priceKey as keyof IType];
    const weightVal =
      name === weightKey ? value : inputs[weightKey as keyof IType];
    let valueVal = name === valueKey ? value : inputs[valueKey as keyof IType];
    let piecesVal =
      name === piecesKey ? value : inputs[piecesKey as keyof IType];
    if (priceVal && weightVal) {
      if (name !== valueKey) {
        valueVal = calcValue(priceVal, weightVal);
      }
    }
    if (valueVal && weightVal) {
      if (name !== priceKey) {
        priceVal = calcPrice(valueVal, weightVal);
      }
    }

    setInputs(_state => {
      return {
        ..._state,
        [priceKey]: priceVal,
        [weightKey]: weightVal,
        [valueKey]: valueVal,
        [piecesKey]: piecesVal
      };
    });
  };

  const deleteSpeciesRow = (uid: string) => {
    let newPurchaseReportItemsState = [...purchaseReportItemsState];
    newPurchaseReportItemsState = newPurchaseReportItemsState.filter(
      function (obj: { id?: string | number; _uid?: string | number }) {
        if (obj.id) {
          return obj.id !== Number(uid);
        } else if (obj._uid) {
          return obj._uid !== uid;
        }
        return obj;
      }
    );
    let newInputs = { ...inputs };
    for (const key in newInputs) {
      if (key.includes(uid)) {
        delete newInputs[key as keyof IType];
      }
    }
    setInputs(newInputs);
    setPurchaseReportItemsState(newPurchaseReportItemsState);
  };

  const addSpeciesRow = async () => {
    const customUid = customAlphabet("1234567890abcdef", 10);
    const rowUid = customUid();
    let newState = [...purchaseReportItemsState];
    //@ts-ignore
    newState.push({
      _uid: rowUid,
      conditionCode: "WH",
      createdAt: "",
      species_pieces: "",
      species_price: "",
      species: { name: "" },
      species_value: "",
      species_weight: ""
    });
    await setPurchaseReportItemsState(newState);
  };

  const handleCancel = () => {
    setDetailData({});
  };

  const handleDeletePurchaseReport = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (window.confirm("Are you sure you want to delete this record?")) {
      deletePurchaseReport.mutate(
        //@ts-ignore
        {},
        {
          onSuccess: () => {
            setDetailData({});
          }
        }
      );
    }
  };

  const handleSearchSelection = (speciesId: string, speciesRowId: string) => {
    const constraintsKey = `species_constraints${getRowId(speciesRowId)}`;
    // Clearing of search state
    if (!speciesId) {
      setInputs(_state => {
        return {
          ..._state,
          [constraintsKey]: null
        };
      });
      return;
    }
    // Selecting a new value within the search component
    const selectedSpecies = speciesOptions.find(s => s.value === speciesId);
    setInputs(_state => {
      return {
        ..._state,
        [constraintsKey]: { ...selectedSpecies }
      };
    });
  };

  const onAdjustmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTotalAdjustment(event.target.value);
    setAdjustedTotalValue(
      String(roundTwoPlaces(Number(totalValue) + Number(event.target.value)))
    );
  };

  const onAdjustedTotalChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTotalAdjustment(
      String(roundTwoPlaces(Number(event.target.value) - Number(totalValue)))
    );
    setAdjustedTotalValue(event.target.value);
  };

  return (
    <div>
      <LogItFormRenderer
        config={config}
        speciesOptions={speciesOptions}
        speciesConditionOptions={speciesConditionOptions}
        purchaseReportItems={purchaseReportItemsState}
        addSpeciesRow={addSpeciesRow}
        deleteSpeciesRow={deleteSpeciesRow}
        handleSubmit={handleSave}
        handleCancel={handleCancel}
        pageType={PAGE_TYPES.PURCHASE_REPORT_DETAILS}
        handleDelete={handleDeletePurchaseReport}
        onChangeForField={onChangeForField}
        onChangeForFisherInputs={onChangeForFisherInputs}
        inputs={inputs}
        totals={{ totalPieces, totalValue, totalWeight, totalFishers }}
        fisherInputs={fisherInputs}
        onAdjustmentChange={onAdjustmentChange}
        onAdjustedTotalChange={onAdjustedTotalChange}
        totalAdjustment={totalAdjustment}
        adjustedTotalValue={adjustedTotalValue}
        isLocked={isLocked}
        handleIsLockedClick={setIsLocked}
        handleSearchSelection={handleSearchSelection}
      />
      {purchaseReport && (
        <>
          <LastUpdatedLabel
            account={purchaseReport.editor}
            timeStamp={purchaseReport.updatedAt}
            label="Updated:"
          />
          <LastUpdatedLabel
            account={purchaseReport.creator}
            timeStamp={purchaseReport.createdAt}
            label="Created:"
          />
        </>
      )}
    </div>
  );
}

export default PurchaseReportDetails;
