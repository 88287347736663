import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SelectSearchOptions } from "../../components";
import {
  LogItFormRenderer,
  LogItFormRendererInputProps
} from "../../components/Forms/LogItFormRenderer";
import { ReportingEntityTypeEnum } from "../../models";
import { getAccountId } from "../../services/ApiService";
import { ReportingEntityFormConfig } from "../../types/formTypes";
import { PAGE_TYPES, passComments } from "../../utils";
import {
  CreateReportingEntityRequestBody,
  useCreateReportingEntity
} from "../queries";

// Dynamically load form config for TARGET_REGION
const TARGET_REGION = process.env.REACT_APP_TARGET_REGION;
let reportingEntityFormConfig: ReportingEntityFormConfig = {
  VENDOR: [],
  FISHER: [],
  UNKNOWN: []
};
import(`../../config/${TARGET_REGION}/reportingEntityFormConfig`).then(
  module => {
    reportingEntityFormConfig = module.reportingEntityFormConfig;
  }
);

type NewReportingEntityProps = {
  categoriesOptions: SelectSearchOptions[];
  entityTypesList: { id: number; name: string }[];
};

function NewReportingEntity({
  categoriesOptions,
  entityTypesList
}: NewReportingEntityProps) {
  const navigate = useNavigate();
  const [entityTypeId, setEntityTypeId] = useState<number>(
    ReportingEntityTypeEnum.FISHER
  );
  const createReportingEntity = useCreateReportingEntity("Created");
  const [config, setConfig] = useState<LogItFormRendererInputProps[]>([]);
  const focus = useRef(null);
  useEffect(() => {
    //@ts-ignore
    focus.current?.focus();
  }, [focus]);

  useEffect(() => {
    //@ts-ignore
    const modifiedConfig = reportingEntityFormConfig[
      ReportingEntityTypeEnum[entityTypeId]
      // @ts-ignore
    ].map(item => {
      delete item.defaultValue;
      if (item.name === "name") item.readonly = false;
      if (item.name === "firstName") item.readonly = false;
      if (item.name === "lastName") item.readonly = false;
      if (item.name === "businessName") item.readonly = false;
      if (item.name === "categoryId" && categoriesOptions) {
        item.options = categoriesOptions?.filter(opt => !opt.archivedAt);
      }
      return item;
    });
    setConfig(modifiedConfig);
  }, [categoriesOptions, entityTypeId]);

  if (!config.length) {
    return null;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const { ...fieldValues } = Object.fromEntries(formData.entries());
    const legacyFisherCode =
      typeof fieldValues.legacyFisherCode === "string" &&
      fieldValues.legacyFisherCode.trim() !== ""
        ? parseInt(fieldValues.legacyFisherCode)
        : null;
    const legacyBuyerCode =
      typeof fieldValues.legacyBuyerCode === "string" &&
      fieldValues.legacyBuyerCode.trim() !== ""
        ? parseInt(fieldValues.legacyBuyerCode)
        : null;
    let displayName = "";
    if (entityTypeId === ReportingEntityTypeEnum.FISHER) {
      displayName = fieldValues.businessName
        ? `${fieldValues.firstName} ${fieldValues.lastName} | ${fieldValues.businessName}`
        : `${fieldValues.firstName} ${fieldValues.lastName}`;
      const body = {
        ...fieldValues,
        legacyFisherCode: legacyFisherCode,
        name: displayName,
        typeId: entityTypeId,
        categoryId: null,
        comments: passComments(fieldValues.comments, getAccountId())
      } as CreateReportingEntityRequestBody;

      createReportingEntity.mutate(
        { body },
        {
          onSuccess: () => {
            navigate("/reporting-entities");
          },
          onError: error => {}
        }
      );
    }

    if (entityTypeId === ReportingEntityTypeEnum.VENDOR) {
      if (fieldValues.firstName && fieldValues.lastName) {
        displayName = `${fieldValues.businessName} | ${fieldValues.lastName}, ${fieldValues.firstName}`;
      } else if (fieldValues.firstName || fieldValues.lastName) {
        displayName = `${fieldValues.businessName} | ${fieldValues.firstName}${fieldValues.lastName}`;
      } else {
        displayName = `${fieldValues.businessName}`;
      }
      const body = {
        ...fieldValues,
        legacyFisherCode: legacyFisherCode,
        legacyBuyerCode: legacyBuyerCode,
        name: displayName,
        typeId: entityTypeId,
        categoryId: Number(fieldValues.categoryId),
        comments: passComments(fieldValues.comments, getAccountId())
      } as CreateReportingEntityRequestBody;

      createReportingEntity.mutate(
        { body },
        {
          onSuccess: () => {
            navigate("/reporting-entities");
          },
          onError: error => {}
        }
      );
    }
  };

  const handleCancel = () => {
    navigate("/reporting-entities");
  };

  const handleSetEntityTypeId = (typeId: number) => {
    setEntityTypeId(typeId);
  };

  return (
    <LogItFormRenderer
      config={config}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      newReportingEntity={true}
      pageType={PAGE_TYPES.NEW_REPORTING_ENTITY}
      setEntityTypeId={handleSetEntityTypeId}
      entityTypeId={entityTypeId}
      entityTypesList={entityTypesList}
    />
  );
}

export default NewReportingEntity;
