type LogItBadgeProps = {
  color: string;
  label: string;
};

function LogItBadge({ color, label }: LogItBadgeProps) {
  return (
    <span
      className={`badge rounded-pill bg-primary flex align-self-center ${color}`}
    >
      {label}
    </span>
  );
}

export default LogItBadge;
