export type LogItButtonStyle =
  | "filled"
  | "outline"
  | "cancel"
  | "outline2"
  | "filledcancel"
  | "warning";

type LogItButtonRoundProps = {
  buttonStyle?: LogItButtonStyle;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  size?: "btn-sm" | "btn";
  name?: string;
  onBlur?: () => void;
  onFocus?: () => void;
};

const buttonClassName = {
  filled: "btn-primary",
  outline: "btn-outline-primary",
  outline2: "btn-outline-secondary",
  cancel: "btn-outline-danger",
  filledcancel: "btn-danger",
  warning: "btn-outline-warning"
};

const LogItButtonRound = ({
  onClick,
  buttonStyle = "filled",
  label,
  type = "button",
  disabled = false,
  size = "btn",
  name = `${label}Button`,
  onBlur,
  onFocus
}: LogItButtonRoundProps) => {
  let className = `btn ${buttonClassName[buttonStyle]} w-100 ${size}`;
  return (
    <button
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
      name={name}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      {label}
    </button>
  );
};

export default LogItButtonRound;
