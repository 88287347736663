import { useEffect, useMemo, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import {
  LogItButtonRound,
  LogItPageHeader,
  ReporterAccountsTable
} from "../../components";
import { DebouncedInput } from "../../components/Tables/DebouncedInput";
import Account from "../../models/Account";
import { isEmptyObj } from "../../utils";
import { useReporterAccountsQuery } from "../queries";
import NewReporterAccount from "./NewReporterAccount";
import ReporterAccountDetails from "./ReporterAccountDetails";

function ReporterAccounts({
  reporterAccountsData,
  setReporterAccountsData
}: any) {
  const navigate = useNavigate();
  const params = useParams();
  const [heading, setHeading] = useState("");
  const [headingDescription, setHeadingDescription] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const { data } = useReporterAccountsQuery("all", {
    type: "Reporter"
  });

  const isBasePath =
    params["*"]?.length === 0 && isEmptyObj(reporterAccountsData);

  // Set heading and descriptions
  useEffect(() => {
    if (isBasePath) {
      setHeading("Reporter Accounts");
      setHeadingDescription(
        "View all Reporter Accounts. Select the table row dropdown to view linked Reporters."
      );
    } else if (params["*"] === "new") {
      setHeading("Add New Reporter Account");
      setHeadingDescription(
        "Register a new Reporter Account with a one-time password or email link"
      );
    } else if (params["*"] === "edit") {
      setHeading("Edit Reporter Account");
      setHeadingDescription(
        "Update Reporter Account user information, linked reporters, settings, and password"
      );
    } else if (!isBasePath) {
      navigate("/reporter-accounts");
    }
  }, [isBasePath, params, reporterAccountsData, navigate]);

  const tableData: Account[] = useMemo(() => {
    return data?.map((account: Account) => {
      let updatedAt = null;

      if (account.updatedAt) {
        updatedAt = `${new Date(
          account.updatedAt
        ).toLocaleDateString()}, ${new Date(
          account.updatedAt
        ).toLocaleTimeString()}`;
      }
      return new Account({
        ...account,
        updatedAt
      });
    });
  }, [data]);

  const goToNewReporterAccountForm = () => {
    navigate("new");
  };

  const renderNewReporterButton = () => {
    return (
      <LogItButtonRound
        label="Add New Reporter Account"
        onClick={goToNewReporterAccountForm}
        buttonStyle="filled"
      />
    );
  };

  const renderCancelButton = () => {
    return (
      <Button variant="outline-primary" onClick={() => navigate(-1)}>
        Cancel
      </Button>
    );
  };

  const renderTable = () => {
    return (
      <>
        <Card className="p-4">
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={value => setGlobalFilter(String(value))}
          />
          <ReporterAccountsTable
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Card>
      </>
    );
  };

  const handleEscape = () => {
    if (params["*"] === "new") {
      navigate("/reporter-accounts");
    }
    if (!isEmptyObj(reporterAccountsData)) {
      setReporterAccountsData({});
    }
  };

  return (
    <div
      onKeyDown={e => {
        if (e.key === "Escape") {
          handleEscape();
        }
      }}
    >
      <LogItPageHeader
        heading={heading}
        description={headingDescription}
        renderButtons={
          isBasePath ? renderNewReporterButton : renderCancelButton
        }
      />

      <Routes>
        <Route path="/" element={renderTable()} />
        <Route path="edit" element={<ReporterAccountDetails />} />
        <Route path="new" element={<NewReporterAccount />} />
      </Routes>
    </div>
  );
}

export default ReporterAccounts;
