// Basic sorting functionality for dates
export const dateSort = (a: string | Date, b: string | Date) => {
  const date1 = new Date(a).getTime();
  const date2 = new Date(b).getTime();
  if (date1 < date2) {
    return 1;
  } else if (date1 > date2) {
    return -1;
  } else {
    return 0;
  }
};
