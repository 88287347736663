import { forwardRef, useEffect, useState } from "react";
import { Lock } from "react-bootstrap-icons";
import BaseSelect, {
  GroupBase,
  OptionProps,
  SingleValueProps,
  components
} from "react-select";
import { LogItBadge } from "../Badges";

export type SelectSearchOptions = {
  id?: number | string;
  value?: string;
  label?: string;
  archivedAt?: string;
  badgeTypeId?: number;
  badgeTypeLabel?: string;
};

export type LogItSelectSearchProps = {
  label?: string;
  options: SelectSearchOptions[];
  name: string;
  isMulti?: boolean;
  required?: boolean;
  defaultValue?: any;
  isReadOnly?: boolean;
  isClearable?: boolean;
  className?: string;
  placeholder?: string;
  ref?: any;
  uniqueId?: string;
  handleSetRequired?: (value: boolean) => void;
  onChangeForFisherInputs?: (name: string, value: string) => void;
  forceRequired?: boolean;
  handleSearchSelection?: (value: string, name: string) => void;
};

const filterOnLabel = (option: any, inputValue: string) => {
  return option.label.toLowerCase().includes(inputValue.toLowerCase());
};

const DEFAULT_BOOK_STATUS_REQUIRED = 30;
const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state?.selectProps?.isReadOnly ? "#e9ecef" : undefined,
    borderColor: state.isFocused ? "#91a0c7" : "#ced4da",
    color: state.isFocused ? "#212529" : "",
    outline: state.isFocused ? 0 : "",
    boxShadow: state.isFocused
      ? "0 0 0 0.25rem rgba(35, 64, 142, 0.25) !important"
      : "none"
  }),
  option: (provided: any, state: any) => {
    const archivedAt = state.data.archivedAt;
    return {
      ...provided,
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: archivedAt
        ? "#f8f9fa"
        : state.isFocused
        ? "#DDEBFF"
        : "transparent",
      color: archivedAt && "#777"
    };
  },
  singleValue: (provided: any) => {
    return {
      ...provided,
      display: "flex",
      justifyContent: "space-between"
    };
  },
  multiValue: (provided: any) => {
    return {
      ...provided,
      backgroundColor: "white",
      border: "1px solid #ced4da"
    };
  }
};

const LogItSelectSearch = forwardRef(
  (
    {
      label = "",
      options,
      name,
      isMulti = false,
      required = false,
      defaultValue,
      isReadOnly,
      isClearable = true,
      className,
      placeholder = "Type to search",
      handleSetRequired,
      onChangeForFisherInputs,
      forceRequired,
      handleSearchSelection,
      uniqueId
    }: LogItSelectSearchProps,
    ref = null
  ) => {
    const calcDefault =
      defaultValue && defaultValue.length && defaultValue[0]
        ? defaultValue[0].value
        : defaultValue && defaultValue.value;
    const [value, setValue] = useState(calcDefault || "");
    const [bookStatus, setBookStatus] = useState(0);
    const onChange = (e: any) => {
      onChangeForFisherInputs && onChangeForFisherInputs(name && name, e && e);
      setValue((e && e.value) || "");
      if (name === "bookStatus") {
        setBookStatus(e && e.value);
      }
      if (name.includes("speciesId") && handleSearchSelection) {
        handleSearchSelection(e?.value, name);
      }
    };

    useEffect(() => {
      handleSetRequired &&
        handleSetRequired(bookStatus >= DEFAULT_BOOK_STATUS_REQUIRED);
    }, [bookStatus, handleSetRequired]);

    useEffect(() => {
      (name === "buyer" || name === "issueDate") &&
        handleSetRequired &&
        handleSetRequired(required);
    }, [handleSetRequired, name, required]);

    const htmlId = `${name}SelectSearch`;
    let showRequired =
      (name !== "buyer" && required) || (name === "buyer" && forceRequired);
    return (
      <div className={`mb-4 ${className} position-relative`}>
        {isReadOnly && (
          <div
            className="position-absolute"
            style={{ right: 0, bottom: -11, zIndex: 1 }}
          >
            <div
              className="d-flex justify-content-center align-items-center text-white rounded-circle"
              style={{
                height: "25px",
                width: "25px",
                backgroundColor: "#ced4da"
              }}
            >
              <Lock />
            </div>
          </div>
        )}
        {label && (
          <label htmlFor={htmlId} className="form-label fw-bold">
            {label} {showRequired && <span>*</span>}
          </label>
        )}
        <BaseSelect
          defaultValue={defaultValue || ""}
          styles={customStyles}
          id={htmlId}
          key={uniqueId}
          isReadOnly={isReadOnly}
          isClearable={isReadOnly ? false : isClearable}
          isSearchable={isReadOnly ? false : true}
          menuIsOpen={isReadOnly ? false : undefined}
          isLoading={false}
          isRtl={false}
          name={name}
          options={options}
          placeholder={placeholder}
          isMulti={isMulti}
          required={showRequired}
          //@ts-ignore
          ref={ref}
          components={{
            Option: CustomOption,
            SingleValue: CustomSingleValue
          }}
          onChange={onChange}
          filterOption={filterOnLabel}
        />
        <div>
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              height: 0,
              position: "absolute"
            }}
            value={value || calcDefault || ""}
            onChange={onChange}
            required={showRequired}
            id="hidden"
          />
        </div>
      </div>
    );
  }
);

function CustomOption(
  props: React.PropsWithChildren<
    OptionProps<SelectSearchOptions, boolean, GroupBase<SelectSearchOptions>>
  >
) {
  const typeId = props.data?.badgeTypeId;
  const typeLabel = props.data?.badgeTypeLabel || "";
  const badgeColor: Record<number, string> = {
    10: "bg-orange",
    20: "bg-teal",
    99: "bg-yellow"
  };
  return (
    <components.Option {...props}>
      {props.children}
      {typeId && <LogItBadge color={badgeColor[typeId]} label={typeLabel} />}
    </components.Option>
  );
}

function CustomSingleValue(
  props: React.PropsWithChildren<
    SingleValueProps<
      SelectSearchOptions,
      boolean,
      GroupBase<SelectSearchOptions>
    >
  >
) {
  const typeId = props.data?.badgeTypeId;
  const typeLabel = props.data?.badgeTypeLabel || "";
  const badgeColor: Record<number, string> = {
    10: "bg-orange",
    20: "bg-teal",
    99: "bg-yellow"
  };
  return (
    <components.SingleValue {...props}>
      {props.children}{" "}
      {typeId && <LogItBadge color={badgeColor[typeId]} label={typeLabel} />}
    </components.SingleValue>
  );
}

export default LogItSelectSearch;
