import { Col, Container, Image, Row } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import backgroundImage from "../../assets/loginBG.jpg";
import logo from "../../logo.svg";

function WelcomeLayout() {
  return (
    <section className="d-flex flex-column vh-100">
      <nav
        id="top-navigation"
        className="d-flex align-items-center bg-primary px-4 py-2"
      >
        <Link to="/">
          <img id="nav-logo" src={logo} alt="LogIt Logo" className="me-1" />
          <span className="text-light fs-5 fw-semibold">Logit</span>
        </Link>
      </nav>
      <Container fluid className="h-100">
        <Row className="flex-grow-1 h-100">
          <Col
            xs={6}
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              backgroundBlendMode: "darken"
            }}
          >
            <div className="d-flex flex-column align-items-center h-75 justify-content-center">
              <div className="mb-5 d-flex align-items-center">
                <Image
                  src={logo}
                  className="me-4"
                  rounded
                  style={{ width: 70 }}
                />
                <h1 className="display-2 fw-semibold text-white">Logit</h1>
              </div>
            </div>
          </Col>
          <Col
            xs={6}
            className="d-flex flex-column  align-items-center bg-light shadow"
          >
            <Container className="d-flex flex-column justify-content-center px-5 my-5 h-75">
              <Outlet />
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default WelcomeLayout;
