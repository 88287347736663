import React, { useEffect, useState } from "react";

export type LogItTextAreaProps = {
  name: string;
  label: string;
  defaultValue?: string;
  rowCount?: number;
  className?: string;
};

function LogItTextArea({
  name,
  label,
  defaultValue = "",
  rowCount = 1,
  className
}: LogItTextAreaProps) {
  const htmlId = `${label}TextArea`;

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const [inputValue, setInputValue] = useState(defaultValue || "");

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "inherit";
      const scrollHeight = textAreaRef.current.scrollHeight;
      if (scrollHeight < 120) {
        textAreaRef.current.style.height = `${scrollHeight}px`;
      } else {
        textAreaRef.current.style.height = "120px";
      }
    }
  }, [inputValue]);

  return (
    <div className={`mb-4 ${className}`}>
      <label htmlFor={htmlId} className="form-label fw-bold">
        {label}
      </label>
      <textarea
        className="form-control form-control"
        id={htmlId}
        rows={rowCount}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        name={name}
        ref={textAreaRef}
      />
    </div>
  );
}

export default LogItTextArea;
