import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import {
  LogItButtonRound,
  LogItTextArea,
  SelectSearchOptions
} from "../../components";
import DeleteItemButton from "../../components/Buttons/DeleteItemButton";
import {
  CommentItem,
  ReportingEntityCommentProps
} from "../../components/Comments";
import { Account, ReportingEntityTypeEnum } from "../../models";
import { AccountReportingEntities } from "../../models/AccountReportingEntities";
import { PAGE_TYPES, getListItemName, getRowId } from "../../utils";
import { LogItButtonStyle } from "../Buttons/LogItButtonRound";
import UnlockButton from "../Buttons/UnlockButton";
import LinkedReportingEntityDetailsPopover from "../Overlays/LinkedReportingEntityDetailsPopover";
import LogItInput from "./LogItInput";
import LogItSelectSearch from "./LogItSelectSearch";

export enum PurchaseReportBookType {
  SINGLE = "Single",
  MULTIPLE = "Multiple"
}

type IType = {
  species_price?: string;
  species_weight?: string;
  species_value?: string;
  species_pieces?: string;
};

type PurchaseReportBookInputProps = {
  numberPerBook?: string;
  startReportNumber?: string;
  endReportNumber?: string;
};

export type LogItFormRendererInputProps = {
  id?: number;
  component: any;
  _uid: any;
  name: string;
  className: string;
  options?: any;
  label?: any;
  type?: string;
  buttonStyle?: LogItButtonStyle;
  fields?: LogItFormRendererInputProps[];
  required?: boolean;
  defaultValue?: any;
  isMulti?: boolean;
  [key: string]: any;
  purchaseReport?: boolean;
};

type LogItFormRendererProps = {
  id?: number;
  config: LogItFormRendererInputProps[];
  purchaseReportItems?: PurchaseReportItemProps[];
  purchaseReport?: boolean;
  addSpeciesRow?: () => void;
  deleteSpeciesRow?: (name: string) => void;
  speciesConditionOptions?: SelectSearchOptions[];
  speciesOptions?: SelectSearchOptions[];
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleCancel?: () => void;
  newReportingEntity?: boolean;
  setEntityTypeId?: (type: any) => void;
  entityTypeId?: number;
  entityTypesList?: { id: number; name: string }[];
  setBookFormType?: (type: string) => void;
  bookFormType?: string;
  pageType?: string;
  handleDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  archivedButtonLabel?: string;
  handleArchiveButtonClick?: () => void;
  archivedButtonClasses?: LogItButtonStyle;
  reportingEntityComments?: ReportingEntityCommentProps[];
  deleteComment?: (id: number) => void;
  handleSetRequired?: (value: boolean) => void;
  fisherInputs?: number;
  fisherTotal?: number;
  onChangeForField?: (
    name: string,
    value: string,
    defaultValue: string,
    uid?: any
  ) => void;
  onChangeForFisherInputs?: (name: string, value: string) => void;
  inputs?: IType;
  purchaseReportBookInputs?: PurchaseReportBookInputProps;
  totals?: any;
  onAdjustmentChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAdjustedTotalChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  totalAdjustment?: string;
  adjustedTotalValue?: string;
  isLocked?: boolean;
  handleIsLockedClick?: (value: boolean) => void;
  handleSearchSelection?: (value: string, name: string) => void;
  accountSearchResults?: any;
  handleClearAccountSearchResults?: () => void;
  linkedAccounts?: AccountReportingEntities[];
  handleAccountSearch?: (searchTerm: string) => Promise<void>;
  handleLinkAccount?: any;
  handleUnlinkAccount?: any;
};

type PurchaseReportItemProps = {
  _uid?: string | number;
  id?: number;
  conditionCode: string | null;
  createdAt?: string;
  species_pieces: number | string;
  species_price: string;
  species?: {
    id?: number;
    name: string;
  };
  species_value: string;
  species_weight: string;
  defaultValue?: any;
};

export enum FormComponents {
  input_group = "input_group",
  select_search = "select_search",
  input_field = "input_field",
  text_area = "text_area"
}

export enum FormElements {
  label = "label"
}

export const LogItFormRenderer = ({
  config,
  addSpeciesRow,
  deleteSpeciesRow,
  purchaseReportItems,
  speciesOptions,
  speciesConditionOptions,
  handleSubmit,
  handleCancel,
  pageType,
  handleDelete,
  setEntityTypeId,
  entityTypeId,
  entityTypesList,
  setBookFormType,
  bookFormType,
  archivedButtonLabel,
  archivedButtonClasses,
  handleArchiveButtonClick,
  reportingEntityComments,
  deleteComment,
  onChangeForField,
  onChangeForFisherInputs,
  inputs,
  totals,
  purchaseReportBookInputs,
  onAdjustmentChange,
  onAdjustedTotalChange,
  totalAdjustment,
  adjustedTotalValue,
  isLocked,
  handleIsLockedClick,
  handleSearchSelection,
  accountSearchResults,
  handleClearAccountSearchResults,
  linkedAccounts,
  handleAccountSearch,
  handleLinkAccount,
  handleUnlinkAccount
}: LogItFormRendererProps) => {
  const isVendor = entityTypeId === ReportingEntityTypeEnum.VENDOR;
  const isFisher = entityTypeId === ReportingEntityTypeEnum.FISHER;
  const isSingle = bookFormType === PurchaseReportBookType.SINGLE;
  const isMultiple = bookFormType === PurchaseReportBookType.MULTIPLE;
  const vendorCheckmark = isVendor && "✓";
  const fisherCheckmark = isFisher && "✓";
  const singleCheckmark = isSingle && "✓";
  const multipleCheckmark = isMultiple && "✓";
  const [forceRequired, setForceRequired] = useState(false);
  const [isCloseButtonFocused, setIsCloseButtonFocused] = useState(false);
  const [isContinueButtonFocused, setIsContinueButtonFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const focusInput = useRef(null);
  const speciesInput = useRef(null);
  const singleOptionRef = useRef<HTMLInputElement>(null);
  const isFisherRef = useRef<HTMLInputElement>(null);
  const bookStatusRef = useRef<HTMLSelectElement>(null);
  const [errors, setErrors] = useState<any>({});
  const [disableSubmit, setDisableSubmit] = useState(false);

  const checkForValidationError = (
    selectedInput: string,
    speciesInputs: any
  ) => {
    let result;
    if (
      !(
        selectedInput.includes("species_price") ||
        selectedInput.includes("species_weight") ||
        selectedInput.includes("species_pieces")
      )
    ) {
      return result;
    }
    const rowId = getRowId(selectedInput);
    const constraint = speciesInputs[`species_constraints${rowId}`];
    if (!constraint) {
      return result;
    }
    const { piecesRequired, minWeight, maxWeight, minPrice, maxPrice } =
      constraint;
    let speciesPieces;

    const fieldType = selectedInput.split("_")[1];
    switch (fieldType) {
      case "pieces":
        speciesPieces = speciesInputs[`species_pieces${rowId}`];
        if (piecesRequired && !speciesPieces) {
          result = { detail: "Pieces are required" };
        }
        if (speciesPieces > 499) {
          result = { detail: "Verify Pieces value" };
        }
        break;
      case "weight":
        speciesPieces = speciesInputs[`species_pieces${rowId}`];
        const speciesWeight = speciesInputs[`species_weight${rowId}`];
        if (!speciesWeight) return undefined;
        // Set pieces to 1 if it's 0 to avoid divide by zero error
        const piecesToDivideBy =
          Number(speciesPieces) === 0 || isNaN(Number(speciesPieces))
            ? 1
            : Number(speciesPieces);
        const totalWeight = Number(speciesWeight) / piecesToDivideBy;
        const maxW = Number(maxWeight);
        const minW = Number(minWeight);
        const validMinWeight = minW > 0 ? totalWeight >= minW : true;
        const validMaxWeight = maxW > 0 ? totalWeight <= maxW : true;
        const validWeight = validMinWeight && validMaxWeight;
        const weightDetail =
          minW < maxW
            ? `Total weight (weight / pieces) must be between ${minW} and ${maxW}`
            : `Total weight (weight / pieces) must more than ${minW}`;
        result =
          validWeight || !speciesPieces
            ? undefined
            : {
                detail: weightDetail
              };
        break;
      case "price":
        let validPrice;
        let validMaxPrice;
        let validMinPrice;
        const speciesPrice = speciesInputs[`species_price${rowId}`];
        if (!speciesPrice) return undefined;
        const maxP = maxPrice ? Number(maxPrice) : 0;
        const minP = minPrice ? Number(minPrice) : 0;
        validMaxPrice = maxP > 0 ? Number(speciesPrice) <= maxP : true;
        validMinPrice = minP > 0 ? Number(speciesPrice) >= minP : true;
        validPrice = validMinPrice && validMaxPrice;
        const priceDetail =
          minP < maxP
            ? `Total price (price / pieces) must be between ${minP} and ${maxP}`
            : `Total price (price / pieces) must more than ${minP}`;
        result = validPrice
          ? undefined
          : {
              detail: priceDetail
            };
        break;
    }
    setErrors({
      ...errors,
      [selectedInput]: result
    });
  };

  // When submitting the form, disable all the buttons so you cannot submit multiple times.
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    setDisableSubmit(true);
    handleSubmit(event);
  };

  // On load or refresh, enable the submit buttons again.
  useEffect(() => {
    setDisableSubmit(false);
  }, []);

  useEffect(() => {
    //@ts-ignore
    focusInput.current?.focus();
  }, [focusInput]);

  useEffect(() => {
    bookStatusRef.current?.focus();
    // eslint-disable-next-line
  }, [bookStatusRef.current]);

  useEffect(() => {
    if (pageType === PAGE_TYPES.NEW_PURCHASE_REPORT_BOOK) {
      singleOptionRef.current?.focus();
    } else if (pageType === PAGE_TYPES.NEW_REPORTING_ENTITY) {
      isFisherRef.current?.focus();
    }
  }, [pageType]);

  const noQuery = window.location.search === "";

  const hasLinkedReportingAccount = (account: Account) => {
    if (linkedAccounts?.some((x: any) => x.accountId === account.id)) {
      return true;
    }
    return false;
  };

  const handleAddSpecies = async () => {
    addSpeciesRow && (await addSpeciesRow());
    //@ts-ignore
    speciesInput.current?.focus();
  };

  const handleDetailAddSpeciesRow = async () => {
    addSpeciesRow && (await addSpeciesRow());
    //@ts-ignore
    speciesInput.current?.focus();
  };

  let lastItem = config
    .slice()
    .reverse()
    .find(el => el.name.includes("species_speciesId"));
  if (lastItem) {
    lastItem.ref = speciesInput;
  }
  const isPurchaseReportItemInput = (name: string) => {
    const itemsInputs = [
      "species_price",
      "species_weight",
      "species_value",
      "species_pieces"
    ];
    for (let i = 0; i < itemsInputs.length; i++) {
      if (name.includes(itemsInputs[i])) return true;
    }
    return false;
  };

  const isPurchaseReportBookInput = (name: string) => {
    const itemsInputs = [
      "endReportNumber",
      "numberPerBook",
      "startReportNumber"
    ];
    for (let i = 0; i < itemsInputs.length; i++) {
      if (name.includes(itemsInputs[i])) return true;
    }
    return false;
  };

  const findSpecies = (id: number) => {
    return speciesOptions?.find((species: any) => {
      return species.id === id;
    });
  };

  const findCondition = (id: number) => {
    return speciesConditionOptions?.find((option: any) => {
      return option.id === id;
    });
  };

  const purchaseReportItemsCount = Object.values(config).filter(val =>
    val.name.includes("species_value")
  ).length;
  return (
    <form
      className="row"
      onKeyDown={e => {
        const element = e.target as HTMLFormElement;
        const elementType = element.tagName;
        if (e.key === "Enter" && elementType !== "BUTTON") {
          const form = element.form;
          const index = Array.prototype.indexOf.call(form, element);
          let skip = 1;
          let skipToElement = form.elements[index + skip];
          while (
            skipToElement.type === "hidden" ||
            skipToElement.id === "hidden" ||
            skipToElement.id === "blankInput"
          ) {
            skip++;
            skipToElement = form.elements[index + skip];
          }
          form.elements[index + skip].focus();
          e.preventDefault();
        }
      }}
      onKeyPress={e => {
        const element = e.target as HTMLElement;
        const elementType = element.tagName;
        if (e.key === "Enter" && elementType !== "BUTTON") {
          e.preventDefault();
        }
      }}
      onSubmit={submitForm}
      onReset={handleCancel}
      id="myForm"
    >
      {pageType === PAGE_TYPES.NEW_PURCHASE_REPORT_BOOK && (
        <div style={{ width: "200px" }}>
          <label className="form-label fw-bold">Select Order Type</label>
          <div className="d-inline btn-group mb-4">
            <input
              type="checkbox"
              className="btn-check"
              name="Single"
              id="single-radio-btn"
              autoComplete="off"
              checked={isSingle}
              onChange={() =>
                setBookFormType &&
                setBookFormType(PurchaseReportBookType.SINGLE)
              }
              ref={singleOptionRef}
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="single-radio-btn"
            >
              {singleCheckmark} Single
            </label>
            <input
              type="checkbox"
              className="btn-check"
              name="Multiple"
              id="btn-check"
              autoComplete="off"
              checked={isMultiple}
              onChange={() =>
                setBookFormType &&
                setBookFormType(PurchaseReportBookType.MULTIPLE)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="btn-check">
              {multipleCheckmark} Multiple
            </label>
          </div>
        </div>
      )}
      {/* Editable field when creating a new reporter */}
      {pageType === PAGE_TYPES.NEW_REPORTING_ENTITY && (
        <>
          <h5 className="mb-3">Select Reporter Type</h5>
          <div className="mb-4">
            <div
              className="position-relative d-inline btn-group"
              style={{ width: "fit-content", height: "fit-content" }}
            >
              <input
                type="checkbox"
                className="btn-check"
                name="Fisher"
                id="fisher-radio-btn"
                autoComplete="off"
                checked={isFisher}
                onChange={() =>
                  setEntityTypeId &&
                  setEntityTypeId(ReportingEntityTypeEnum.FISHER)
                }
                ref={isFisherRef}
              />
              <label
                className="btn btn-outline-primary"
                htmlFor="fisher-radio-btn"
              >
                {fisherCheckmark}{" "}
                {entityTypesList &&
                  getListItemName(
                    ReportingEntityTypeEnum.FISHER,
                    entityTypesList
                  )}
              </label>
              <input
                type="checkbox"
                className="btn-check"
                name="Vendor"
                id="btn-check"
                autoComplete="off"
                checked={isVendor}
                onChange={() =>
                  setEntityTypeId &&
                  setEntityTypeId(ReportingEntityTypeEnum.VENDOR)
                }
              />
              <label className="btn btn-outline-primary" htmlFor="btn-check">
                {vendorCheckmark}{" "}
                {entityTypesList &&
                  getListItemName(
                    ReportingEntityTypeEnum.VENDOR,
                    entityTypesList
                  )}
              </label>
            </div>
          </div>
        </>
      )}
      {/* Disabled/Read-Only when editing or viewing. WPacFIN support must manually change the type */}
      {pageType === PAGE_TYPES.REPORTING_ENTITY_DETAILS && (
        <>
          <h5 className="mb-3">Reporter Type</h5>
          <div className="mb-4">
            <div className="btn bg-primary text-white cursor-default">
              {entityTypeId && entityTypesList
                ? getListItemName(entityTypeId, entityTypesList)
                : // Add a catch to show "undefined" rather than nothing
                  "Undefined Reporter Type"}
            </div>
          </div>
        </>
      )}
      {config.map((input, i) => {
        switch (input.component) {
          case FormComponents.input_group:
            return <InputGroup key={input._uid} group={input} />;
          case FormComponents.select_search:
            let refValue = null;
            if (
              input.name === "bookStatus" &&
              pageType === PAGE_TYPES.PURCHASE_REPORT_BOOK_DETAILS
            ) {
              refValue = bookStatusRef;
            } else if (
              pageType === PAGE_TYPES.NEW_PURCHASE_REPORT &&
              input.name.includes("species_")
            ) {
              refValue = input.ref;
            }

            let inputProps = {
              uniqueId: input._uid,
              label: input.label,
              options: input.options,
              name: input.name,
              className: input.className,
              isMulti: input.isMulti,
              placeholder: input.placeholder,
              defaultValue: input.defaultValue,
              required: input.required,
              isReadOnly: input.isReadOnly,
              key: input._uid,
              ref: refValue,
              isClearable: input.isClearable
            };
            return (
              //@ts-ignore
              <LogItSelectSearch
                handleSetRequired={setForceRequired}
                handleSearchSelection={handleSearchSelection}
                onChangeForFisherInputs={onChangeForFisherInputs}
                forceRequired={
                  pageType === PAGE_TYPES.PURCHASE_REPORT_BOOK_DETAILS ||
                  pageType === PAGE_TYPES.NEW_PURCHASE_REPORT_BOOK
                    ? forceRequired
                    : input.required
                }
                {...inputProps}
              />
            );
          case FormComponents.text_area:
            return (
              <LogItTextArea
                key={input._uid}
                name={input.name}
                label={input.label}
                defaultValue={input.defaultValue}
                className={input.className}
              />
            );
          case FormElements.label:
            return (
              <div key={input._uid} className={input.className}>
                {input.label}
              </div>
            );
          default:
            const renderDelete =
              input.name.includes("species_value") &&
              purchaseReportItemsCount > 1;
            if (
              input.name === "firstName" &&
              noQuery &&
              pageType === PAGE_TYPES.REPORTING_ENTITY_DETAILS &&
              isFisher
            ) {
              input = { ...input, ref: focusInput };
            }

            if (
              input.name === "businessName" &&
              noQuery &&
              pageType === PAGE_TYPES.REPORTING_ENTITY_DETAILS &&
              isVendor
            ) {
              input = { ...input, ref: focusInput };
            }

            if (
              pageType === PAGE_TYPES.NEW_PURCHASE_REPORT_BOOK ||
              pageType === PAGE_TYPES.PURCHASE_REPORT_BOOK_DETAILS
            ) {
              return (
                <LogItInput
                  {...input}
                  handleOnChange={onChangeForField}
                  values={
                    isPurchaseReportBookInput(input.name)
                      ? purchaseReportBookInputs
                      : undefined
                  }
                  key={input._uid}
                  forceRequired={forceRequired}
                />
              );
            }
            return (
              <LogItInput
                {...input}
                handleOnChange={
                  input.name === "fisherCount"
                    ? onChangeForFisherInputs
                    : onChangeForField
                }
                values={
                  isPurchaseReportItemInput(input.name) ? inputs : undefined
                }
                totalFishers={totals && totals.totalFishers}
                key={input._uid}
                deleteSpeciesRow={renderDelete ? deleteSpeciesRow : undefined}
                renderDelete={renderDelete}
                handleOnBlur={
                  input.handleOnBlur ||
                  (() => checkForValidationError(input.name, inputs))
                }
                customClass={renderDelete ? "position-relative" : undefined}
                error={errors[input.name]}
              />
            );
        }
      })}

      {/* New Purchase Report Buttons */}
      {pageType === PAGE_TYPES.NEW_PURCHASE_REPORT && totals && (
        <>
          <div className="col-4 col-xl-5 my-auto">
            <div className="col-lg-6 col-8">
              <LogItButtonRound
                label={"+ Add Species"}
                buttonStyle={"outline2"}
                onClick={() => handleAddSpecies()}
                size={"btn-sm"}
              />
            </div>
          </div>
          <div className="col-2 fw-bold form-label position-relative mt-3 pt-1">
            Adjustment:
            <div
              className="line position-absolute"
              style={{
                top: "-20px",
                borderBottom: "2px solid #CED4DA",
                width: "350%"
              }}
            ></div>
          </div>
          <div className="col-1 mt-3"></div>
          <div className="col-2 col-xl-1 mt-3"></div>
          <div className="col-1 mt-3"></div>
          <div className="col-2 mt-3">
            {onAdjustmentChange && (
              <input
                className={`form-control mb-4`}
                name="adjustmentValue"
                onChange={onAdjustmentChange}
                value={totalAdjustment}
              />
            )}
          </div>
          <div className="col-4 col-xl-5 my-auto"></div>
          <div className="col-2 fw-bold form-label position-relative mt-3 pt-1">
            Items Totals:
            <div
              className="line position-absolute"
              style={{
                top: "-20px",
                borderBottom: "2px solid #CED4DA",
                width: "350%"
              }}
            ></div>
          </div>
          <div className="col-1 mt-3">
            <div
              style={{ height: "38px", backgroundColor: "#e9ecef" }}
              className="form-control mb-4"
            >
              {totals.totalPieces}
            </div>
          </div>
          <div className="col-2 col-xl-1 mt-3">
            <div
              className="form-control mb-4"
              style={{ backgroundColor: "#e9ecef" }}
            >
              {totals.totalWeight}
            </div>
          </div>
          <div className="col-1 mt-3"></div>
          <div className="col-2 mt-3">
            {onAdjustedTotalChange && (
              <input
                className={`form-control mb-4`}
                name="adjustedTotalValue"
                onChange={onAdjustedTotalChange}
                value={adjustedTotalValue}
              />
            )}
          </div>
          {/* TODO Compare UI for having this inside vs outside */}
          <div className="row flex mb-4">
            <div className="col col-lg-2">
              <LogItButtonRound
                label="Add | Continue"
                name="saveAndContinue"
                buttonStyle={isContinueButtonFocused ? "filled" : "outline"}
                onBlur={() => setIsContinueButtonFocused(false)}
                onFocus={() => setIsContinueButtonFocused(true)}
                type="submit"
                disabled={disableSubmit}
              />
            </div>
            <div className="col col-lg-2">
              <LogItButtonRound
                label="Add | Close"
                buttonStyle={isCloseButtonFocused ? "filled" : "outline"}
                onBlur={() => setIsCloseButtonFocused(false)}
                onFocus={() => setIsCloseButtonFocused(true)}
                type="submit"
                disabled={disableSubmit}
              />
            </div>
            <div className="col col-lg-2">
              <LogItButtonRound
                label="Cancel"
                buttonStyle="cancel"
                type="reset"
              />
            </div>
          </div>
        </>
      )}
      {/* Purchase Report Detail Buttons */}
      {pageType === PAGE_TYPES.PURCHASE_REPORT_DETAILS && (
        <div className="row mt-4">
          <div className="col-4 col-xl-5 form-label fw-bold">
            Species Name *
          </div>
          <div className="col-2 form-label fw-bold">Condition</div>
          <div className="col-1 form-label fw-bold">Pieces</div>
          <div className="col-2 col-xl-1 form-label fw-bold">Weight *</div>
          <div className="col-1 form-label fw-bold">Price *</div>
          <div className="col-2 form-label fw-bold">Value *</div>
        </div>
      )}

      {pageType === PAGE_TYPES.PURCHASE_REPORT_DETAILS &&
        speciesOptions &&
        speciesConditionOptions &&
        purchaseReportItems &&
        purchaseReportItems.map((item: any) => {
          const schemeSpecies = findSpecies(item.speciesId);
          const speciesCondition = findCondition(item.conditionCode);
          return (
            <div className="row" key={`speciesRow_${item._uid || item.id}`}>
              <div className="col-4 col-xl-5 position-relative">
                <LogItSelectSearch
                  options={speciesOptions}
                  name={`species_speciesId_${item._uid || item.id}`}
                  defaultValue={schemeSpecies ? [schemeSpecies] : []}
                  ref={speciesInput}
                  required={true}
                  placeholder="Type to search"
                  handleSearchSelection={handleSearchSelection}
                />
              </div>
              <div className="col-2">
                <LogItSelectSearch
                  options={speciesConditionOptions}
                  name={`species_conditionCode_${item._uid || item.id}`}
                  defaultValue={speciesCondition ? [speciesCondition] : []}
                  placeholder="Search"
                />
              </div>
              <div className="col-1">
                <LogItInput
                  name={`species_pieces_${item._uid || item.id}`}
                  defaultValue={item.pieces}
                  values={inputs}
                  handleOnChange={onChangeForField}
                  step="1"
                  min="1"
                  type="number"
                  error={errors[`species_pieces_${item._uid || item.id}`]}
                  handleOnBlur={() =>
                    checkForValidationError(
                      `species_pieces_${item._uid || item.id}`,
                      inputs
                    )
                  }
                />
              </div>
              <div className="col-2 col-xl-1">
                <LogItInput
                  name={`species_weight_${item._uid || item.id}`}
                  defaultValue={item.weight}
                  values={inputs}
                  handleOnChange={onChangeForField}
                  min="0"
                  step="0.01"
                  type="number"
                  required={true}
                  error={errors[`species_weight_${item._uid || item.id}`]}
                  handleOnBlur={() =>
                    checkForValidationError(
                      `species_weight_${item._uid || item.id}`,
                      inputs
                    )
                  }
                />
              </div>
              <div className="col-1">
                <LogItInput
                  name={`species_price_${item._uid || item.id}`}
                  defaultValue={item.price}
                  handleOnChange={onChangeForField}
                  values={inputs}
                  min="0"
                  step="0.01"
                  type="number"
                  required={true}
                  error={errors[`species_price_${item._uid || item.id}`]}
                  handleOnBlur={() =>
                    checkForValidationError(
                      `species_price_${item._uid || item.id}`,
                      inputs
                    )
                  }
                />
              </div>
              <div className="col-2 position-relative">
                <LogItInput
                  name={`species_value_${item._uid || item.id}`}
                  defaultValue={item.value}
                  handleOnChange={onChangeForField}
                  values={inputs}
                  min="0"
                  step="0.01"
                  type="number"
                  required={true}
                />
                {purchaseReportItems && purchaseReportItems.length > 1 && (
                  <DeleteItemButton
                    handleOnClick={deleteSpeciesRow}
                    rowId={item._uid || item.id}
                    style={{
                      top: 0,
                      bottom: 30,
                      right: -12,
                      margin: "auto 0",
                      height: "fit-content"
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
      {pageType === PAGE_TYPES.PURCHASE_REPORT_DETAILS && totals && (
        <>
          <div className="row">
            <div className="col-4 col-xl-5 my-auto">
              <div className="col-lg-6 col-8">
                <LogItButtonRound
                  label={"+ Add Species"}
                  buttonStyle={"outline2"}
                  onClick={handleDetailAddSpeciesRow}
                  size={"btn-sm"}
                />
              </div>
            </div>
            <div className="col-2 fw-bold form-label position-relative mt-3 pt-1">
              Adjustment:
              <div
                className="line position-absolute"
                style={{
                  top: "-20px",
                  borderBottom: "2px solid #CED4DA",
                  width: "350%"
                }}
              ></div>
            </div>
            <div className="col-1 mt-3"></div>
            <div className="col-2 col-xl-1 mt-3"></div>
            <div className="col-1 mt-3"></div>
            <div className="col-2 mt-3">
              {onAdjustmentChange && (
                <input
                  className={`form-control mb-4`}
                  name="adjustmentValue"
                  onChange={onAdjustmentChange}
                  value={totalAdjustment}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-xl-5 my-auto"></div>
            <div className="col-2 fw-bold form-label position-relative mt-3 pt-1">
              Items Totals:
              <div
                className="line position-absolute"
                style={{
                  top: "-20px",
                  borderBottom: "2px solid #CED4DA",
                  width: "350%"
                }}
              ></div>
            </div>

            <div className="col-1 mt-3">
              <div
                style={{ height: "38px", backgroundColor: "#e9ecef" }}
                className="form-control mb-4"
              >
                {totals.totalPieces}
              </div>
            </div>
            <div className="col-2 col-xl-1 mt-3">
              <div
                style={{ backgroundColor: "#e9ecef" }}
                className="form-control mb-4"
              >
                {totals.totalWeight}
              </div>
            </div>
            <div className="col-1 mt-3"></div>
            <div className="col-2 mt-3">
              <input
                className={`form-control mb-4`}
                name="adjustedTotalValue"
                onChange={onAdjustedTotalChange}
                value={adjustedTotalValue}
              />
            </div>
          </div>
          <div className="row flex my-4">
            <div className="col-3 col-lg-2">
              <LogItButtonRound
                label="Save"
                buttonStyle={isContinueButtonFocused ? "filled" : "outline"}
                onBlur={() => setIsContinueButtonFocused(false)}
                onFocus={() => setIsContinueButtonFocused(true)}
                type="submit"
                disabled={disableSubmit}
              />
            </div>
            <div className="col-3 col-lg-2">
              <LogItButtonRound
                label="Cancel"
                buttonStyle="cancel"
                type="reset"
              />
            </div>
            <div className="ms-auto col-md-auto">
              <LogItButtonRound
                label="Delete Report"
                buttonStyle="cancel"
                type="reset"
                onClick={handleDelete}
              />
            </div>
            {typeof handleIsLockedClick === "function" &&
              typeof isLocked !== "undefined" && (
                <div className="col-md-auto">
                  <UnlockButton
                    className={isLocked ? "btn-outline-warning" : "btn-warning"}
                    handleOnClick={handleIsLockedClick}
                    isLocked={isLocked}
                  />
                </div>
              )}
          </div>
        </>
      )}
      {pageType === PAGE_TYPES.REPORTING_ENTITY_DETAILS && (
        <>
          {/* Reporter Accounts Section */}
          <div>
            <Card>
              <Card.Body>
                <Card.Title>Linked Accounts</Card.Title>
                <Card.Subtitle className="text-body-tertiary mb-3">
                  Accounts linked to this reporter. You can view or unlink the
                  accounts from this reporter.
                </Card.Subtitle>
                {linkedAccounts?.length ? (
                  <Table responsive className="border">
                    <thead>
                      <tr>
                        <th className="bg-body-secondary">Name</th>
                        <th className="bg-body-secondary">Username</th>
                        <th className="bg-body-secondary">Email</th>
                        <th className="bg-body-secondary">Linked Reporters</th>
                        <th className="bg-body-secondary">Status</th>
                        <th className="bg-body-secondary">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {linkedAccounts.map(account => (
                        <tr key={account.accountId}>
                          <td>{account?.account?.name}</td>
                          <td>{account?.account?.loginId}</td>
                          <td>{account?.account?.email}</td>
                          <td>
                            {account?.account?.accountReportingEntities &&
                            account.account.accountReportingEntities.length ? (
                              <LinkedReportingEntityDetailsPopover
                                reportingEntities={
                                  account.account.accountReportingEntities
                                }
                                value={
                                  account.account.accountReportingEntities
                                    .length
                                }
                              />
                            ) : (
                              0
                            )}
                          </td>
                          <td>
                            <span
                              className={`status-dot status-dot-${String(
                                account?.account?.status.name
                              ).toLowerCase()}`}
                            >
                              {account?.account?.status.name}
                            </span>
                          </td>
                          <td>
                            <Button
                              variant="outline-danger"
                              size="sm"
                              onClick={() =>
                                handleUnlinkAccount(account.account?.id)
                              }
                            >
                              Unlink Account
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>
                    <InfoCircle className="text-primary me-2" />
                    There are no linked accounts. Link accounts from the search
                    below.
                  </p>
                )}

                {/* Account Search Form Start */}
                <div className="mt-5">
                  <Card.Title>Link Existing Reporter</Card.Title>
                  <Card.Subtitle className="text-body-tertiary mb-3">
                    You can search for a Account by name, username, or email.
                    You link MANY accounts to this reporter.
                  </Card.Subtitle>

                  {handleAccountSearch && handleClearAccountSearchResults && (
                    <Row className="align-items-end mb-4">
                      <Form.Group as={Col} sm={6} className="mb-3">
                        <Form.Label>
                          Search by name, phone, or license
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={searchTerm}
                          name="searchTerm"
                          onChange={e => setSearchTerm(e.currentTarget.value)}
                          onKeyDown={e => {
                            if (e.key === "Enter")
                              handleAccountSearch(searchTerm);
                          }}
                        ></Form.Control>
                      </Form.Group>
                      <Col sm={6}>
                        <Button
                          variant="outline-primary"
                          className="mb-3 me-2"
                          onClick={() => handleAccountSearch(searchTerm)}
                        >
                          Search for Reporter
                        </Button>
                        <Button
                          variant="outline-dark"
                          className="mb-3"
                          type="button"
                          onClick={handleClearAccountSearchResults}
                        >
                          Clear Results
                        </Button>
                      </Col>
                    </Row>
                  )}
                  {accountSearchResults.length > 0 && (
                    <>
                      <div className="d-flex align-items-end mb-3">
                        <span className="lead me-3">
                          <strong>Search Results</strong>
                        </span>
                      </div>
                      <Table responsive className="border">
                        <thead>
                          <tr>
                            <th className="bg-body-secondary">Name</th>
                            <th className="bg-body-secondary">Username</th>
                            <th className="bg-body-secondary">Email</th>
                            <th className="bg-body-secondary">
                              Linked Reporters
                            </th>
                            <th className="bg-body-secondary">Status</th>
                            <th className="bg-body-secondary">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {accountSearchResults.map((account: Account) => (
                            <tr key={account.id}>
                              <td>{account?.name}</td>
                              <td>{account?.loginId}</td>
                              <td>{account?.email}</td>
                              <td>
                                {account?.accountReportingEntities &&
                                account.accountReportingEntities.length ? (
                                  <LinkedReportingEntityDetailsPopover
                                    reportingEntities={
                                      account.accountReportingEntities
                                    }
                                    value={
                                      account.accountReportingEntities.length
                                    }
                                  />
                                ) : (
                                  0
                                )}
                              </td>
                              <td>
                                <span
                                  className={`status-dot status-dot-${String(
                                    account.status.name
                                  ).toLowerCase()}`}
                                >
                                  {account.status.name}
                                </span>
                              </td>
                              <td>
                                {hasLinkedReportingAccount(account) ? (
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={() =>
                                      handleUnlinkAccount(account.id)
                                    }
                                  >
                                    Unlink Account
                                  </Button>
                                ) : (
                                  <Button
                                    variant="outline-success"
                                    size="sm"
                                    onClick={() =>
                                      handleLinkAccount(account.id)
                                    }
                                  >
                                    Link Account
                                  </Button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  )}
                </div>
                {/* Account Search Form End */}
              </Card.Body>
            </Card>
          </div>
          <div>
            <div>
              <label className="fw-bold mb-2">Existing Remarks</label>
              {deleteComment && reportingEntityComments ? (
                reportingEntityComments.map(
                  (comment: ReportingEntityCommentProps) => {
                    return (
                      <CommentItem
                        key={comment.id}
                        comment={comment}
                        deleteCommentHandler={deleteComment}
                      />
                    );
                  }
                )
              ) : (
                <div>No recorded remarks.</div>
              )}
            </div>
          </div>
          <div className="d-flex row my-4">
            <div className="col-3 col-lg-2 ">
              <LogItButtonRound
                label="Save"
                buttonStyle={isContinueButtonFocused ? "filled" : "outline"}
                onBlur={() => setIsContinueButtonFocused(false)}
                onFocus={() => setIsContinueButtonFocused(true)}
                type="submit"
                disabled={disableSubmit}
              />
            </div>
            <div className="col-3 col-lg-2">
              <LogItButtonRound
                label="Cancel"
                buttonStyle="cancel"
                type="reset"
              />
            </div>
            <div className="col-2 col-lg-1"></div>
            <div className="col-3 col-lg-2">
              <LogItButtonRound
                label={archivedButtonLabel || ""}
                buttonStyle={archivedButtonClasses}
                onClick={handleArchiveButtonClick}
              />
            </div>
            <div className="col-md-auto">
              <LogItButtonRound
                label="Delete Reporter"
                buttonStyle="cancel"
                type="reset"
                onClick={handleDelete}
              />
            </div>
            {typeof handleIsLockedClick === "function" &&
              typeof isLocked !== "undefined" && (
                <div className="col-md-auto">
                  <UnlockButton
                    className={isLocked ? "btn-outline-warning" : "btn-warning"}
                    handleOnClick={handleIsLockedClick}
                    isLocked={isLocked}
                  />
                </div>
              )}
          </div>
        </>
      )}
      {pageType === PAGE_TYPES.NEW_REPORTING_ENTITY && (
        <div className="row flex align-items-end">
          <div className="col-2 mt-5">
            <LogItButtonRound
              label="Add"
              buttonStyle={isContinueButtonFocused ? "filled" : "outline"}
              onBlur={() => setIsContinueButtonFocused(false)}
              onFocus={() => setIsContinueButtonFocused(true)}
              type="submit"
              disabled={disableSubmit}
            />
          </div>
          <div className="col-2">
            <LogItButtonRound
              label="Cancel"
              buttonStyle="cancel"
              type="reset"
            />
          </div>
        </div>
      )}
      {(pageType === PAGE_TYPES.NEW_PURCHASE_REPORT_BOOK ||
        pageType === PAGE_TYPES.PURCHASE_REPORT_BOOK_DETAILS) && (
        <div className="row flex mb-4">
          <div className="col col-lg-2">
            <LogItButtonRound
              label={
                pageType === PAGE_TYPES.NEW_PURCHASE_REPORT_BOOK
                  ? "Add"
                  : "Save"
              }
              buttonStyle={isContinueButtonFocused ? "filled" : "outline"}
              onBlur={() => setIsContinueButtonFocused(false)}
              onFocus={() => setIsContinueButtonFocused(true)}
              type="submit"
              disabled={disableSubmit}
            />
          </div>
          <div className="col col-lg-2">
            <LogItButtonRound
              label="Cancel"
              buttonStyle="cancel"
              type="reset"
            />
          </div>
          {pageType === PAGE_TYPES.PURCHASE_REPORT_BOOK_DETAILS && (
            <>
              <div className="col-3 col-lg-2 ms-auto col-md-auto">
                <LogItButtonRound
                  label="Delete Book"
                  buttonStyle="cancel"
                  type="reset"
                  onClick={handleDelete}
                />
              </div>
              {typeof handleIsLockedClick === "function" &&
                typeof isLocked !== "undefined" && (
                  <div className="col-md-auto">
                    <UnlockButton
                      className={
                        isLocked ? "btn-outline-warning" : "btn-warning"
                      }
                      handleOnClick={handleIsLockedClick}
                      isLocked={isLocked}
                    />
                  </div>
                )}
            </>
          )}
        </div>
      )}
    </form>
  );
};

type FieldGroupProps = {
  group: LogItFormRendererInputProps;
};

const InputGroup = ({ group }: FieldGroupProps) => {
  const { fields } = group;

  return (
    <fieldset key={group._uid} name={group.name} className={group.className}>
      {fields?.map(input => {
        if (input.component === "select_search") {
          return (
            <LogItSelectSearch
              key={input._uid}
              label={input.label}
              options={input.options}
              name={input.name}
              className={input.className}
              isMulti={input.isMulti}
              placeholder={input.placeholder}
            />
          );
        } else {
          return <LogItInput {...input} key={input._uid} />;
        }
      })}
    </fieldset>
  );
};

export default InputGroup;
