export type LogItCheckboxProps = {
  checked?: boolean;
  label: string;
  name: string;
};

function LogItCheckbox({ checked = false, label, name }: LogItCheckboxProps) {
  return (
    <div className="form-check form-check-inline mb-3">
      <input
        name={name}
        className="form-check-input"
        type="checkbox"
        id="flexCheckDefault"
        defaultChecked={checked}
      />
      <label className="form-check-label fw-bold" htmlFor="flexCheckDefault">
        {label}
      </label>
    </div>
  );
}

export default LogItCheckbox;
