type LogItInputProps = {
  heading: string;
  description?: string;
  renderButtons?: null | (() => JSX.Element);
};

function LogItPageHeader({
  heading,
  description = "",
  renderButtons
}: LogItInputProps) {
  return (
    <div className="row border-bottom pb-3 align-items-center mb-3">
      <div className="col">
        <h2 className="fw-bold">{heading}</h2>
        {description.length > 0 && (
          <p className="fw-semibold text-body-tertiary">{description}</p>
        )}
      </div>
      <div className="col-md-4 col-lg-3 d-flex justify-content-end">
        {renderButtons ? renderButtons() : null}
      </div>
    </div>
  );
}

export default LogItPageHeader;
