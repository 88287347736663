import { forwardRef, useEffect, useRef, useState } from "react";
import {
  ArrowDown,
  ArrowDownUp,
  ArrowUp,
  ChevronBarLeft,
  ChevronBarRight,
  ChevronLeft,
  ChevronRight,
  Search
} from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from "react-table";
import { searchPurchaseReport } from "../../pages/queries";
import { SelectSearchOptions } from "../Forms";
import "./styles.scss";
const LIMIT = 100;
type LogItColumns = {
  sortType?: (a: any, b: any) => number;
  Header: string;
  accessor: string;
};

type LogItTableProps = {
  columns: LogItColumns[];
  data: Array<Record<any, any>>;
  sortBy?: { id: string; desc: boolean };
  setSearchResults?: (data: []) => void;
  setLoadMoreResults?: (data: []) => void;
  type?: string;
  reportStatusOptions?: SelectSearchOptions[];
  setDetailData?: any;
};

const LogItTable = forwardRef<HTMLInputElement, LogItTableProps>(
  (
    {
      columns,
      data,
      setSearchResults,
      setLoadMoreResults,
      type,
      reportStatusOptions,
      setDetailData
    },
    ref
  ) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      setGlobalFilter,
      state,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize
    } = useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: 0,
          pageSize: 12
        },
        autoResetPage: false,
        autoResetResize: false,
        autoResetSortBy: false
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    const [searchValue, setSearchValue] = useState(state.globalFilter);
    const [disableLoadMore, setDisableLoadMore] = useState(false);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [selectedRow, setSelectedRow] = useState(0);
    let location = useLocation();
    let focusRow = useRef<HTMLTableRowElement>(null);
    const reportStatusArr = reportStatusOptions?.map(reportStatus =>
      reportStatus.label?.toLowerCase()
    );
    useEffect(() => {
      if (state.pageIndex > pageOptions.length) {
        gotoPage(0);
      }
    }, [state.pageIndex, pageOptions.length, gotoPage]);

    useEffect(() => {
      focusRow.current?.focus();
    }, [selectedRow]);

    const onSearchChange = useAsyncDebounce(value => {
      if (state.pageIndex !== 0) gotoPage(0);
      setGlobalFilter(value || undefined);
    }, 200);

    const navigateTo = (data: any) => {
      setDetailData && setDetailData(data);
    };

    const searchTable = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
      if (location.pathname === "/purchase-reports") {
        if (e.target.value.length === 0) {
          handleSearch();
        }
        return;
      }
      onSearchChange(e.target.value);
    };

    const handleKeypress = (e: any, searchValue: string) => {
      if (e.code === "Enter" && location.pathname === "/purchase-reports") {
        handleSearch(searchValue);
      }
    };

    const isDateString = (val: string) => {
      return val.match(/\s*\d{1,4}\s*[-,/]\s*\d{1,2}\s*[-,/]\s*\d{1,4}\s*/);
    };

    const handleLoadMore = async (val: string = "") => {
      let data;
      let type: string[] = [];
      if (val === "") {
        type.push("empty");
      } else if (isDateString(val)) {
        type.push("purchaseDate", "createdAt", "updatedAt");
      } else if (Number(val)) {
        type.push("reportNumber");
      } else if (reportStatusArr?.includes(val.toLowerCase())) {
        type.push("reportStatus");
      } else {
        type.push("reportingEntityName");
      }

      let offset = numberOfPages * LIMIT;
      let limit = LIMIT;
      data = await searchPurchaseReport(
        isDateString(val) ? new Date(val) : val,
        type,
        offset,
        limit
      );
      if (data.length < LIMIT) setDisableLoadMore(true);
      setLoadMoreResults && setLoadMoreResults(data);
      setNumberOfPages(pages => pages + 1);
    };

    const handleSearch = async (val: string = "") => {
      let data;
      if (val === "") {
        data = await searchPurchaseReport(val, ["empty"]);
      } else if (
        val.match(/\s*\d{1,4}\s*[-,/]\s*\d{1,2}\s*[-,/]\s*\d{1,4}\s*/)
      ) {
        let dateParam = new Date(val);
        data = await searchPurchaseReport(dateParam, [
          "purchaseDate",
          "createdAt",
          "updatedAt"
        ]);
      } else if (Number(val)) {
        data = await searchPurchaseReport(val, ["reportNumber"]);
      } else if (reportStatusArr?.includes(val.toLowerCase())) {
        data = await searchPurchaseReport(val, ["reportStatus"]);
      } else {
        data = await searchPurchaseReport(val, ["reportingEntityName"]);
      }
      setDisableLoadMore(data.length < LIMIT);
      gotoPage(0);
      setNumberOfPages(1);
      setSearchResults && setSearchResults(data);
    };

    const upArrowPressed = () => {
      setSelectedRow(row => (row > 0 ? row - 1 : row));
    };

    const downArrowPressed = () => {
      setSelectedRow(row => (row + 1 < state.pageSize ? row + 1 : row));
    };

    return (
      <>
        {/** SEARCH */}
        <div className="row align-self-start mb-4 mt-3">
          <div className="input-group">
            <div className="col-5">
              <input
                ref={ref}
                id="searchInput"
                type="search"
                value={searchValue || ""}
                onChange={searchTable}
                placeholder={
                  type === "purchaseReport"
                    ? "Search by purchase number, dates, name, and status."
                    : "Type to search"
                }
                className="form-control"
                onKeyPress={e => handleKeypress(e, searchValue)}
              />
            </div>
            <div className="col-1">
              <button
                tabIndex={-1}
                onClick={() =>
                  location.pathname === "/purchase-reports" &&
                  handleSearch(searchValue)
                }
                title={
                  location.pathname === "/purchase-reports"
                    ? `purchase number: exact match number
  date: exact match MM/DD/YYYY
  name: partial match on seller and buyer
  status: exact match
    • submitted
    • incomplete
    • review
    • rejected
    • complete`
                    : undefined
                }
                className="input-group-text"
                style={{
                  marginLeft: `${
                    location.pathname === "/purchase-reports" ? "15px" : "15px"
                  }`
                }}
                id="basic-addon1"
              >
                {location.pathname === "/purchase-reports" ? (
                  "Search"
                ) : (
                  <Search size={22} />
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          {/** TABLE */}
          <table
            className="logit-table table-hover table-bordered"
            {...getTableProps()}
          >
            <thead className="table-light">
              {
                // Loop over the header rows
                headerGroups.map(headerGroup => (
                  // Apply the header row props
                  <tr
                    className="text-nowrap"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map(column => {
                        return (
                          // Apply the header cell props
                          <th
                            scope="col"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            <div className="d-flex justify-content-between">
                              <div>{column.render("Header")}</div>
                              <div>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <ArrowDown />
                                  ) : (
                                    <ArrowUp />
                                  )
                                ) : (
                                  <ArrowDownUp />
                                )}
                              </div>
                            </div>
                          </th>
                        );
                      })
                    }
                  </tr>
                ))
              }
            </thead>
            <tbody {...getTableBodyProps()}>
              {
                // Loop over the table rows
                page.map((row, i) => {
                  // Prepare the row for display
                  prepareRow(row);
                  let index = state.pageIndex * state.pageSize + i;
                  return (
                    // Apply the row props
                    <tr
                      {...row.getRowProps()}
                      className={
                        location.pathname === "/reporting-entities" &&
                        data[index].archivedAt
                          ? "bg-light text-muted"
                          : ""
                      }
                      ref={
                        Number(row.id) === selectedRow ? focusRow : undefined
                      }
                      tabIndex={0}
                      onKeyPress={e => {
                        if (e.key === "Enter") {
                          navigateTo(row.original);
                        }
                      }}
                      onKeyDown={e => {
                        if (
                          e.key === "ArrowUp" ||
                          (e.shiftKey && e.key === "Tab")
                        ) {
                          upArrowPressed();
                        } else if (e.key === "ArrowDown" || e.key === "Tab") {
                          downArrowPressed();
                        }
                      }}
                      onClick={() => {
                        navigateTo(row.original);
                      }}
                    >
                      {
                        // Loop over the rows cells
                        row.cells.map(cell => {
                          // Apply the cell props
                          return (
                            <td
                              className="text-nowrap text-truncate"
                              {...cell.getCellProps()}
                            >
                              {
                                // Render the cell contents
                                cell.render("Cell")
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        {/** PAGINATION */}
        <div className="my-3 row align-items-center table-pagination">
          <div className="btn-group col-3">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <ChevronBarLeft size={21} />
            </button>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <ChevronLeft size={21} />
            </button>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <ChevronRight size={21} />
            </button>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <ChevronBarRight size={21} />
            </button>

            {location.pathname === "/purchase-reports" && (
              <button
                disabled={disableLoadMore}
                style={{ marginLeft: "10px" }}
                className="btn btn-sm btn-primary"
                onClick={() => {
                  handleLoadMore(searchValue);
                }}
              >
                Load More
              </button>
            )}
          </div>
          <div className="col-sm-8 col-md-6 col-xl-5 col-xxl-4">
            <div className="input-group align-items-center">
              <span className="fw-bold me-2">
                Page {state.pageIndex + 1} of {pageOptions.length}
              </span>
              <span className="me-2">| Go to page: </span>
              <input
                className="form-control form-control-sm"
                type="number"
                defaultValue={state.pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />
              <select
                className="form-select form-select-sm"
                value={state.pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[12, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default LogItTable;
