import { useEffect, useState } from "react";
import {
  LastUpdatedLabel,
  LogItFormRenderer,
  SelectSearchOptions
} from "../../components";
import {
  LogItFormRendererInputProps,
  PurchaseReportBookType
} from "../../components/Forms/LogItFormRenderer";
import { ReportingEntity, ReportingEntityTypeEnum } from "../../models";
import { PAGE_TYPES } from "../../utils";
import {
  getPurchaseReportsInRange,
  useCreatePurchaseReportBook,
  useDeletePurchaseReportBook
} from "../queries";

type InputOptions = {
  prbsOptions: SelectSearchOptions[];
  reportingEntityOptions: ReportingEntity[];
  purchaseReportBook?: any;
  setDetailData?: any;
};

type PurchaseReportBookInputProps = {
  numberPerBook?: string;
  startReportNumber?: string;
  endReportNumber?: string;
  bookCount?: string;
};

const TARGET_REGION = process.env.REACT_APP_TARGET_REGION;
let DEFAULT_BOOK_STATUS_REQUIRED: number;
let singleFormConfig: LogItFormRendererInputProps[] = [];
import(`../../config/${TARGET_REGION}/purchaseReportBookFormConfig`).then(
  module => {
    singleFormConfig = module.singlePurchaseReportBookFormConfig;
  }
);
import(`../../config/${TARGET_REGION}/defaults`).then(module => {
  DEFAULT_BOOK_STATUS_REQUIRED = module.DEFAULT_BOOK_STATUS_REQUIRED;
});

function PurchaseReportBookDetails({
  prbsOptions,
  reportingEntityOptions,
  purchaseReportBook,
  setDetailData
}: InputOptions) {
  const [inputs, setInputs] = useState<PurchaseReportBookInputProps>({});
  const [config, setConfig] = useState([]);
  const createPurchaseReportBook = useCreatePurchaseReportBook("Updated");
  const [isLocked, setIsLocked] = useState<boolean>(true);
  const [validInputs, setValidInputs] = useState({
    startReportNumber: true,
    bookCount: true
  });
  const id = Number(purchaseReportBook.id);
  const deletePurchaseReport = useDeletePurchaseReportBook(id);

  if (!purchaseReportBook) {
    window.location.replace(`${window.location.origin}/purchase-report-books`);
  }
  const formatDate = (date: Date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();

    let month = (1 + newDate.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    let day = newDate.getDate().toLocaleString();
    day = day.length > 1 ? day : "0" + day;

    return `${year}-${month}-${day}`;
  };
  const handleInvalidInputCheck = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (
      name === "startReportNumber" &&
      purchaseReportBook.purchaseReportsPerBook
    ) {
      setValidInputs({
        ...validInputs,
        [name]:
          (Number(value) - 1) % purchaseReportBook.purchaseReportsPerBook === 0
      });
    }
  };

  const makeFormConfig = (config: any) => {
    return config.map((input: any) => {
      // This is needed to clear any previous values
      switch (input.name) {
        case "numberPerBook":
          input.isReadOnly = isLocked;
          input.defaultValue = input.options?.find((option: { id: number }) => {
            return option.id === purchaseReportBook.purchaseReportsPerBook;
          });
          break;
        case "buyer":
          input.options = reportingEntityOptions;
          input.required =
            purchaseReportBook.purchaseReportBookStatus.id >=
            DEFAULT_BOOK_STATUS_REQUIRED;
          if (purchaseReportBook.buyer) {
            input.defaultValue = [
              {
                id: purchaseReportBook.buyer.id,
                value: String(purchaseReportBook.buyer.id),
                label: purchaseReportBook.buyer.name,
                badgeTypeId: purchaseReportBook.buyer.typeId,
                badgeTypeLabel: purchaseReportBook.buyer.type?.name
              }
            ];
          } else {
            input.defaultValue = "";
          }
          input.options = reportingEntityOptions
            ?.filter(entity => entity.typeId !== ReportingEntityTypeEnum.FISHER)
            .map(entity => ({
              value: String(entity.id),
              label: entity.name,
              badgeTypeId: entity.type?.id,
              badgeTypeLabel: entity.type?.name,
              archivedAt: entity.archivedAt
            }));
          break;
        case "bookStatus":
          input.options = prbsOptions;
          input.defaultValue = [
            {
              id: purchaseReportBook.purchaseReportBookStatus.id,
              value: String(purchaseReportBook.purchaseReportBookStatus.id),
              label: purchaseReportBook.purchaseReportBookStatus.name
            }
          ];
          break;
        case "startReportNumber":
          input.defaultValue = purchaseReportBook.startReportNumber;
          input.readonly = isLocked;
          input.handleOnBlur = handleInvalidInputCheck;
          input.isvalidinput = validInputs.startReportNumber;
          if (!inputs.startReportNumber) {
            setInputs({
              ...inputs,
              startReportNumber: purchaseReportBook.startReportNumber
            });
          }
          break;
        case "endReportNumber":
          input.defaultValue = purchaseReportBook.endReportNumber;
          break;
        case "comments":
          input.defaultValue = purchaseReportBook.comments;
          break;
        case "issueDate":
          input.defaultValue = purchaseReportBook.issuedDate
            ? formatDate(purchaseReportBook.issuedDate)
            : "";
          input.required =
            purchaseReportBook.purchaseReportBookStatus.id >=
            DEFAULT_BOOK_STATUS_REQUIRED;
          input.max = new Date().toISOString().split("T")[0];

          break;
        default:
          break;
      }

      return input;
    });
  };
  useEffect(() => {
    setConfig(makeFormConfig(singleFormConfig));
    // eslint-disable-next-line
  }, [isLocked, validInputs]);
  const [bookFormType, setBookFormType] = useState<PurchaseReportBookType>(
    PurchaseReportBookType.SINGLE
  );

  const handleDeletePurchaseReport = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    getPurchaseReportsInRange(
      purchaseReportBook.startReportNumber,
      purchaseReportBook.endReportNumber
    ).then(numOfReports => {
      if (
        window.confirm(
          `Are you sure you want to delete this record? ${
            numOfReports
              ? `There are ${numOfReports} purchase records associated with this book.`
              : ""
          }`
        )
      ) {
        deletePurchaseReport.mutate(
          //@ts-ignore
          {},
          {
            onSuccess: () => {
              setDetailData && setDetailData({});
            }
          }
        );
      }
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const { ...fieldValues } = Object.fromEntries(formData.entries());
    const startReportNumber = Number(fieldValues.startReportNumber);
    const numberPerBook = Number(fieldValues.numberPerBook);
    const body = {
      startReportNumber: fieldValues.startReportNumber,
      endReportNumber: startReportNumber + numberPerBook - 1,
      purchaseReportsPerBook: fieldValues.numberPerBook,
      issuedDate:
        fieldValues.issueDate !== ""
          ? new Date(fieldValues.issueDate as string)
          : null,
      comments: fieldValues.comments,
      buyerId: String(fieldValues.buyer).length ? fieldValues.buyer : null,
      updatedBy: fieldValues.updatedBy,
      purchaseReportBookStatusId: fieldValues.bookStatus,
      id: id
    };
    createPurchaseReportBook.mutate(
      { body },
      {
        onSuccess: () => {
          setDetailData && setDetailData({});
        },
        onError: error => {}
      }
    );
  };

  const handleCancel = () => {
    setDetailData && setDetailData({});
  };

  const handleSetEntityType = (type: any) => {
    setBookFormType(type);
  };

  const onChangeForField = (name: string, value: string) => {
    let numberPerBookVal =
      name === "numberPerBook" ? value : inputs["numberPerBook"];
    let startReportNumberVal =
      name === "startReportNumber" ? value : inputs["startReportNumber"];
    let endReportVal = inputs["endReportNumber"];
    let bookCountVal = name === "bookCount" ? value : inputs["bookCount"];
    if (numberPerBookVal && startReportNumberVal) {
      endReportVal = String(
        Number(numberPerBookVal) * Number(bookCountVal || 1) +
          Number(startReportNumberVal) -
          1
      );
    } else {
      endReportVal = purchaseReportBook.endReportNumber;
    }
    setInputs({
      numberPerBook: numberPerBookVal,
      startReportNumber: startReportNumberVal,
      endReportNumber: endReportVal,
      bookCount: bookCountVal
    });
  };
  return (
    <div>
      <LogItFormRenderer
        config={config}
        pageType={PAGE_TYPES.PURCHASE_REPORT_BOOK_DETAILS}
        setBookFormType={handleSetEntityType}
        bookFormType={bookFormType}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        onChangeForField={onChangeForField}
        purchaseReportBookInputs={inputs}
        handleDelete={handleDeletePurchaseReport}
        isLocked={isLocked}
        handleIsLockedClick={setIsLocked}
      />
      {purchaseReportBook && (
        <>
          <LastUpdatedLabel
            account={purchaseReportBook.editor}
            timeStamp={purchaseReportBook.updatedAt}
            label="Updated:"
          />
          <LastUpdatedLabel
            account={purchaseReportBook.creator}
            timeStamp={purchaseReportBook.createdAt}
            label="Created:"
          />
        </>
      )}
    </div>
  );
}
export default PurchaseReportBookDetails;
