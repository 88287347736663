import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { queryClient } from "../../App";
import { ApiService } from "../../services";
import { QueryParamOptions } from "../../services/ApiService";
import { makeKeyFactory } from "./makeKeyFactory";

const { get, post, put, patch, deleteCall } = new ApiService();
const ACCOUNTS_ENDPOINT = "accounts";
const AUTHORIZATION_ENDPOINT = "authorization";

export type UpdateAccountRequestBody = {
  id: number;
  loginId: string | null;
  name?: string;
  email?: string;
};

export type CreateAccountRequestBody = {
  name: string;
  loginId?: string;
  sendEmail?: boolean;
  email: string;
  status?: string;
  phoneNumber?: number;
  type: string;
};

export const reporterAccountsKeys = makeKeyFactory("reporterAccounts");

export const useReporterAccountsQuery = (
  queryFilter: string = "all",
  queryOptions?: QueryParamOptions
) => {
  const allReporterAccounts = useQuery(
    reporterAccountsKeys.list(queryFilter),
    async () => await get(`${ACCOUNTS_ENDPOINT}/get`, queryOptions),
    {
      onSuccess: async data => {
        return data;
      },
      onError: async error => {
        return await error;
      }
    }
  );

  return allReporterAccounts;
};

export const useReporterAccountQuery = (reporterId: number) => {
  const reporterAccount = useQuery(
    `reporterAccount_${reporterId}`,
    async () => await get(`${ACCOUNTS_ENDPOINT}/get/${reporterId}`),
    {
      refetchOnWindowFocus: "always",
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
      onSuccess: data => data,
      onError: error => error
    }
  );

  return reporterAccount;
};

export const useCreateReporterAccount = () => {
  const newReporterAccount = useMutation(
    async (body: CreateAccountRequestBody) =>
      await post(`${ACCOUNTS_ENDPOINT}`, body),
    {
      onSuccess: async () => {
        toast(`Reporter Account Created`, {
          position: "top-right",
          type: "success",
          theme: "colored"
        });
        return queryClient.invalidateQueries(reporterAccountsKeys.lists());
      },
      onError: async () => {
        toast(`Error Creating Reporter Account`, {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return newReporterAccount;
};

export const useRegisterReporterAccountByEmail = () => {
  const registerReporterAccount = useMutation(
    async (body: CreateAccountRequestBody) =>
      await post(`${ACCOUNTS_ENDPOINT}/email-registration`, body),
    {
      onSuccess: async () => {
        toast(`Reporter Account Registered`, {
          position: "top-right",
          type: "success",
          theme: "colored"
        });
        return queryClient.invalidateQueries(reporterAccountsKeys.lists());
      },
      onError: async () => {
        toast(`Error Registering Reporter Account`, {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return registerReporterAccount;
};

export const useDeleteReporterAccount = (accountId: number) => {
  const deleteReporterAccount = useMutation(
    async () => await deleteCall(`${ACCOUNTS_ENDPOINT}/delete`, { accountId }),
    {
      onSuccess: async () => {
        toast(`Reporter Account Deleted`, {
          position: "top-right",
          type: "success",
          theme: "colored"
        });
        return queryClient.invalidateQueries(reporterAccountsKeys.lists());
      },
      onError: async () => {
        toast(`Error Deleting Reporter Account`, {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return deleteReporterAccount;
};

export const setAccountPassword = async (
  accountId: number,
  password: string,
  requireNew: boolean = true
) => {
  try {
    const response = await patch(`${AUTHORIZATION_ENDPOINT}/set-password`, {
      accountId,
      password,
      requireNew
    });
    toast(`Password Changed`, {
      position: "top-right",
      type: "success",
      theme: "colored"
    });
    return response;
  } catch (err) {
    toast("Error Changing Password", {
      position: "top-right",
      type: "error",
      theme: "colored"
    });
    console.error(err);
    throw err;
  }
};

export const linkReportingEntity = async (
  accountId: number,
  reportingEntityId: number
) => {
  try {
    const response = await post(`${ACCOUNTS_ENDPOINT}/link-reporter`, {
      accountId,
      reportingEntityId
    });
    toast(`Reporter Linked`, {
      position: "top-right",
      type: "success",
      theme: "colored"
    });
    queryClient.invalidateQueries(reporterAccountsKeys.lists());
    return response;
  } catch (err) {
    toast("Error Linking Reporter", {
      position: "top-right",
      type: "error",
      theme: "colored"
    });
    console.error(err);
  }
};

export const unlinkReportingEntity = async (
  accountId: number,
  reportingEntityId: number
) => {
  try {
    const response = await post(`${ACCOUNTS_ENDPOINT}/unlink-reporter`, {
      accountId,
      reportingEntityId
    });
    toast(`Reporter Unlinked`, {
      position: "top-right",
      type: "success",
      theme: "colored"
    });
    queryClient.invalidateQueries(reporterAccountsKeys.lists());
    return response;
  } catch (err) {
    toast("Error Unlinking Reporter", {
      position: "top-right",
      type: "error",
      theme: "colored"
    });
    console.error(err);
  }
};

export const updateAccount = async (updateAcct: UpdateAccountRequestBody) => {
  try {
    const response = await patch(`${ACCOUNTS_ENDPOINT}/update`, {
      ...updateAcct
    });
    toast(`Account Updated`, {
      position: "top-right",
      type: "success",
      theme: "colored"
    });
    queryClient.invalidateQueries(reporterAccountsKeys.lists());
    return response;
  } catch (err) {
    toast("Error Updating Account", {
      position: "top-right",
      type: "error",
      theme: "colored"
    });
    console.error(err);
  }
};

export const archiveAccount = async (accountId: number) => {
  try {
    const response = await patch(`${ACCOUNTS_ENDPOINT}/archive`, {
      accountId
    });
    toast(`Account Archived`, {
      position: "top-right",
      type: "success",
      theme: "colored"
    });
    return response;
  } catch (err) {
    toast("Error Archiving Account", {
      position: "top-right",
      type: "error",
      theme: "colored"
    });
    console.error(err);
  }
};

export const updateAccountStatus = async (
  accountId: number,
  statusId: number
) => {
  try {
    const response = await put(`${ACCOUNTS_ENDPOINT}/update-status`, {
      accountId,
      statusId
    });
    toast(`Account Status Updated`, {
      position: "top-right",
      type: "success",
      theme: "colored"
    });
    queryClient.invalidateQueries(reporterAccountsKeys.lists());
    return response;
  } catch (err) {
    toast("Error Updating Account Status", {
      position: "top-right",
      type: "error",
      theme: "colored"
    });
    console.error(err);
  }
};

export const accountSearch = async (searchTerm: string, type?: string) => {
  try {
    const data = await get(`${ACCOUNTS_ENDPOINT}/search`, {
      searchTerm,
      type
    });
    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const registerAccount = async (
  loginId: string,
  password: string,
  token: string
) => {
  try {
    const response = await post(`${ACCOUNTS_ENDPOINT}/register`, {
      loginId,
      password,
      token
    });
    toast(`Account Created!`, {
      position: "top-right",
      type: "success",
      theme: "colored"
    });
    return response;
  } catch (err) {
    toast("Error Registering Account", {
      position: "top-right",
      type: "error",
      theme: "colored"
    });
    console.error(err);
    throw err;
  }
};
