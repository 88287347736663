import React from "react";
import { Lock, Unlock } from "react-bootstrap-icons";

type UnlockButtonProps = {
  isLocked: boolean;
  handleOnClick: (isLocked: boolean) => void;
  className?: string;
};

function UnlockButton({
  className,
  isLocked,
  handleOnClick
}: UnlockButtonProps) {
  return (
    <div>
      <button
        type="button"
        className={`btn btn-round ${className}`}
        onClick={() => handleOnClick(!isLocked)}
        style={{ minWidth: "120px" }}
      >
        {isLocked ? <Lock /> : <Unlock />}
        {isLocked ? " Unlock" : " Lock"}
      </button>
    </div>
  );
}

export default UnlockButton;
