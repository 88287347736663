import React, { ReactElement, useEffect, useState } from "react";
import { usePermissions } from "../../providers/Permission";

interface PermissionsGateProps {
  children: ReactElement;
  resource: string;
  action?: string;
  errorProps?: any;
  RenderError?: React.ComponentType;
}

const PermissionsGate: React.FC<PermissionsGateProps> = ({
  children,
  resource,
  action = "view",
  errorProps = null,
  RenderError = () => <></>
}) => {
  const { isAllowed } = usePermissions();
  const [permissionGranted, setPermissionGranted] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    let isMounted = true;

    const checkPermission = async () => {
      const permission = await isAllowed(action, resource);
      if (isMounted) {
        setPermissionGranted(permission);
      }
    };

    checkPermission();

    return () => {
      isMounted = false;
    };
  }, [isAllowed, action, resource]);

  //Permission check in progress...
  if (permissionGranted === null) {
    return null;
  }

  //Permission denied. Rendering RenderError component.
  if (!permissionGranted && !errorProps) {
    return <RenderError />;
  }

  //Permission denied. Rendering child component with errorProps.
  if (!permissionGranted && errorProps) {
    return React.cloneElement(children, { ...errorProps });
  }

  //Permission granted. Rendering children.
  return <>{children}</>;
};

export default PermissionsGate;
