import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import React from "react";
import { Table as BTable, Badge } from "react-bootstrap";
import { Account } from "../../models";

interface ReporterAccountsExpandedRowProps {
  row: Account;
}

const columnHelper = createColumnHelper<any>();

const columns = [
  columnHelper.accessor(row => row.reportingEntity.type, {
    header: "Type",
    cell: info => (
      <span>
        <Badge pill bg={`${info.getValue()?.id === 10 ? "yellow" : "red"}`}>
          {info.getValue()?.name}
        </Badge>
      </span>
    )
  }),
  columnHelper.accessor(row => row.reportingEntity.name, {
    id: "name",
    header: "Name"
  }),
  columnHelper.accessor(row => row.reportingEntity.category?.name, {
    id: "category",
    header: "Category"
  }),
  columnHelper.accessor(row => row.reportingEntity.license, {
    header: "License"
  }),
  columnHelper.accessor(row => row.reportingEntity.archivedAt, {
    header: "Status",
    cell: info => (
      <span
        className={`status-dot status-dot-${
          info.getValue() ? "archived" : "active"
        }`}
      >
        {info.getValue() ? "Archived" : "Active"}
      </span>
    )
  })
];

export default function ReporterAccountsExpandedRow({
  row
}: ReporterAccountsExpandedRowProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, _setData] = React.useState(() => [
    ...row.accountReportingEntities
  ]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <BTable responsive className="reporter-accounts-expanded-row-table">
      <thead className="border-bottom border-body-secondary">
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr className="border-top border-body-secondary" key={row.id}>
            {row.getVisibleCells().map(cell => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </BTable>
  );
}
