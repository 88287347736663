import { AccountReportingEntities } from "./AccountReportingEntities";

type AccountConstructorArgs = {
  id: number;
  name: string;
  loginId: string | null;
  email: string | null;
  phoneNumber: string | null;
  archivedAt: Date | string | null;
  createdAt: Date | string | null;
  updatedAt: Date | string | null;
  updatedBy: Date | string | null;
  editor?: { name: string };
  creator?: { name: string };
  accountReportingEntities: AccountReportingEntities[];
  status: AccountStatus;
};

export type AccountStatus = {
  allowLogin: boolean;
  name: string;
  updatedAt?: string;
  updatedBy?: number;
  SourceAccountStatusTransition?: {
    destAccountStatus: {
      id: number;
      name: string;
    };
    transitionVerb: string;
  }[];
};

class Account {
  public readonly id: number;
  public readonly name: string;
  public readonly loginId: string | null;
  public readonly email: string | null;
  public readonly phoneNumber: string | null;
  public readonly archivedAt: Date | string | null;
  public readonly createdAt: Date | string | null;
  public readonly updatedAt: Date | string | null;
  public readonly updatedBy: Date | string | null;
  public readonly editor?: { name: string };
  public readonly creator?: { name: string };
  public readonly accountReportingEntities: AccountReportingEntities[];
  public readonly status: AccountStatus;

  constructor(args: AccountConstructorArgs) {
    this.name = args.name;
    this.id = args.id;
    this.loginId = args.loginId;
    this.email = args.email;
    this.phoneNumber = args.phoneNumber;
    this.archivedAt = args.archivedAt;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;
    this.updatedBy = args.updatedBy;
    this.editor = args.editor;
    this.creator = args.creator;
    this.accountReportingEntities = args.accountReportingEntities;
    this.status = args.status;
  }
}

export default Account;
