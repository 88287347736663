import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { resetPasswordQuery } from "../queries/resetPasswordQueries";

const MIN_PW_LENGTH = 8;

function ResetPasswordForm() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [warningMessage, setWarningMessage] = useState("");

  const submitUsername = (password1: string, password2: string) => {
    if (password1 !== password2) {
      setWarningMessage("Passwords do not match");
      return;
    }
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const accountId = params.get("accountId");
    resetPasswordQuery(password1, accountId || "", token || "").then(
      ({ error }: { error: string | null }) => {
        if (error) {
          setWarningMessage(error);
        }
      }
    );
  };

  const handlePasswordChange = (e: React.FocusEvent<HTMLInputElement>) => {
    setWarningMessage("");
    setNewPassword(e.target.value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // Password validation rules
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!regex.test(newPassword)) {
      setWarningMessage(`Password does not meet the requirements.
      At a minimum:
      - 8 characters
      - 1 lowercase character
      - 1 uppercase character
      - 1 numeric character
      - 1 special character (@$!%*?&)
      `);
    }
  };

  return (
    <div>
      {warningMessage && (
        <div className="alert alert-danger" role="alert">
          {warningMessage.split("\n").map((line, idx) => (
            <div key={idx}>{line}</div>
          ))}
        </div>
      )}
      <h2>Reset Password</h2>
      <p className="mb-4">Please enter your new password</p>
      <label className="form-label fw-bold">New password</label>
      <input
        type="password"
        className="form-control mb-4"
        value={newPassword}
        onChange={handlePasswordChange}
        onBlur={handleBlur}
        minLength={MIN_PW_LENGTH}
      />
      <label className="form-label fw-bold">Confirm new password</label>
      <input
        type="password"
        className="form-control mb-4"
        value={confirmPassword}
        onChange={e => setConfirmPassword(e.target.value)}
        minLength={MIN_PW_LENGTH}
      />
      <div className="d-flex align-items-end">
        <Button
          variant="success"
          className="me-4"
          onClick={() => submitUsername(newPassword, confirmPassword)}
        >
          Submit
        </Button>
        <Link to="/login">Return to login</Link>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
