import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { queryClient } from "../../App";
import { ApiService } from "../../services";
import { QueryParamOptions } from "../../services/ApiService";
import { makeKeyFactory } from "./makeKeyFactory";

const { get, post, patch, deleteCall } = new ApiService();

export type CreatePurchaseReportBookRequestBody = {
  buyerId: number | null;
  issuedDate: Date | null;
  purchaseReportsPerBook: number;
  startReportNumber: number;
  endReportNumber: number;
  purchaseReportBookStatusId: number;
  comments: string | null;
};

const PURCHASE_REPORT_BOOKS_ENDPOINT = "purchase-report-books";

export const purchaseReportBookKeys = makeKeyFactory("purchaseReportBooks");

export const getPurchaseReportBook = async (
  purchaseReportNumber: number,
  showPreexistingRecordWarning?: boolean,
  showNonExistantRecordWarning?: boolean
) => {
  try {
    const purchaseReportBook = await get(PURCHASE_REPORT_BOOKS_ENDPOINT, {
      purchaseReportNumber
    });
    if (showPreexistingRecordWarning && purchaseReportBook.length > 0) {
      const { startReportNumber, endReportNumber } = purchaseReportBook[0];
      toast(
        `A Purchase Report Book within this range (${startReportNumber}-${endReportNumber}) already exists.`,
        {
          position: "top-right",
          type: "warning",
          theme: "colored"
        }
      );
    }
    if (showNonExistantRecordWarning && purchaseReportBook.length === 0) {
      toast(
        `A Purchase Report Book for this Purchase Report Number does not yet exist. Please add one before adding this Purchase Report.`,
        {
          position: "top-right",
          type: "warning",
          theme: "colored"
        }
      );
    }
    return purchaseReportBook;
  } catch (err) {
    toast("Error Fetching Purchase Report Book", {
      position: "top-right",
      type: "error",
      theme: "colored"
    });
  }
};

export const usePurchaseReportBooksQuery = (
  queryFilter: string = "all",
  queryOptions?: QueryParamOptions
) => {
  const allPurchaseReportBooks = useQuery(
    purchaseReportBookKeys.list(queryFilter),
    async () => await get(PURCHASE_REPORT_BOOKS_ENDPOINT, queryOptions),
    {
      onSuccess: data => {
        return data;
      },
      onError: error => {
        toast("Error Fetching Purchase Report Books", {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );

  return allPurchaseReportBooks;
};

export const useCreatePurchaseReportBook = (action: "Updated" | "Created") => {
  const newPurchaseReport = useMutation(
    async ({ body }: any) =>
      (await action) === "Updated"
        ? patch(PURCHASE_REPORT_BOOKS_ENDPOINT, body)
        : post(PURCHASE_REPORT_BOOKS_ENDPOINT, body),
    {
      onSuccess: async () => {
        toast(`Purchase Report Book ${action}.`, {
          position: "top-right",
          type: "success",
          theme: "colored"
        });
        return queryClient.invalidateQueries(purchaseReportBookKeys.lists());
      },
      onError: (error: any) => {
        toast("Error Creating Purchase Report Book", {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return newPurchaseReport;
};

export const useDeletePurchaseReportBook = (id?: number) => {
  const deletedPurchaseReport = useMutation(
    async () => deleteCall(PURCHASE_REPORT_BOOKS_ENDPOINT, { id }),
    {
      onSuccess: async () => {
        toast(`Purchase Report Book ${id} deleted.`, {
          position: "top-right",
          type: "success",
          theme: "colored"
        });
        return queryClient.invalidateQueries(purchaseReportBookKeys.lists());
      },
      onError: error => {
        toast(`Error Deleting Purchase Report Book ${id}`, {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return deletedPurchaseReport;
};
