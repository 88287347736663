import { dateSort } from "../../utils";

type ReportingEntityColumns = {
  Header: string;
  accessor: string;
  display: boolean;
  sortType?: (a: any, b: any) => number;
};

export const reportingEntitiesTableConfig: ReportingEntityColumns[] = [
  { Header: "Name", accessor: "name", display: true },
  { Header: "Code", accessor: "code", display: false },
  { Header: "Type", accessor: "typeId", display: false },
  { Header: "Type", accessor: "type", display: true },
  { Header: "Category", accessor: "category", display: true },
  { Header: "License", accessor: "license", display: true },
  { Header: "Middle Name", accessor: "middleName", display: false },
  { Header: "Last Name", accessor: "lastName", display: false },
  { Header: "Vessel Name", accessor: "vesselName", display: false },
  { Header: "Phone Number", accessor: "phoneNumber", display: false },
  { Header: "Fax Number", accessor: "faNumber", display: false },
  { Header: "Email", accessor: "email", display: false },
  { Header: "Address", accessor: "address", display: false },
  {
    Header: "Secondary Address",
    accessor: "addressSecondary",
    display: false
  },
  { Header: "City", accessor: "city", display: false },
  { Header: "Postal Code", accessor: "postalCode", display: false },
  {
    Header: "Created At",
    accessor: "createdAt",
    display: false,
    sortType: (a: any, b: any) => {
      return dateSort(a.values.createdAt, b.values.createdAt);
    }
  },
  {
    Header: "Last Updated",
    accessor: "updatedAt",
    display: true,
    sortType: (a: any, b: any) => {
      return dateSort(a.values.updatedAt, b.values.updatedAt);
    }
  },
  { Header: "Last Updated By", accessor: "updatedBy", display: false },
  { Header: "ID", accessor: "id", display: false }
];
