import { Col, OverlayTrigger, Row } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import Popover from "react-bootstrap/Popover";
import ReportingEntity, {
  ReportingEntityComments
} from "../../models/ReportingEntity";

interface ReportingEntityDetailsPopoverProps {
  reportingEntity: ReportingEntity;
  value: any;
}

export default function ReportingEntityDetailsPopover({
  reportingEntity,
  value
}: ReportingEntityDetailsPopoverProps) {
  return (
    <OverlayTrigger
      trigger={["focus", "hover"]}
      placement="bottom-start"
      overlay={
        <Popover className="popover-details">
          <Popover.Header as="h3">Reporter Entity Details</Popover.Header>
          <Popover.Body className="m-2">
            <Row>
              <Col xs={3}>
                <strong>Business Name</strong>
                <p>{reportingEntity?.name}</p>
              </Col>
              <Col xs={3}>
                <strong>Type</strong>
                <p>{reportingEntity.type?.name}</p>
              </Col>
              <Col xs={3}>
                <strong>Buyer Category</strong>
                <p>{reportingEntity?.category?.name}</p>
              </Col>
              <Col xs={3}>
                <strong>License Number</strong>
                <p>{reportingEntity?.license}</p>
              </Col>
            </Row>
            <hr />

            <Row>
              <Col>
                <strong>Contact First Name</strong>
                <p>{reportingEntity?.firstName}</p>
              </Col>
              <Col>
                <strong>Contact Last Name</strong>
                <p>{reportingEntity?.lastName}</p>
              </Col>
            </Row>
            <hr />

            <Row>
              <Col xs={3}>
                <strong>Phone Number</strong>
                <p>{reportingEntity?.phoneNumber}</p>
              </Col>
              <Col xs={3}>
                <strong>Email</strong>
                <p>{reportingEntity?.email}</p>
              </Col>
              <Col xs={3}>
                <strong>Fax Number</strong>
                <p>{reportingEntity?.faxNumber}</p>
              </Col>
            </Row>
            <hr />

            <Row>
              <Col xs={6}>
                <strong>Address</strong>
                <p>{reportingEntity?.address}</p>
              </Col>

              <Col xs={3}>
                <strong>City</strong>
                <p>{reportingEntity?.city}</p>
              </Col>
              <Col xs={3}>
                <strong>Postal Code</strong>
                <p>{reportingEntity?.postalCode}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>Secondary Address</strong>
                <p>{reportingEntity?.addressSecondary}</p>
              </Col>
            </Row>
            <hr />

            <Row>
              <Col>
                <strong>Comments</strong>
                <ul>
                  {reportingEntity?.comments?.map(
                    (comment: ReportingEntityComments) => (
                      <li key={comment.id}>{comment.text}</li>
                    )
                  )}
                </ul>
              </Col>
            </Row>
          </Popover.Body>
        </Popover>
      }
    >
      <span className="cursor-pointer">
        {value} <InfoCircle />
      </span>
    </OverlayTrigger>
  );
}
