import { AccountReportingEntities } from "./AccountReportingEntities";

export enum ReportingEntityTypeEnum {
  FISHER = 10,
  VENDOR = 20,
  UNKNOWN = 99
}

export type ReportingEntityComments = {
  id: number;
  reportingEntityId?: number;
  text: string;
  updatedAt?: string;
  updatedBy?: number;
  editor?: {
    name: string;
  };
};

type ReportingEntityConstructorArgs = {
  address: string | null;
  addressSecondary: string | null;
  archivedAt: Date | string | null;
  categoryId: number | null;
  category?: string | null;
  city: string | null;
  legacyFisherCode: number | null;
  legacyBuyerCode: number | null;
  createdAt: Date | string | null;
  email: string | null;
  faxNumber: string | null;
  firstName: string | null;
  id: number;
  lastName: string | null;
  businessName: string | null;
  license: string | null;
  middleName: string | null;
  name: string;
  phoneNumber: string | null;
  postalCode: string | null;
  suffix: string | null;
  typeId: number;
  type?: { id: number; name: string } | null;
  updatedAt: Date | string | null;
  updatedBy: Date | string | null;
  vesselId: string | null;
  vesselName: string | null;
  editor?: { name: string };
  creator?: { name: string };
  accountReportingEntities: AccountReportingEntities[];
  comments?: ReportingEntityComments[];
};

class ReportingEntity {
  public readonly address: string | null;
  public readonly addressSecondary: string | null;
  public readonly archivedAt: Date | string | null;
  public readonly categoryId: number | null;
  public readonly category?: any | null;
  public readonly city: string | null;
  public readonly legacyFisherCode: number | null;
  public readonly legacyBuyerCode: number | null;
  public readonly createdAt: Date | string | null;
  public readonly email: string | null;
  public readonly faxNumber: string | null;
  public readonly firstName: string | null;
  public readonly id: number;
  public readonly lastName: string | null;
  public readonly businessName: string | null;
  public readonly license: string | null;
  public readonly middleName: string | null;
  public readonly name: string;
  public readonly phoneNumber: string | null;
  public readonly postalCode: string | null;
  public readonly suffix: string | null;
  public readonly typeId: number;
  public readonly type?: { id: number; name: string } | null;
  public readonly updatedAt: Date | string | null;
  public readonly updatedBy: Date | string | null;
  public readonly vesselId: string | null;
  public readonly vesselName: string | null;
  public readonly editor?: { name: string };
  public readonly creator?: { name: string };
  public readonly accountReportingEntities: AccountReportingEntities[];
  public readonly comments?: ReportingEntityComments[];

  constructor(args: ReportingEntityConstructorArgs) {
    this.id = args.id;
    this.typeId = args.typeId;
    this.name = args.name;
    this.firstName = args.firstName;
    this.middleName = args.middleName;
    this.lastName = args.lastName;
    this.businessName = args.businessName;
    this.suffix = args.suffix;
    this.license = args.license;
    this.address = args.address;
    this.addressSecondary = args.addressSecondary;
    this.city = args.city;
    this.postalCode = args.postalCode;
    this.email = args.email;
    this.phoneNumber = args.phoneNumber;
    this.faxNumber = args.faxNumber;
    this.vesselId = args.vesselId;
    this.vesselName = args.vesselName;
    this.categoryId = args.categoryId;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;
    this.updatedBy = args.updatedBy;
    this.archivedAt = args.archivedAt;
    this.legacyFisherCode = args.legacyFisherCode;
    this.legacyBuyerCode = args.legacyBuyerCode;
    this.type = args.type;
    this.category = args.category;
    this.editor = args.editor;
    this.creator = args.creator;
    this.accountReportingEntities = args.accountReportingEntities;
    this.comments = args.comments;
  }
}

export default ReportingEntity;
