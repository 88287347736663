import { useEffect, useMemo, useRef, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import {
  LogItButtonRound,
  LogItPageHeader,
  SelectSearchOptions
} from "../../components";
import LogItTable from "../../components/Tables/LogItTable";
import { isEmptyObj } from "../../utils";
import {
  useFishingAreasQuery,
  useFishingMethodsQuery,
  usePurchaseReportStatusesQuery,
  usePurchaseReportsQuery,
  usePurchaseTypesQuery,
  useReportingEntitiesQuery,
  useSpeciesConditionsQuery,
  useSpeciesQuery
} from "../queries";
import NewPurchaseReport from "./NewPurchaseReport";
import PurchaseReportDetails from "./PurchaseReportDetails";
import { purchaseReportsTableConfig as tableConfig } from "./tableConfig";
type TableViewProps = {
  purchaseReports: { [key: string]: any }[];
  setSearchResults: (data: []) => void;
  setLoadMoreResults: (data: []) => void;
  reportStatusOptions: SelectSearchOptions[];
  setDetailData: React.Dispatch<React.SetStateAction<object>>;
  searchBarRef?: any;
};

function formatDateString(sourceDate: string) {
  //Purchase Date is stored in DB as Date datatype.
  //Is is returned by API as YYYY-MM-DDT00:00:00.000Z
  //Only the date component is needed and should not have local offset applied.
  const re = /[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}T/;
  if (re.test(sourceDate)) {
    const [yearStr, monthStr, dayStr] = sourceDate.split("T")[0].split("-");
    return `${monthStr}/${dayStr}/${yearStr}`;
  } else {
    return sourceDate;
  }
}

function PurchaseReports({ purchaseReportData, setPurchaseReportData }: any) {
  const navigate = useNavigate();
  const params = useParams();

  const [heading, setHeading] = useState("");
  const { data: areaOptsData } = useFishingAreasQuery({
    sort: "-displayOrder,name"
  });
  const { data: methOptsData } = useFishingMethodsQuery({
    sort: "-displayOrder,name",
    include: "method"
  });
  const { data: specOptsData } = useSpeciesQuery({
    sort: "-displayOrder,name",
    include: "species"
  });
  const { data: specCondOptsData } = useSpeciesConditionsQuery({
    sort: "name"
  });
  const { data: pTypeOptsData } = usePurchaseTypesQuery({ sort: "id" });
  const { data: rStatOptsData } = usePurchaseReportStatusesQuery({
    sort: "id"
  });
  const { data: rEntOptsData } = useReportingEntitiesQuery("picklist", {
    sort: "name",
    fields: "id,typeId,legacyFisherCode,legacyBuyerCode,name,type,archivedAt"
  });
  const [purchaseReports, setPurchaseReports] = useState<any>([]);
  const { data } = usePurchaseReportsQuery();
  const isBasePath =
    params["*"]?.length === 0 && isEmptyObj(purchaseReportData);

  let searchBarRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    searchBarRef.current?.focus();
  }, [purchaseReports, purchaseReportData, params]);
  const handleBackArrow = () => {
    // When browser popstate is called, if on detail page, navigate to purchase-reports
    if (!isEmptyObj(purchaseReportData)) {
      setPurchaseReportData({});
      navigate("/purchase-reports");
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", handleBackArrow);
    return () => {
      window.removeEventListener("popstate", handleBackArrow);
    };
  });
  useEffect(() => {
    if (isBasePath) {
      setHeading("Purchase Reports");
    } else if (params["*"] === "new") {
      setHeading("Add New Purchase Report");
    } else if (!isEmptyObj(purchaseReportData)) {
      setHeading("View/Edit Purchase Report");
    } else if (!isBasePath) {
      navigate("/purchase-reports");
    }
  }, [isBasePath, params, purchaseReportData, navigate]);

  useEffect(() => {
    setPurchaseReports(data || []);
  }, [data]);
  const tableData = useMemo(() => {
    return purchaseReports?.map((item: Record<string, any>) => {
      if (item.purchaseDate) {
        item.purchaseDate = formatDateString(item.purchaseDate);
      }
      if (item.updatedAt) {
        item.updatedAt = new Date(item.updatedAt).toLocaleString();
      }
      if (item.createdAt) {
        item.createdAt = new Date(item.createdAt).toLocaleString();
      }
      return item;
    });
  }, [purchaseReports]);

  const goToNewPurchaseReportForm = () => {
    navigate("new");
  };

  const renderButtons = () => {
    return (
      <LogItButtonRound
        label="Add New Purchase Report"
        onClick={goToNewPurchaseReportForm}
        buttonStyle="filled"
      />
    );
  };
  const setLoadMoreResults = (data: any) => {
    setPurchaseReports((oldState: any) => [...oldState, ...data]);
  };

  const setSearchResults = (data: []) => {
    setPurchaseReports(data);
  };

  if (!purchaseReports) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Fetching reports...</span>
        </div>
      </div>
    );
  }

  const renderTable = () => {
    return (
      <>
        <div className={!isEmptyObj(purchaseReportData) ? "d-none" : ""}>
          <TableView
            purchaseReports={tableData}
            setLoadMoreResults={setLoadMoreResults}
            setSearchResults={setSearchResults}
            reportStatusOptions={rStatOptsData}
            setDetailData={setPurchaseReportData}
            searchBarRef={searchBarRef}
          />
        </div>
        {!isEmptyObj(purchaseReportData) ? (
          <PurchaseReportDetails
            areasOptions={areaOptsData}
            methodsOptions={methOptsData}
            speciesOptions={specOptsData}
            reportStatusOptions={rStatOptsData}
            purchaseTypesOptions={pTypeOptsData}
            speciesConditionOptions={specCondOptsData}
            reportingEntityOptions={rEntOptsData}
            purchaseReport={purchaseReportData}
            setDetailData={setPurchaseReportData}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const handleEscape = () => {
    if (params["*"] === "new") {
      navigate("/purchase-reports");
    }
    if (!isEmptyObj(purchaseReportData)) {
      setPurchaseReportData({});
    }
  };

  return (
    <div
      onKeyDown={e => {
        if (e.key === "Escape") {
          handleEscape();
        }
      }}
    >
      <LogItPageHeader
        heading={heading}
        renderButtons={isBasePath ? renderButtons : null}
      />

      <Routes>
        <Route path="/" element={renderTable()} />
        <Route
          path="new"
          element={
            <NewPurchaseReport
              areasOptions={areaOptsData}
              methodsOptions={methOptsData}
              speciesOptions={specOptsData}
              reportStatusOptions={rStatOptsData}
              purchaseTypesOptions={pTypeOptsData}
              speciesConditionOptions={specCondOptsData}
              reportingEntityOptions={rEntOptsData}
            />
          }
        />
      </Routes>
    </div>
  );
}

function TableView({
  purchaseReports,
  setSearchResults,
  setLoadMoreResults,
  reportStatusOptions,
  setDetailData,
  searchBarRef
}: TableViewProps) {
  const tableColumns = useMemo(
    () => tableConfig.filter(({ display }) => display),
    []
  );
  return (
    <LogItTable
      columns={tableColumns}
      setSearchResults={setSearchResults}
      setLoadMoreResults={setLoadMoreResults}
      data={purchaseReports}
      reportStatusOptions={reportStatusOptions}
      type="purchaseReport"
      setDetailData={setDetailData}
      ref={searchBarRef}
    />
  );
}

export default PurchaseReports;
