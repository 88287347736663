import { dateSort } from "../../utils";

type PurchaseReportBooksColumns = {
  Header: string;
  accessor: string;
  display: boolean;
  sortType?: (a: any, b: any) => number;
};

export const purchaseReportBooksTableConfig: PurchaseReportBooksColumns[] = [
  {
    Header: "Book Status",
    accessor: "purchaseReportBookStatus.name",
    display: true
  },
  { Header: "Start Report #", accessor: "startReportNumber", display: true },
  { Header: "End Report #", accessor: "endReportNumber", display: true },
  {
    Header: "Reports per Book",
    accessor: "purchaseReportsPerBook",
    display: true
  },
  { Header: "Date Issued", accessor: "issuedDate", display: true },
  { Header: "Issued Buyer", accessor: "buyer.name", display: true },
  { Header: "Report Count", accessor: "reportCount", display: true },
  {
    Header: "Created At",
    accessor: "createdAt",
    display: false,
    sortType: (a: any, b: any) => {
      return dateSort(a.values.createdAt, b.values.createdAt);
    }
  },
  {
    Header: "Last Updated",
    accessor: "updatedAt",
    display: true,
    sortType: (a: any, b: any) => {
      return dateSort(a.values.updatedAt, b.values.updatedAt);
    }
  },
  { Header: "Updated By", accessor: "user.name", display: false }
];
