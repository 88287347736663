import { customAlphabet } from "nanoid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SelectSearchOptions } from "../../components";
import {
  FormComponents,
  LogItFormRenderer,
  LogItFormRendererInputProps
} from "../../components/Forms/LogItFormRenderer";
import ReportingEntity, {
  ReportingEntityTypeEnum
} from "../../models/ReportingEntity";
import {
  getPurchaseReport,
  getPurchaseReportBook,
  useCreatePurchaseReport
} from "../../pages/queries";
import { getAccountId } from "../../services/ApiService";
import {
  PAGE_TYPES,
  getRowId,
  passBooleanValOrNull,
  passNumberValOrNull,
  passStringValOrNull,
  roundTwoPlaces
} from "../../utils";

// Dynamically load form config for TARGET_REGION
const TARGET_REGION = process.env.REACT_APP_TARGET_REGION;
let initialFormConfig: LogItFormRendererInputProps[] = [];
import(`../../config/${TARGET_REGION}/purchaseReportFormConfig`).then(
  module => {
    initialFormConfig = module.purchaseReportFormConfig;
  }
);
import(`../../config/${TARGET_REGION}/purchaseReportFormConfig`).then(
  module => {
    initialFormConfig = module.purchaseReportFormConfig;
  }
);
let DEFAULT_PURCHASE_TYPE: number;
let DEFAULT_REPORT_STATUS: number;
let DEFAULT_CONDITION: string | null = null;

import(`../../config/${TARGET_REGION}/defaults`).then(module => {
  DEFAULT_CONDITION = module.DEFAULT_CONDITION;
  DEFAULT_PURCHASE_TYPE = module.DEFAULT_PURCHASE_TYPE;
  DEFAULT_REPORT_STATUS = module.DEFAULT_REPORT_STATUS;
});

type InputOptions = {
  reportingEntityOptions: ReportingEntity[];
  areasOptions: SelectSearchOptions[];
  methodsOptions: SelectSearchOptions[];
  speciesOptions: SelectSearchOptions[];
  purchaseTypesOptions: SelectSearchOptions[];
  reportStatusOptions: SelectSearchOptions[];
  speciesConditionOptions: SelectSearchOptions[];
};

type IType = {
  species_price?: string;
  species_weight?: string;
  species_value?: string;
  species_pieces?: string;
  species_constraints?: any;
};

// Determine if a purchase report book exists for a purchase report
const purchaseReportBookExists = (prb: any[]) => {
  return prb?.length > 0 && prb?.length < 3;
};

function NewPurchaseReport({
  areasOptions,
  methodsOptions,
  speciesOptions,
  purchaseTypesOptions,
  reportStatusOptions,
  speciesConditionOptions,
  reportingEntityOptions
}: InputOptions) {
  const [validFisher, setValidFisher] = useState<boolean>(true);
  const makeFormConfig = ({
    reportingEntityOptions,
    areasOptions,
    methodsOptions,
    speciesOptions,
    purchaseTypesOptions,
    reportStatusOptions,
    speciesConditionOptions
  }: InputOptions) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return initialFormConfig.map(config => {
      // This is needed to clear any previous values
      delete config.defaultValue;
      if (config.name === "reportNumber") {
        const prePopulateBuyer = async (purchaseReportNumber: number) => {
          const purchaseReportBuyer = await getPurchaseReportBook(
            purchaseReportNumber,
            false,
            true
          );
          if (
            purchaseReportBookExists(purchaseReportBuyer) &&
            formData.length
          ) {
            const newFormData = formData.map(input => {
              if (input.name === "buyer") {
                const matchedBuyer = input.options.find(
                  ({ value }: any) => +value === +purchaseReportBuyer[0].buyerId
                );
                return {
                  ...input,
                  defaultValue: matchedBuyer,
                  _uid: String(Math.random())
                };
              }
              return input;
            });
            setFormData(newFormData);
          }
        };
        const handleValidPurchaseReportNumber = async (
          e: React.FocusEvent<HTMLInputElement>
        ) => {
          const { value } = e.target;
          if (value) {
            const purchaseReportNumber = Number(value);
            await getPurchaseReport(purchaseReportNumber);
            await prePopulateBuyer(purchaseReportNumber);
          }
        };
        config.readonly = false;
        config.handleOnBlur = handleValidPurchaseReportNumber;
        if (params.reportNumber) {
          config.defaultValue = params.reportNumber;
        }
      }
      if (config.name === "fisherCount") {
        const handleValidFisherCount = (
          e: React.FocusEvent<HTMLInputElement>
        ) => {
          const { value } = e.target;
          setValidFisher(fisherInputs === Number(value));
        };
        config.handleOnBlur = handleValidFisherCount;
        config.isvalidinput = validFisher;
      }
      if (config.name === "buyer") {
        config.isReadOnly = false;
        config.options = reportingEntityOptions
          ?.filter(entity => entity.typeId !== ReportingEntityTypeEnum.FISHER)
          .map(entity => ({
            value: String(entity.id),
            label: entity.name,
            badgeTypeId: entity.type?.id,
            badgeTypeLabel: entity.type?.name
          }));
        if (params.buyer && config.options) {
          const buyerObj = config.options.find(
            (b: any) => b.value === params.buyer
          );
          config.defaultValue = buyerObj;
        }
      }
      if (config.name === "seller") {
        config.options = reportingEntityOptions?.map(entity => ({
          value: String(entity.id),
          label: entity.name,
          badgeTypeId: entity.type?.id,
          badgeTypeLabel: entity.type?.name
        }));
      }
      if (config.name === "purchaseDate") {
        if (params.purchaseDate) {
          config.defaultValue = params.purchaseDate;
        }
        config.max = new Date().toISOString().split("T")[0];
      }
      if (config.name === "purchaseReportFishers") {
        config.options = reportingEntityOptions
          ?.filter(entity => entity.typeId === ReportingEntityTypeEnum.FISHER)
          .map(entity => ({
            value: String(entity.id),
            label: entity.name
          }));
      }
      if (config.name === "purchaseReportAreas") {
        config.options = areasOptions;
      }
      if (config.name.includes("purchaseReportMethods")) {
        config.options = methodsOptions;
      }
      if (config.name === "species_speciesId") {
        config.options = speciesOptions;
      }
      if (config.name === "species_conditionCode") {
        config.options = speciesConditionOptions;
        config.defaultValue = speciesConditionOptions?.find(
          option => option.id === DEFAULT_CONDITION
        );
        config._uid = `speciesConditionCodeOptions-${config.defaultValue?.id}`;
      }
      if (config.name === "purchaseType") {
        config.options = purchaseTypesOptions;
        config.defaultValue = purchaseTypesOptions?.find(
          option => option.id === DEFAULT_PURCHASE_TYPE
        );
        config._uid = `purchaseTypeOptions-${config.defaultValue?.id}`;
      }
      if (config.name === "purchaseReportStatus") {
        config.options = reportStatusOptions;
        config.defaultValue = reportStatusOptions?.find(
          option => option.id === DEFAULT_REPORT_STATUS
        );
        config._uid = `purchaseReportStatusOptions-${config.defaultValue?.id}`;
      }
      return config;
    });
  };
  let config = makeFormConfig({
    reportingEntityOptions: reportingEntityOptions?.filter(
      opt => !opt.archivedAt
    ),
    areasOptions: areasOptions?.filter(opt => !opt.archivedAt),
    methodsOptions: methodsOptions?.filter(opt => !opt.archivedAt),
    speciesOptions: speciesOptions?.filter(opt => !opt.archivedAt),
    purchaseTypesOptions: purchaseTypesOptions?.filter(opt => !opt.archivedAt),
    reportStatusOptions: reportStatusOptions?.filter(opt => !opt.archivedAt),
    speciesConditionOptions: speciesConditionOptions?.filter(
      opt => !opt.archivedAt
    )
  });

  const [formData, setFormData] =
    useState<LogItFormRendererInputProps[]>(config);

  useEffect(() => {
    if (!formData.length && reportingEntityOptions)
      setFormData(
        makeFormConfig({
          reportingEntityOptions: reportingEntityOptions?.filter(
            opt => !opt.archivedAt
          ),
          areasOptions: areasOptions?.filter(opt => !opt.archivedAt),
          methodsOptions: methodsOptions?.filter(opt => !opt.archivedAt),
          speciesOptions: speciesOptions?.filter(opt => !opt.archivedAt),
          purchaseTypesOptions: purchaseTypesOptions?.filter(
            opt => !opt.archivedAt
          ),
          reportStatusOptions: reportStatusOptions?.filter(
            opt => !opt.archivedAt
          ),
          speciesConditionOptions: speciesConditionOptions?.filter(
            opt => !opt.archivedAt
          )
        })
      );
    // eslint-disable-next-line
  }, [config, reportingEntityOptions, formData.length]);

  const navigate = useNavigate();
  const [fisherInputs, setFisherInputs] = useState<number>(0);
  const createPurchaseReport = useCreatePurchaseReport("Created");
  const [totalPieces, setTotalPieces] = useState("");
  const [totalWeight, setTotalWeight] = useState("0");
  const [totalValue, setTotalValue] = useState("0");
  const [totalFishers, setTotalFishers] = useState("");
  const [totalAdjustment, setTotalAdjustment] = useState("0");
  const [adjustedTotalValue, setAdjustedTotalValue] = useState("0");
  const [inputs, setInputs] = useState<IType>({
    species_price: "",
    species_weight: "",
    species_value: "",
    species_pieces: "",
    species_constraints: null
  });
  useEffect(() => {
    let weightTotal = 0;
    let valueTotal = 0;
    let piecesTotal = 0;
    for (const key in inputs) {
      if (key.includes("weight")) {
        weightTotal += Number(inputs[key as keyof IType]) || 0;
        setTotalWeight(String(roundTwoPlaces(weightTotal)));
      }
      if (key.includes("value")) {
        valueTotal += Number(inputs[key as keyof IType]) || 0;
        setTotalValue(String(roundTwoPlaces(valueTotal)));
      }
      if (key.includes("pieces")) {
        let piecesTotalDisplay = "";
        piecesTotal += Number(inputs[key as keyof IType]) || 0;
        if (piecesTotal) {
          piecesTotalDisplay = String(Number(piecesTotal).toFixed());
        }
        setTotalPieces(piecesTotalDisplay);
      }
    }
  }, [formData, inputs]);

  useEffect(() => {
    setAdjustedTotalValue(
      String(roundTwoPlaces(Number(totalValue) + Number(totalAdjustment)))
    );
  }, [totalValue, totalAdjustment]);

  const calcValue = (price: string, weight: string) =>
    price && weight
      ? String(roundTwoPlaces(Number(price) * Number(weight)))
      : "";
  const calcPrice = (value: string, weight: string) =>
    value && weight
      ? String(roundTwoPlaces(Number(value) / Number(weight)))
      : "";

  const handleSearchSelection = (speciesId: string, speciesRowId: string) => {
    const constraintsKey = `species_constraints${getRowId(speciesRowId)}`;
    // Clearing of search state
    if (!speciesId) {
      setInputs(_state => {
        return {
          ..._state,
          [constraintsKey]: null
        };
      });
      return;
    }

    // Selecting a new value within the search component
    const selectedSpecies = speciesOptions.find(s => s.value === speciesId);
    setInputs(_state => {
      return {
        ..._state,
        [constraintsKey]: { ...selectedSpecies }
      };
    });
  };
  const onChangeForFisherInputs = (name: string, value: string) => {
    if (name === "fisherCount") {
      setTotalFishers(value || "");
      return;
    }
    if (name !== "purchaseReportFishers") return;

    let amountOfAdditional = value ? value.length : fisherInputs;

    setTotalFishers(value.length ? String(amountOfAdditional + 1) : "");
    setFisherInputs(amountOfAdditional + 1);
    setValidFisher(true);
  };
  const handleSetInputs = (name: string, value: string, deletedUid: string) => {
    let nameUid = getRowId(name);
    if (deletedUid) {
      nameUid = deletedUid;
    }
    const base = "species_";
    const priceKey = `${base}price${nameUid}`;
    const weightKey = `${base}weight${nameUid}`;
    const valueKey = `${base}value${nameUid}`;
    const piecesKey = `${base}pieces${nameUid}`;

    let priceVal = name === priceKey ? value : inputs[priceKey as keyof IType];
    const weightVal =
      name === weightKey ? value : inputs[weightKey as keyof IType];
    let valueVal = name === valueKey ? value : inputs[valueKey as keyof IType];
    let piecesVal =
      name === piecesKey ? value : inputs[piecesKey as keyof IType];

    if (priceVal && weightVal) {
      if (name !== valueKey) {
        valueVal = calcValue(priceVal, weightVal);
      }
    }
    if (valueVal && weightVal) {
      if (name !== priceKey) {
        priceVal = calcPrice(valueVal, weightVal);
      }
    }
    setInputs(_state => {
      return {
        ..._state,
        [priceKey]: priceVal,
        [weightKey]: weightVal,
        [valueKey]: valueVal,
        [piecesKey]: piecesVal
      };
    });
  };

  const handleDeleteSpeciesRow = (name: string) => {
    if (name === "species_value") {
      let newFormData = [...formData].filter(function (obj) {
        return /[0-9]/.test(obj.name) || !obj.name.startsWith("species_");
      });
      setFormData(newFormData);
      let newInputs = { ...inputs };
      for (const key in inputs) {
        if (!/[0-9]/.test(key) && key.startsWith("species_")) {
          delete newInputs[key as keyof IType];
        }
      }
      setInputs(newInputs);
    } else {
      const nameUid = getRowId(name);
      deleteSpeciesRow && deleteSpeciesRow(nameUid);
      let newInputs = { ...inputs };
      for (const key in inputs) {
        if (key.includes(nameUid)) {
          delete newInputs[key as keyof IType];
        }
      }
      setInputs(newInputs);
    }
  };

  const onChangeForField = (name: string, value: string, uid = "") => {
    handleSetInputs(name, value, uid);
  };

  const onAdjustmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTotalAdjustment(event.target.value);
    setAdjustedTotalValue(
      String(roundTwoPlaces(Number(totalValue) + Number(event.target.value)))
    );
  };

  const onAdjustedTotalChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTotalAdjustment(
      String(roundTwoPlaces(Number(event.target.value) - Number(totalValue)))
    );
    setAdjustedTotalValue(event.target.value);
  };

  const addSpeciesRow = () => {
    const customUid = customAlphabet("1234567890abcdef", 10);
    const rowUid = customUid();
    setFormData([
      ...formData,
      {
        component: FormComponents.select_search,
        name: `species_speciesId_${rowUid}`,
        placeholder: "Type to search",
        _uid: `species_speciesId_${rowUid}`,
        className: "col-4 col-xl-5",
        options: speciesOptions,
        required: true
      },
      {
        component: FormComponents.select_search,
        name: `species_conditionCode_${rowUid}`,
        placeholder: "Search",
        _uid: `species_condition_${rowUid}`,
        className: "col-2",
        options: speciesConditionOptions,
        defaultValue: speciesConditionOptions?.find(
          option => option.id === DEFAULT_CONDITION
        )
      },
      {
        component: "text",
        name: `species_pieces_${rowUid}`,
        _uid: `species_pieces_${rowUid}`,
        type: "number",
        className: "col-1",
        min: "0"
      },
      {
        component: FormComponents.input_field,
        name: `species_weight_${rowUid}`,
        _uid: `species_weight_${rowUid}`,
        className: "col-2 col-xl-1",
        type: "number",
        min: "0",
        step: "0.01",
        required: true
      },
      {
        component: FormComponents.input_field,
        name: `species_price_${rowUid}`,
        _uid: `species_price_${rowUid}`,
        className: "col-1",
        type: "number",
        min: "0",
        step: "0.01",
        required: true
      },
      {
        component: FormComponents.input_field,
        name: `species_value_${rowUid}`,
        _uid: `species_value_${rowUid}`,
        className: "col-2",
        type: "number",
        min: "0",
        step: "0.01",
        required: true
      }
    ]);
  };

  const deleteSpeciesRow = (uid: string) => {
    let newFormData = [...formData].filter(function (obj) {
      return !obj.name.includes(uid);
    });
    setFormData(newFormData);
  };

  const handleCancel = () => {
    navigate("/purchase-reports");
  };

  const chooseMultiEntryParams = (fieldValues: any) => {
    return `buyer=${fieldValues.buyer}&purchaseDate=${
      fieldValues.purchaseDate
    }&reportNumber=${Number(fieldValues.reportNumber) + 1}&multi=true`;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const elementPressed = document.activeElement as HTMLButtonElement;
    const multientry = elementPressed.name === "saveAndContinue";
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    let multiValues: Record<string, any> = {
      purchaseReportMethods: [],
      purchaseReportAreas: [],
      purchaseReportFishers: [],
      purchaseReportItems: {}
    };

    formData.forEach((val, key) => {
      const firstSpeciesRow = "1";
      const speciesKey = key.split("_");
      const rowKey = speciesKey[2] || firstSpeciesRow;

      if (
        key.startsWith("species_speciesId") ||
        key.startsWith("species_weight") ||
        key.startsWith("species_price") ||
        key.startsWith("species_value")
      ) {
        multiValues.purchaseReportItems[rowKey] = {
          ...multiValues.purchaseReportItems[rowKey],
          [speciesKey[1]]: Number(val)
        };
      }
      if (key.startsWith("species_conditionCode")) {
        multiValues.purchaseReportItems[rowKey] = {
          ...multiValues.purchaseReportItems[rowKey],
          [speciesKey[1]]: passStringValOrNull(val)
        };
      }
      if (key.startsWith("species_pieces")) {
        multiValues.purchaseReportItems[rowKey] = {
          ...multiValues.purchaseReportItems[rowKey],
          [speciesKey[1]]: passNumberValOrNull(val)
        };
      }

      if (key === "purchaseReportMethods" && val) {
        multiValues.purchaseReportMethods.push({
          methodId: Number(val),
          updatedBy: getAccountId()
        });
      }
      if (key === "purchaseReportAreas" && val) {
        multiValues.purchaseReportAreas.push({
          areaId: Number(val),
          updatedBy: getAccountId()
        });
      }
      if (key === "purchaseReportFishers" && val) {
        multiValues.purchaseReportFishers.push({
          reportingEntityId: Number(val),
          updatedBy: getAccountId()
        });
      }
    });

    const purchaseReportItems = Object.entries(
      multiValues.purchaseReportItems as object
    ).map(item => {
      return { ...item[1], updatedBy: getAccountId() };
    });
    multiValues.purchaseReportItems = purchaseReportItems;
    const { ...fieldValues } = Object.fromEntries(formData.entries());

    const body = {
      purchaseDate: new Date(fieldValues.purchaseDate as string),
      reportNumber: Number(fieldValues.reportNumber),
      purchaseTypeId: Number(fieldValues.purchaseType),
      sellerId: Number(fieldValues.seller),
      buyerId: Number(fieldValues.buyer),
      purchaseReportStatusId: Number(fieldValues.purchaseReportStatus),
      comments: passStringValOrNull(fieldValues.comments),
      fisherCount: passNumberValOrNull(fieldValues.fisherCount),
      motorizedVessel: passBooleanValOrNull(fieldValues.motorizedVessel),
      hoursFished: passNumberValOrNull(fieldValues.hoursFished),
      gearsCount: passNumberValOrNull(fieldValues.gearsCount),
      purchaseReportItems: multiValues.purchaseReportItems,
      purchaseReportMethods: multiValues.purchaseReportMethods,
      purchaseReportAreas: multiValues.purchaseReportAreas,
      purchaseReportFishers: multiValues.purchaseReportFishers,
      valueAdjustment: totalAdjustment
    };

    createPurchaseReport.mutate(
      { body },
      {
        onSuccess: () => {
          // If multi-entry, clean fields, leave appropriate fields prefilled,
          // and focus on the correct field
          // Else, route to purchase-reports
          if (multientry) {
            window.location.replace(
              `${
                window.location.origin
              }/purchase-reports/new?${chooseMultiEntryParams(fieldValues)}`
            );
          } else {
            navigate("/purchase-reports");
          }
        },
        onError: error => {}
      }
    );
  };

  if (!formData) {
    return null;
  }

  return (
    <LogItFormRenderer
      config={formData}
      addSpeciesRow={addSpeciesRow}
      deleteSpeciesRow={handleDeleteSpeciesRow}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      pageType={PAGE_TYPES.NEW_PURCHASE_REPORT}
      onChangeForField={onChangeForField}
      onAdjustmentChange={onAdjustmentChange}
      onAdjustedTotalChange={onAdjustedTotalChange}
      inputs={inputs}
      onChangeForFisherInputs={onChangeForFisherInputs}
      totals={{ totalPieces, totalValue, totalWeight, totalFishers }}
      fisherInputs={fisherInputs}
      totalAdjustment={totalAdjustment}
      adjustedTotalValue={adjustedTotalValue}
      handleSearchSelection={handleSearchSelection}
    />
  );
}

export default NewPurchaseReport;
