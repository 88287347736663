import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { queryClient } from "../../App";
import { ApiService } from "../../services";
import { QueryParamOptions } from "../../services/ApiService";
import { makeKeyFactory } from "./makeKeyFactory";

const { get, post, patch, deleteCall } = new ApiService();

const REPORTING_ENTITIES_ENDPOINT = "reporting-entities";
const REPORTING_ENTITY_COMMENTS_ENDPOINT = "reporting-entity-comments";
const ACCOUNTS_ENDPOINT = "accounts";

export type CreateReportingEntityRequestBody = {
  id?: string;
  typeId: number;
  address: string | null;
  addressSecondary?: string | null;
  categoryId?: number | null;
  category?: { id?: number; name?: string };
  city: string | null;
  email?: string | null;
  faxNumber?: string | null;
  firstName?: string | null;
  lastName: string | null;
  legacyFisherCode?: number | null;
  legacyBuyerCode?: number | null;
  license?: string | null;
  middleName?: string | null;
  name: string;
  phoneNumber: string | null;
  postalCode: string | null;
  suffix?: string | null;
  vesselId?: string | null;
  vesselName?: string | null;
  archivedAt?: string | null;
  comments: {
    text: string;
    updatedBy: number;
  }[];
};

type CreateReportingEntityProps = {
  body: CreateReportingEntityRequestBody;
  update?: boolean;
};

export const reportingEntityKeys = makeKeyFactory("reportingEntities");
export const useReportingEntitiesQuery = (
  queryFilter: string = "all",
  queryOptions?: QueryParamOptions
) => {
  const allReportingEntities = useQuery(
    reportingEntityKeys.list(queryFilter),
    async () => await get(REPORTING_ENTITIES_ENDPOINT, queryOptions),
    {
      onSuccess: async data => {
        return data;
      },
      onError: async error => {
        return await error;
      }
    }
  );

  return allReportingEntities;
};

export const useReportingEntityCommentsQuery = (reportingEntityId: number) => {
  const reportingEntityComments = useQuery(
    `entityComments_${reportingEntityId}`,
    async () =>
      await get(`${REPORTING_ENTITY_COMMENTS_ENDPOINT}/${reportingEntityId}`),
    {
      refetchOnWindowFocus: "always",
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
      onSuccess: data => data,
      onError: error => error
    }
  );
  return reportingEntityComments;
};

export const useCreateReportingEntity = (action: "Updated" | "Created") => {
  const newEntity = useMutation(
    async ({ body }: CreateReportingEntityProps) =>
      (await action) === "Updated"
        ? patch(REPORTING_ENTITIES_ENDPOINT, body)
        : post(REPORTING_ENTITIES_ENDPOINT, body),
    {
      onSuccess: async () => {
        toast(`Reporting Entity ${action}`, {
          position: "top-right",
          type: "success",
          theme: "colored"
        });
        return queryClient.invalidateQueries(reportingEntityKeys.lists());
      },
      onError: async () => {
        const errAction = action === "Updated" ? "Updating" : "Creating";
        toast(`Error ${errAction} Reporting Entity`, {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return newEntity;
};

export const useDeleteReportingEntity = (id: number) => {
  const deletedPurchaseReport = useMutation(
    async () => deleteCall(REPORTING_ENTITIES_ENDPOINT, { id }),
    {
      onSuccess: async () => {
        toast(`Reporting Entity ${id} deleted.`, {
          position: "top-right",
          type: "success",
          theme: "colored"
        });
        return queryClient.invalidateQueries(reportingEntityKeys.lists());
      },
      onError: (error: Error) => {
        toast(`Cannot delete Reporting Entity ${id} ${error.message}`, {
          position: "top-right",
          type: "error",
          theme: "colored"
        });
      }
    }
  );
  return deletedPurchaseReport;
};

export const reportingEntitySearch = async (searchTerm: string) => {
  try {
    const data = await get(`${REPORTING_ENTITIES_ENDPOINT}/search`, {
      searchTerm,
      include: "category,type"
    });
    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getReportingEntity = async (reportingEntityId: number) => {
  try {
    const response = await get(
      `${REPORTING_ENTITIES_ENDPOINT}/get/${reportingEntityId}`
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const linkReportingAccount = async (
  accountId: number,
  reportingEntityId: number
) => {
  try {
    const response = await post(`${ACCOUNTS_ENDPOINT}/link-reporter`, {
      accountId,
      reportingEntityId
    });
    toast(`Reporter Linked`, {
      position: "top-right",
      type: "success",
      theme: "colored"
    });
    queryClient.invalidateQueries(reportingEntityKeys.lists());
    return response;
  } catch (err) {
    toast("Error Linking Reporter", {
      position: "top-right",
      type: "error",
      theme: "colored"
    });
    console.error(err);
  }
};

export const unlinkReportingAccount = async (
  accountId: number,
  reportingEntityId: number
) => {
  try {
    const response = await post(`${ACCOUNTS_ENDPOINT}/unlink-reporter`, {
      accountId,
      reportingEntityId
    });
    toast(`Reporter Unlinked`, {
      position: "top-right",
      type: "success",
      theme: "colored"
    });
    queryClient.invalidateQueries(reportingEntityKeys.lists());
    return response;
  } catch (err) {
    toast("Error Unlinking Reporter", {
      position: "top-right",
      type: "error",
      theme: "colored"
    });
    console.error(err);
  }
};
