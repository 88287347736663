import { jwtDecode } from "jwt-decode";
import ApiService from "./ApiService";

const API_HOST = process.env.REACT_APP_API_HOST;

export type AuthErrorResponse = {
  errors: {
    source?: {
      pointer: string;
    };
    detail: string;
  }[];
};
export type AuthTokenResponse = {
  accessToken: string;
  refreshToken: string;
};

export type AuthResponse = AuthErrorResponse | AuthTokenResponse;
class AuthenticationService {
  static tokenExpired(token: string) {
    //get access token from local storage
    const aToken = jwtDecode(token);

    //Check expiration
    var expired = false;
    if (aToken.exp) {
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      if (aToken.exp < currentTime) {
        expired = true;
      }
    }
    return expired;
  }

  static async refreshToken(
    accessToken: string,
    refreshToken: string
  ): Promise<AuthResponse> {
    const expired = AuthenticationService.tokenExpired(accessToken);
    //if expired use refresh token to get a new token
    if (expired) {
      console.warn("token is expired, attempting refresh");
      //store refresh and access token in storage or return it
      const url = new URL(`${API_HOST}/authorization/refresh-token`);
      url.searchParams.append("refreshToken", refreshToken);

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        return (await response.json()) as {
          errors: { source?: { pointer: string }; detail: string }[];
        };
      }

      const body = (await response.json()) as {
        accessToken: string;
        refreshToken: string;
      };

      return body;
    }

    const body = { accessToken: accessToken, refreshToken: refreshToken };
    return body;
  }

  async logIn(loginId: string, password: string) {
    const response = await fetch(`${API_HOST}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        loginId,
        password
      })
    });

    if (!response.ok) {
      return (await response.json()) as {
        errors: { source?: { pointer: string }; detail: string }[];
      };
    }

    const body = (await response.json()) as {
      accessToken: string;
      refreshToken: string;
      accountId: string;
      name: string;
      status: string;
    };

    return body;
  }
  async logOut() {
    const api = new ApiService();
    await api.get("authorization/logout");
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("accountId");
    window.localStorage.removeItem("name");
    window.localStorage.removeItem("roles");
    window.localStorage.removeItem("status");
    window.location.href = "/login";
  }
  signOut() {
    window.localStorage.removeItem("token");
  }
}
export default AuthenticationService;
