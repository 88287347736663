export function getInitials(fullName: string) {
  // Split the name by whitespace
  let nameParts = fullName.trim().split(/\s+/);

  // Handle the case where there's only one name part
  if (nameParts.length === 1) {
    return nameParts[0].charAt(0).toUpperCase();
  }

  // Get the first letter of the first name part and the last name part
  let firstInitial = nameParts[0].charAt(0).toUpperCase();
  let lastInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();

  return firstInitial + lastInitial;
}
