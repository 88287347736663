import Alert from "react-bootstrap/Alert";

export type APIError = {
  detail: string;
  status: string;
  title: string;
};

interface ListAPIErrorsProps {
  errors: APIError[];
  setErrors: React.Dispatch<React.SetStateAction<APIError[]>>;
  errorHeading?: string;
}

function ListAPIErrors({
  errors,
  setErrors,
  errorHeading = "You got an error!"
}: ListAPIErrorsProps) {
  return (
    <>
      {errors?.length > 0 && (
        <Alert
          variant="danger"
          className="mb-4"
          onClose={() => setErrors([])}
          dismissible
        >
          <Alert.Heading>{errorHeading}</Alert.Heading>

          <ul>
            {errors.map((err, i: number) => (
              <li key={i}>{err.detail}</li>
            ))}
          </ul>
        </Alert>
      )}
    </>
  );
}

export default ListAPIErrors;
