import { dateSort } from "../../utils";

type PurchaseReportsColumns = {
  Header: string;
  accessor: string;
  display: boolean;
  sortType?: (a: any, b: any) => number;
};

export const purchaseReportsTableConfig: PurchaseReportsColumns[] = [
  { Header: "Report Number", accessor: "reportNumber", display: true },
  {
    Header: "Report Date",
    accessor: "purchaseDate",
    display: true,
    sortType: (a: any, b: any) => {
      return dateSort(a.values.purchaseDate, b.values.purchaseDate);
    }
  },
  { Header: "Seller", accessor: "seller.name", display: true },
  { Header: "Buyer", accessor: "buyer.name", display: true },
  { Header: "Fishing Area", accessor: "purchaseReportAreas", display: false },
  {
    Header: "Fishing Method",
    accessor: "purchaseReportMethods",
    display: false
  },
  {
    Header: "Report Status",
    accessor: "purchaseReportStatus.name",
    display: true
  },
  { Header: "Remarks", accessor: "comments", display: false },

  {
    Header: "Created At",
    accessor: "createdAt",
    display: false,
    sortType: (a: any, b: any) => {
      return dateSort(a.values.createdAt, b.values.createdAt);
    }
  },
  {
    Header: "Last Updated",
    accessor: "updatedAt",
    display: true,
    sortType: (a: any, b: any) => {
      return dateSort(a.values.updatedAt, b.values.updatedAt);
    }
  },
  { Header: "Updated By", accessor: "user.name", display: false }
];
