import { Formik } from "formik";
import { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import ListAPIErrors, { APIError } from "../../components/Error/ListAPIErrors";
import loginIdValidationSchema from "../../utils/validation/loginIdValidationSchema";
import passwordConfirmationValidationSchema from "../../utils/validation/passwordConfirmationValidationSchema";
import passwordValidationSchema from "../../utils/validation/passwordValidationSchema";
import { registerAccount } from "../queries";

function RegisterAccount() {
  const navigate = useNavigate();
  const [APIErrors, setAPIErrors] = useState<APIError[]>([]);
  const validationSchema = yup.object().shape({
    loginId: loginIdValidationSchema,
    password: passwordValidationSchema,
    confirmPassword: passwordConfirmationValidationSchema
  });

  return (
    <div>
      <h2>Account Registration</h2>
      <p className="mb-4">
        Please enter a username and password to complete registration.
      </p>

      <Formik
        validationSchema={validationSchema}
        onSubmit={async values => {
          setAPIErrors([]);
          const params = new URLSearchParams(window.location.search);
          const token = params.get("token") ?? "";
          try {
            await registerAccount(values.loginId, values.password, token).then(
              () => {
                setTimeout(() => {
                  navigate("/login", {
                    state: {
                      message:
                        "Your account was created successfully. Please log in with your username and password."
                    }
                  });
                }, 1500);
              }
            );
          } catch (err: any) {
            setAPIErrors(err.errors);
            console.error("Registration failed:", err);
          }
        }}
        initialValues={{
          loginId: "",
          password: "",
          confirmPassword: ""
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form onSubmit={handleSubmit} className="mb-4">
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="loginId"
                value={values.loginId}
                onChange={handleChange}
                isInvalid={!!errors.loginId && touched.loginId}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.loginId}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                onChange={handleChange}
                isInvalid={!!errors.password && touched.password}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                onChange={handleChange}
                isInvalid={!!errors.confirmPassword && touched.confirmPassword}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Alert variant="warning" className="mb-4">
              Passwords must have at least 8 characters and contain the
              following: uppercase letters, lowercase letters, numbers, and
              symbols.
            </Alert>

            <div className="d-flex align-items-end">
              <Button variant="success" className="me-4" type="submit">
                Create Account
              </Button>
              <Link to="/login">Return to login</Link>
            </div>
          </Form>
        )}
      </Formik>

      <ListAPIErrors errors={APIErrors} setErrors={setAPIErrors} />
    </div>
  );
}

export default RegisterAccount;
