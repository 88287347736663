import { toast } from "react-toastify";
import { ApiService } from "../../services";

const { post } = new ApiService();

export const requestResetPasswordQuery = async (username: string) => {
  const setRequestFailure = () =>
    window.localStorage.setItem("requestState", "failed");
  const redirectToMessage = () => (window.location.href = "/message");
  post("password/reset/request", { username: username })
    .then(data => {
      if (data.email) {
        window.localStorage.setItem("emailsent", data.email);
      } else {
        setRequestFailure();
      }
      redirectToMessage();
      return data;
    })
    .catch(err => {
      console.error(`Error resetting username: ${username}`, err);
      setRequestFailure();
      redirectToMessage();
      return err;
    });
};

export const resetPasswordByEmail = async (username: string) => {
  try {
    const response = await post(`password/reset/request`, {
      username: username
    });
    toast(`Password reset link sent!`, {
      position: "top-right",
      type: "success",
      theme: "colored"
    });
    return response;
  } catch (err) {
    toast("Error sending password reset link", {
      position: "top-right",
      type: "error",
      theme: "colored"
    });
    console.error(err);
    throw err;
  }
};

export const resetPasswordQuery = (
  password: string,
  accountId: string,
  token: string
) => {
  return post("password/reset", { password, accountId, token })
    .then(data => {
      window.location.href = "/login";
      return { error: null };
    })
    .catch(err => {
      console.error(`Error resetting accountID: ${accountId}`, err);
      return { error: err.errors[0].detail };
    });
};
