import { useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { requestResetPasswordQuery } from "../queries/resetPasswordQueries";

function RequestResetPassword() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");

  const submitUsername = async (username: string) => {
    await requestResetPasswordQuery(username);
    navigate("/message");
  };

  return (
    <>
      <h2>Password Reset</h2>
      <p className="mb-4">
        Please enter your username and you will be emailed a link to reset your
        password
      </p>
      <label className="form-label fw-bold">Username</label>
      <input
        className="form-control mb-4"
        value={username}
        onChange={e => setUsername(e.target.value)}
      />
      <div className="d-flex align-items-end">
        <Button
          variant="success"
          className="me-4"
          onClick={() => submitUsername(username)}
        >
          Submit
        </Button>
        <Link to="/login">Return to login</Link>
      </div>
    </>
  );
}

export default RequestResetPassword;
