import { Badge, OverlayTrigger, Table } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import Popover from "react-bootstrap/Popover";
import { AccountReportingEntities } from "../../models/AccountReportingEntities";

interface LinkedReportingEntityDetailsPopoverProps {
  reportingEntities: AccountReportingEntities[];
  value: any;
}

export default function LinkedReportingEntityDetailsPopover({
  reportingEntities,
  value
}: LinkedReportingEntityDetailsPopoverProps) {
  return (
    <OverlayTrigger
      trigger={["focus", "hover"]}
      placement="bottom-start"
      overlay={
        <Popover className="popover-details">
          <Popover.Header as="h3">Linked Reporter Details</Popover.Header>
          <Popover.Body className="m-2">
            <Table responsive>
              <thead>
                <tr>
                  <th className="bg-body-secondary">Name</th>
                  <th className="bg-body-secondary">Type</th>
                  <th className="bg-body-secondary">Category</th>
                  <th className="bg-body-secondary">License</th>
                  <th className="bg-body-secondary">Phone</th>
                </tr>
              </thead>
              <tbody>
                {reportingEntities.map(linkedReporter => (
                  <tr key={linkedReporter.reportingEntityId}>
                    <td>{linkedReporter.reportingEntity?.name}</td>
                    <td>
                      <Badge
                        pill
                        bg={`${
                          linkedReporter.reportingEntity?.type?.id === 10
                            ? "yellow"
                            : "red"
                        }`}
                      >
                        {linkedReporter.reportingEntity?.type?.name}
                      </Badge>
                    </td>
                    <td>{linkedReporter.reportingEntity?.category?.name}</td>
                    <td>{linkedReporter.reportingEntity?.license}</td>
                    <td>{linkedReporter.reportingEntity?.phoneNumber}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Popover.Body>
        </Popover>
      }
    >
      <span className="cursor-pointer">
        {value} <InfoCircle />
      </span>
    </OverlayTrigger>
  );
}
